import React from 'react';

import styles from './RedirectNotification.module.scss';
import { classNames } from 'utils/classNames';
import { RoutePath } from 'config/routeConfig';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ThemeButton } from 'components/Button/Button';

interface RedirectNotificationProps {
    className?: string;
}

export const RedirectNotification = ({ className }: RedirectNotificationProps) => {
    const navigate = useNavigate();
        
    const onClickRedirectBtn = () => {
        navigate(RoutePath.billing);
    }

    return (
        <div className={classNames(styles.container, {}, [className])}>
            <div className={styles.info}>
                <h2 className={styles.title}>Select Plan</h2>
                <p className={styles.description}>You have chosen a creator without a subscription plan. To access analytics, please upgrade this creator to a Premium plan.</p>
            </div>

            <Button theme={ThemeButton.PRIMARY} className={styles.redirect} onClick={onClickRedirectBtn}>Go to Billings</Button>
        </div>
    );
};
