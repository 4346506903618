import { BrandCanvas } from "components/BrandCanvas/BrandCanvas";
import SignUp from "components/SignUp/SignUp";

const SignUpPage = () => {

    
    return <>
        <BrandCanvas />
        <SignUp />
    </>
}

export default SignUpPage;