import React, { useState } from 'react';

import styles from './SuccessForm.module.scss';
import { Button, ThemeButton } from 'components/Button/Button';
import { ReactComponent as SuccessIcon } from 'assets/images/billing/successful.svg';

interface SuccessFormProps {
    text: string;
    onDone: () => void;
    className?: string;
}

export const SuccessForm = ({ text, onDone }: SuccessFormProps) => {

    return (
        <div className={styles.container}>
            <SuccessIcon className={styles.icon} />
            <h1 className={styles.title}>Successfully!</h1>
            <p className={styles.description}>{text}</p>
            <Button
                theme={ThemeButton.PRIMARY}
                className={styles.button}
                onClick={onDone}
            >
                Done
            </Button>

        </div>
    );
};
