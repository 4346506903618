import React, { useEffect, useContext, useState } from 'react';
import { useAppSelector } from '../store';
import { getRolesList } from '../store/reducers/roles';
import { getCreatorsList } from '../store/reducers/creators';
import { ICreators } from '../models/creators';
import { IRoles } from '../models/roles';
import usePermissions from '../hooks/usePermissions';
import useHandleApiErrors from '../hooks/useHandleApiErrors';
import useCreators from '../hooks/useCreators';

type mapType<T> = Map<string, T>;

interface IAppProvider {
  children: React.ReactNode;
}

interface IAppContext {
  creatorMap: mapType<ICreators> | null;
  rolesMap: mapType<IRoles> | null;
  creators: ICreators[];
}

export const AppContext = React.createContext<IAppContext>({
  creatorMap: null,
  rolesMap: null,
  creators: [],
});

export const useChatterCharms = () => useContext(AppContext);

export const ChatterCharmsProvider = ({ children }: IAppProvider) => {
  const { items: roles } = useAppSelector(getRolesList);

  const [creatorMap, setCreatorMap] = useState<mapType<ICreators> | null>(null);
  const [rolesMap, setRolesMap] = useState<mapType<IRoles> | null>(null);

  const { creators } = useCreators();

  useHandleApiErrors();

  useEffect(() => {
    if (!creators.length) {
      return;
    }

    const creatorsMap = new Map(creators.map((item) => [item.id!, item]));

    setCreatorMap(creatorsMap);
  }, [creators]);

  useEffect(() => {
    if (!roles.length) {
      return;
    }

    const rolesMap = new Map(roles.map((item) => [item.id!, item]));

    setRolesMap(rolesMap);
  }, [roles]);

  return (
    <AppContext.Provider value={{ creatorMap, rolesMap, creators }}>
      {children}
    </AppContext.Provider>
  );
};
