import React from 'react';

import { CreatorMember } from '../../../../models/creators';

import styles from './Row.module.scss';


interface Props {
  isLine?: boolean
  item: CreatorMember
  bg: string
  color: string
  roleName: string
}

export const Row = ({ isLine, item, bg, color, roleName }: Props) => {
  return (
    <>
      <div className={styles.box}>
        <div className={styles.tag} style={{ color, background: bg }}>
          {roleName}
        </div>

        <p className={styles.member}>{item.memberName}</p>
      </div>

      {isLine && <div className={styles.line} />}
    </>
  )
}
