import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      paddingTop: '22px',
      paddingBottom: '22px',
      position: 'relative',
      textAlign: 'center',

      '&:before': {
        background: 'rgba(0, 175, 240, 0.05)',
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '1px',
      },

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        paddingTop: '8px',
        paddingBottom: '8px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },

    button: (isActive: boolean) => ({
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      textTransform: 'none',

      '&:after': {
        position: 'absolute',
        right: '30px',
        top: '50%',
        transform: 'translateY(-50%)',
        content: '""',
        width: '20px',
        height: '10px',
        display: isActive ? 'none' : 'block',
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 1L10 8L1 1' stroke='%2390A5BE' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E ")`,

        [breakpoints.down(ScreenSizes.screenXXl)]: {
          right: '15px',
          width: '14px',
          height: '5px',
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 5L11 1' stroke='%23A3ADB1' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,
        },

        [breakpoints.down(ScreenSizes.screenXl)]: {
          right: '15px',
          width: '12px',
          height: '6px',
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 5L11 1' stroke='%23A3ADB1' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,
        },
      },
    }),

    collapse: {
      position: 'relative',
      overflow: 'hidden',
      transition: 'height 0.2s ease-in-out',

      maxHeight: 'calc(100vh - 100px)',
    },

    hideButton: {
      position: 'absolute',

      bottom: '18px',
      color: '#90A5BE',
      fontSize: '19px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      textTransform: 'none',
      right: '50%',
      transform: 'translateX(50%)',

      '&:after': {
        content: '""',
        width: '18px',
        height: '7px',
        display: 'block',
        margin: '7px auto 0',
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L10 2L19 9' stroke='%2390A5BE' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,

        [breakpoints.down(ScreenSizes.screenXXl)]: {
          margin: '3px auto 0',
        },

        [breakpoints.down(ScreenSizes.screenXl)]: {
          width: '8px',
          height: '3px',
          margin: '2px 2px 0',
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 4L5 1L1 4' stroke='%23A3ADB1' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,
        },
      },

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '15px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '10px',
      },
    },
  } as Record<string, any>;
};

export default useStyles;
