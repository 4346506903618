import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { RoutePath } from 'config/routeConfig';
import { AgencyForm } from './AgencyForm/AgencyForm';
import { RequestForm } from './RequestForm/RequestForm';
import { AgencyType, ISignUpRequest } from 'models/auth';
import useMemberToken from '../../hooks/useMemberToken';

import { getAuthInited, signUp } from '../../store/reducers/auth';
import { useAppDispatch, useAppSelector } from '../../store';
import { classNames } from 'utils/classNames';

import styles from './SignUp.module.scss';

export enum SIGN_UP_STEP {
  REQUEST = 'REQUEST',
  REQUEST_TOKEN = 'REQUEST_TOKEN',
  AGENCY = 'AGENCY',
}

interface SignUpProps {
  className?: string;
}

const SignUp = ({ className }: SignUpProps) => {
  const dispatch = useAppDispatch();

  const [stepSignUp, setStepSignUp] = useState(SIGN_UP_STEP.REQUEST);
  const navigate = useNavigate();
  const { token } = useMemberToken();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISignUpRequest>({
    password: '',
    name: '',
    email: '',
    agencyName: '',
    zone: '',
    type: AgencyType.TEAM,
  });

  const [showingProcess, setShowingProcess] = useState(false);

  const inited = useAppSelector(getAuthInited);

  useEffect(() => {
    setTimeout(() => setShowingProcess(true), 1000);
  }, [inited]);

  const onNextToAgencyForm = useCallback(() => {
    setStepSignUp(SIGN_UP_STEP.AGENCY);
  }, []);

  const onBackToRequestForm = useCallback(() => {
    setStepSignUp(SIGN_UP_STEP.REQUEST);
  }, []);

  const onSubmit = useCallback(async () => {
    if (!token) {
      return;
    }

    setLoading(true);
    try {
      await dispatch(signUp({ ...data, memberToken: token }));
      navigate(RoutePath.accounts);

      setLoading(false);
    } catch (errors) {
      setLoading(false);
    }
  }, [data, token]);

  const onChangeData = (
    value: string | boolean | AgencyType,
    targetName?: string,
  ) => {
    if (!targetName) return;
    // TODO: fix
    if (targetName === 'type') {
      setData({ ...data, type: value as AgencyType, agencyName: '' });
    } else if (targetName === 'email') {
      setData({ ...data, [targetName]: (value as string).trim() });
    } else {
      setData({ ...data, [targetName]: value });
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    setStepSignUp(SIGN_UP_STEP.REQUEST_TOKEN);
  }, [token]);

  const render = (): JSX.Element => {
    switch (stepSignUp) {
      case SIGN_UP_STEP.AGENCY:
        return (
          <AgencyForm
            data={data}
            onChangeData={onChangeData}
            onBack={onBackToRequestForm}
          />
        );
      case SIGN_UP_STEP.REQUEST_TOKEN:
        return (
          <RequestForm
            data={data}
            onChangeData={onChangeData}
            onNext={onSubmit}
            buttonName="Register"
            buttonDisabled={loading}
          />
        );
      default:
        return (
          <RequestForm
            data={data}
            onChangeData={onChangeData}
            onNext={onNextToAgencyForm}
          />
        );
    }
  };

  return (
    <div
      className={classNames(
        styles.signUp,
        { [styles.showingProcess]: showingProcess },
        [className],
      )}
    >
      <div className={styles.form}>
        <div className={styles.body}>
          {render()}
          {stepSignUp !== SIGN_UP_STEP.REQUEST_TOKEN ? (
            <p className={styles.signInLink}>
              Already have an account?{' '}
              <Link to={RoutePath.signIn}>Login here!</Link>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
