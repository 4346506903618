import React, { useEffect } from 'react';
import { useElectronContext } from '../providers/ElectronProvider';
import { creatorsService } from '../../../services/CreatorsService';
import {
  getCreatorsList,
  loadCreatorsList,
} from '../../../store/reducers/creators';
import { useAppDispatch, useAppSelector } from '../../../store';
import { CreatorsSessionStatus } from '../../../constants/creators';
import { useChatterCharms } from '../../../providers/ChatterCharmsProvider';

const useMainListener = () => {
  const dispatch = useAppDispatch();

  const { creators: creatorsList } = useChatterCharms();

  const {
    activateLoginCreatorId,
    activateLoginSessionId,
    setActivateLoginSessionId,
    setActivateLoginCreatorId,

    setActiveCreator,
    activeCreator,
    setOfUrl,
    isActivatingModel,
  } = useElectronContext();

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }

    const eventName = 'creator-success-login';

    const cb = () => {
      window.electronAPI.send(`${eventName}-response`, {
        payload: {
          creatorId: activateLoginCreatorId,
          sessionId: activateLoginSessionId,
        },
      });
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [activateLoginCreatorId, activateLoginSessionId]);

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }

    const eventName = 'clear-creator-session-successful';

    const cb = () => {
      const creator = creatorsList.find(
        (item) => item.id === activateLoginCreatorId,
      );

      setActivateLoginSessionId(null);
      setActivateLoginCreatorId(null);

      if (creator) {
        setActiveCreator?.(creator);
      }

      dispatch(loadCreatorsList());

      window.electronAPI.send(`${eventName}-response`, {
        payload: true,
      });
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [activateLoginCreatorId, creatorsList]);

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }
    const eventName = 'activate-creator-session-url';

    const cb = async (_: IpcRendererEvent, payload: any) => {
      let result = false;

      try {
        const { creatorId, ...rest } = payload;
        const response = await creatorsService.activateCreatorSessionUrl(
          creatorId,
          rest,
        );

        //console.log('activateCreatorSessionUrl', response);
      } catch (error: any) {
        console.log({ error });
      }

      console.log('payload', payload);
      window.electronAPI.send(`${eventName}-response`, {
        payload: result,
      });
    };
    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, []);

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }
    const eventName = 'get-creator-by-id';

    const cb = async (_: IpcRendererEvent, payload: { creatorId: string }) => {
      let result = false;

      try {
        const { creatorId } = payload;
        const result = await creatorsService.getCreatorsById(creatorId);

        console.log('get-creator-by-id - response', result);
      } catch (error: any) {
        console.log({ error });
      }

      window.electronAPI.send(`${eventName}-response`, {
        payload: result,
      });
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, []);

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }
    const eventName = 'deactivate-creator';

    const cb = async (_: IpcRendererEvent, payload: any) => {
      let result = false;

      try {
        const { creatorId, sessionId } = payload;
        const response = await creatorsService.getDeactivateCreatorSessionUrl(
          creatorId,
          { sessionId },
        );

        console.log('activateCreatorSessionUrl', response);
      } catch (error: any) {
        console.log({ error });
      }

      console.log('payload', payload);
      window.electronAPI.send(`${eventName}-response`, {
        payload: result,
      });
    };
    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, []);

  //LOGIN response
  useEffect(() => {
    let umounted = false;
    if (!window.electronAPI) {
      return;
    }

    if (
      !activeCreator ||
      activeCreator.sessionStatus !== CreatorsSessionStatus.ACTIVE
    ) {
      return;
    }

    const eventName = 'deactivate-on-login';

    const cb = async (_: IpcRendererEvent, payload: any) => {
      console.log('!!!!!LOGOUT isActivatingModel', isActivatingModel);
      if (isActivatingModel) {
        return;
      }

      try {
        const { sessionId = null } = await creatorsService.getCreatorSessionUrl(
          activeCreator.id!,
        );

        if (!sessionId) {
          return;
        }

        if (umounted) {
          console.log('UNMOUNTED');
          return;
        }

        console.log('getDeactivateCreatorSessionUrl 1');
        await creatorsService.getDeactivateCreatorSessionUrl(
          activeCreator.id!,
          {
            sessionId,
          },
        );

        dispatch(loadCreatorsList());

        setOfUrl('');
        setActiveCreator?.(null);

        return;
      } catch (error: any) {
        console.error(`error deactivate-on-login: ${error}`);
      }
    };
    window.electronAPI.receive(eventName, cb);

    return () => {
      umounted = true;
      console.log('leave ', eventName);
      window.electronAPI.receiveOff(eventName);
    };
  }, [activeCreator, isActivatingModel]);
};

export default useMainListener;
