import React, { memo, useState } from 'react';

import styles from './Chart.module.scss';
import { classNames } from 'utils/classNames';
import LineChart from './Line/LineChart';
import HeatmapChart from './Heatmap/HeatmapChart';
import ColumnChart from './Column/ColumnChart';
import { StatisticFilters, chartStatusesConfig } from 'models/statistics';
import PieChart from './PieChart/PieChart';
import brandLogo from 'assets/images/whiteBrandLogo.png';

export enum ChartType {
    EARNINGS = "earnings",
    EARNINGS_BREAKDOWN = "earningsBreakdown",
    DAY_HOUR_EARNINGS_HEATMAP = "dayHourEarningsHeatmap",
    BEST_DAY = "bestDay",
    BEST_HOUR = "bestHour",
}

interface ChartProps {
    title?: string;
    type: ChartType;
    className?: string;
    commonFilters: StatisticFilters;
    withoutFilterContentType?: boolean;
    needWaterMark?: boolean;
    halfView?: boolean;
}


const WaterMark = () => (
    <div className={styles.waterMark}>
        <img src={brandLogo} alt="Brand Logo"  />
        <span>ChatterCharms.ai</span>
        
    </div>
);

const Chart = ({ type, title, className, commonFilters, withoutFilterContentType, needWaterMark, halfView }: ChartProps) => {
    const [chartContentType, setChartContentType] = useState("All");

    const updatedFiltersWithType: StatisticFilters = withoutFilterContentType ? { ...commonFilters } : {
        ...commonFilters,
        type: chartContentType !== "All" ? chartContentType.toLowerCase() : undefined
    }

    const renderChart = () => {
        switch (type) {
            case ChartType.EARNINGS: {
                return <LineChart filters={updatedFiltersWithType} name={type} />
            }
            case ChartType.DAY_HOUR_EARNINGS_HEATMAP: {
                return <HeatmapChart filters={updatedFiltersWithType} name={type} />
            }
            case ChartType.BEST_DAY:
            case ChartType.BEST_HOUR: {
                return <ColumnChart filters={updatedFiltersWithType} name={type} />
            }
            case ChartType.EARNINGS_BREAKDOWN: {
                return <PieChart name={type} filters={updatedFiltersWithType} />
            }

            default: {
                return <></>
            }
        }
    }

    const onClickChartContentType = (item: string) => {
        setChartContentType(item);
    }

    return (
        <div className={classNames(styles.chart, {[styles.halfView]: halfView}, [className])}>
            <div className={styles.header}>
                <h2>{title}</h2>
                {!withoutFilterContentType && (<div className={styles.statuses}>
                    {chartStatusesConfig.map(item => (
                        <div
                            onClick={() => onClickChartContentType(item)}
                            className={classNames("", { [styles.active]: chartContentType === item })}>
                            {item}
                        </div>
                    ))}
                </div>)}
            </div>
            {renderChart()}
            {needWaterMark && <WaterMark />}
        </div>
    );
};

export default memo(Chart);
