import { useEffect, useState } from "react";

import { useAppSelector } from "../../../store";
import { getRolesList } from "../../../store/reducers/roles";
import { classNames } from "../../../utils/classNames";
import { ALL } from "../constants";
import styles from "./TableFilter.module.scss";

interface ITableFilter {
  onChange: (value: string) => void;
}

const TableFilter = ({ onChange }: ITableFilter) => {
  const { items: roles } = useAppSelector(getRolesList);

  const [activeTab, setActiveTab] = useState<string>(ALL);

  useEffect(() => {
    onChange(activeTab);
  }, [activeTab]);

  return (
    <div className={styles.filters}>
      <button
        onClick={() => setActiveTab(ALL)}
        className={classNames(styles.filterControl, {
          [styles.active]: activeTab === ALL,
        })}
      >
        Show All
      </button>
      {roles.map((role) => (
        <button
          key={role.id}
          onClick={() => setActiveTab(role.id!)}
          className={classNames(styles.filterControl, {
            [styles.active]: activeTab === role.id,
          })}
        >
          {role.name}
        </button>
      ))}
    </div>
  );
};

export default TableFilter;
