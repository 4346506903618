import Accounts from "components/Accounts/Accounts";
import { MutableRefObject, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getCreatorsInited, loadCreatorsList } from "store/reducers/creators";

const GET_CREATORS_INTERVAL = 20000;

const CreatorsPage = () => {
    const timerRef = useRef() as MutableRefObject<ReturnType<typeof setInterval>>;
    const inited = useAppSelector(getCreatorsInited);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadCreatorsList());
    }, []);

    useEffect(() => {
        !inited && dispatch(loadCreatorsList());

        timerRef.current = setInterval(async () => {
            dispatch(loadCreatorsList());
        }, GET_CREATORS_INTERVAL);

        return () => clearTimeout(timerRef.current);
    }, [inited]);


    return <Accounts />
}

export default CreatorsPage;