import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store';

import { RoutePath } from '../../config/routeConfig';

import { getCreator, loadCreatorById, loadCreatorsDictionary } from '../../store/reducers/creators';

import { CurrentPlan } from './CurrentPlan/CurrentPlan';
import { ProxySettings } from './ProxySettings/ProxySettings';
import { AssignedMembers } from './AssignedMembers/AssignedMembers';
import { AccountInfoForm } from './AccountInfoForm/AccountInfoForm';

import styles from './EditAccount.module.scss';
import Loader from '../../modules/electron/components/OFSidebar/components/Loader';
import { Button, ThemeButton } from '../Button/Button';
import { creatorsService } from '../../services/CreatorsService';

export const EditAccount = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const creator = useAppSelector(getCreator);

  const accountId = useMemo(() => searchParams.get('id'), [searchParams])

  const onDeleteCreator = useCallback(async () => {
    if (!accountId) return;
    await creatorsService.deleteCreator(accountId);
    navigate(RoutePath.accounts);
  }, [accountId]);

  useEffect(() => {
    if (accountId) {
      dispatch(loadCreatorsDictionary());
      dispatch(loadCreatorById({ id: accountId}));
    } else {
      navigate(RoutePath.settings);
    }
  }, [accountId]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Edit Account</h1>
        <Button
          onClick={onDeleteCreator}
          className={styles.btn}
          theme={ThemeButton.DARK_OUTLINE}
        >
          Delete creator
        </Button>
      </div>
      {!creator
        ?  <div className={styles.loaderBox}><Loader /></div>
        : (
          <div className={styles.forms}>
            <AccountInfoForm data={creator} />
            <div className={styles.rightBox}>
              <CurrentPlan data={creator} />
              <AssignedMembers data={creator} />
              <ProxySettings data={creator} />
            </div>
          </div>
        )
      }
    </div>
  )
}
