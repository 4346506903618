import { initAuthData, getAuthInited } from './../store/reducers/auth';
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { getUserAuthData, authActions } from "store/reducers/auth";
import { isElectron } from 'modules/electron/helpers';

const useInitApp = () => {
    const inited = useAppSelector(getAuthInited);
    const isAuth = useAppSelector(getUserAuthData);
    const dispatch = useAppDispatch();

    const downloadPageLink = process.env.REACT_APP_DOWNLOAD_PAGE;
    const isLocalhost = window.location.hostname === "localhost"; // TODO - replace to env.local variable

    useEffect(() => {
        (async () => {
            if (!isElectron && isAuth && downloadPageLink && !isLocalhost) {
                // if web version, need redirect to download page
                dispatch(authActions.logout());
                window.location.href = downloadPageLink;
            }
            
            !inited && await dispatch(initAuthData());
        })();
    }, [inited, isAuth]);

    return inited && downloadPageLink
};

export default useInitApp;
