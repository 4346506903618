import React from 'react';

import { useAppSelector } from '../../../../store';
import { getUserAuthData } from '../../../../store/reducers/auth';
import { useElectronContext } from '../../providers/ElectronProvider/ElectronProvider';

import { OfSidebarProvider } from './providers';
import OFSidebarView from './OFSIdebarView';

const OFSidebar = () => {
  const isAuth = useAppSelector(getUserAuthData);
  const { activeCreator } = useElectronContext();

  if (!isAuth || !activeCreator) {
    return null;
  }

  return (
    <OfSidebarProvider>
      <OFSidebarView />
    </OfSidebarProvider>
  );
};

export default OFSidebar;
