import { useState, useEffect, useCallback } from 'react';
import { classNames } from 'utils/classNames';
import styles from './RequestForm.module.scss';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as LockIcon } from '../../../assets/images/lock.svg';
import { ReactComponent as UserIcon } from '../../../assets/images/user.svg';
import { Button, ThemeButton } from 'components/Button/Button';

import Input from 'components/fields/Input/Input';
import { DeepPartial } from '@reduxjs/toolkit';
import { yupToFormErrors } from 'formik';
import { signUpFormSchema } from 'utils/validators';
import { ISignUpRequest } from 'models/auth';
import { ThemeField } from 'models/fields';
import { useAppDispatch, useAppSelector } from 'store';
import { authActions, getUserAuthErrors } from 'store/reducers/auth';
import { getApiErrorMessageByField } from 'constants/messages';

const TERMS_AND_PRIVACY_LINK =
  'https://docs.google.com/document/d/1dbgkxfmICGE0i809BRaewsmyT_m8AgfABN0A8nSybS0/edit';

interface RequestFormProps {
  data: ISignUpRequest;
  className?: string;
  onChangeData: (value: string, targetName?: string) => void;
  onNext: () => void;
  buttonName?: string;
  buttonDisabled?: boolean;
}

export const RequestForm = ({
  data,
  className,
  onChangeData,
  onNext,
  buttonName,
  buttonDisabled = false,
}: RequestFormProps) => {
  const { email, name, password } = data;
  const [validateErrors, setValidateErrors] = useState<
    DeepPartial<ISignUpRequest>
  >({});
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const apiErrors = useAppSelector(getUserAuthErrors);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setValidateErrors({});
    dispatch(authActions.updateAuthErrors(undefined));
  }, [email, name, password]);

  const onClickNextButton = async () => {
    try {
      await signUpFormSchema.validate(
        { email, password, name },
        { abortEarly: false },
      );
      onNext();
    } catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    }
  };

  const onPressKeyButton = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClickNextButton();
    }
  };

  const togglePrivacyPolicyAccepted = useCallback(() => {
    setPrivacyPolicyAccepted((prev) => !prev);
  }, []);

  return (
    <div className={classNames(styles.RequestForm, {}, [className])}>
      <h1 className={styles.title}>Sign Up</h1>
      <Input
        name="email"
        fieldName="E-mail"
        placeholder="Ex: abc@example.com"
        theme={ThemeField.LIGHT}
        value={email}
        onChange={onChangeData}
        className={styles.input}
        error={
          validateErrors.email || getApiErrorMessageByField('email', apiErrors)
        }
        onKeyUp={onPressKeyButton}
        mandatory
      />
      <Input
        name="name"
        fieldName="Name"
        placeholder="Ex: John Wick"
        theme={ThemeField.LIGHT}
        value={name}
        onChange={onChangeData}
        className={styles.input}
        error={validateErrors.name}
        onKeyUp={onPressKeyButton}
        mandatory
      />
      <Input
        name="password"
        fieldName="Password"
        placeholder="Enter your password"
        theme={ThemeField.LIGHT}
        value={password}
        onChange={onChangeData}
        type="password"
        className={styles.input}
        error={
          validateErrors.password ||
          getApiErrorMessageByField('password', apiErrors)
        }
        onKeyUp={onPressKeyButton}
        mandatory
      />
      <div
        className={styles.privacyPolicy}
        onClick={togglePrivacyPolicyAccepted}
      >
        <input
          id="privacyPolicy"
          type="checkbox"
          onClick={(e) => e.stopPropagation()}
          checked={privacyPolicyAccepted}
        />
        <label htmlFor="privacyPolicy">
          I have read and agree to the{' '}
          <a href={TERMS_AND_PRIVACY_LINK} target="_blank" rel="noreferrer">
            Terms of Service and Privacy Policy
          </a>
        </label>
      </div>

      <Button
        onClick={onClickNextButton}
        theme={ThemeButton.PRIMARY}
        className={styles.button}
        disabled={buttonDisabled || !privacyPolicyAccepted}
      >
        {buttonName || 'Next'}
      </Button>
    </div>
  );
};
