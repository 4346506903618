import { memo } from 'react';
import styles from './SuccessfulInfo.module.scss';
import { Button, ThemeButton } from 'components/Button/Button';
import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';

interface SuccessfulInfoProps {
    onClose?: () => void;
}

export const SuccessfulInfo = memo(({ onClose }: SuccessfulInfoProps) => {

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Success!</h2>
            <p className={styles.subtitle}>Keep in mind:</p>
            <div className={styles.description}>
                Typically, the account will successfully link within 5-15 minutes. After linking, 
                it may take some time to load the data (depending on the Creator's specifications), 
                but this won't impact your use of ChatterCharms.
            </div>
            <div className={styles.actions}>
                <Button theme={ThemeButton.PRIMARY} onClick={onClose}>Ok</Button>
            </div>
        </div>
    );
});
