import React from 'react';

import { useElectronContext } from '../../providers';

import Webview from '../Webview';
import EmojiDashboard from '../EmojiDashboard';

const WebviewDashboard = () => {
  const { ofUrl } = useElectronContext();

  return (
    <>
      <Webview src={ofUrl} />
      <EmojiDashboard />
    </>
  );
};

export default WebviewDashboard;
