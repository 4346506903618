export enum BillingColumns {
    ACCOUNT,
    EARNINGS,
    PLAN,
    M1,
    M3,
    M6
}

export enum BillingHistoryColumns {
    DATE,
    AMOUNT,
    DESCRIPTION
}

export enum BillingPaymentStep {
    ACCOUNT = "ACCOUNT",
    BUSINESS_PURCHASE = "BUSINESS_PURCHASE",
}

export enum PaymentMethod {
    CREDIT_CARD = "Credit/Debit Card",
}

export const earningColorsMap = [
    {
        from: 0,
        to: 1000,
        color: "#F7931A"
    },
    {
        from: 1000,
        to: 2000,
        color: "#26A17B"
    },
    {
        from: 2000,
        to: 5000,
        color: "#6F41D8"
    },
    {
        from: 5000,
        to: 7500,
        color: "#FF007A"
    },
    {
        from: 7500,
        to: 10000,
        color: "#F3BA2F"
    },
    {
        from: 10000,
        to: 15000,
        color: "#516AFF"
    },
    {
        from: 15000,
        to: 30000,
        color: "#EF0027"
    },
    {
        from: 30000,
        color: "#29F1D9"
    },
];

export const getEarningColor = (earnings: string) => {
    const earningValue = Number(earnings);
    const { color } = earningColorsMap.find(({ from, to }) => earningValue >= from && (!to || earningValue < to)) || {};

    return color;
}

export const AI_COPILOT_NOTIFY = 'AiCopilotNotify';
