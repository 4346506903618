import { useCallback, useEffect, useState } from 'react';
import { IconButton, Box } from '@mui/material';

import { Reload } from '../../../../../icons';
import Input from '../Form/Input';

import { useElectronContext } from '../../../../../providers/ElectronProvider';

import useStyles from './useStyles';

interface IForm {
  onChange: (value: number | string) => void;
}

const Filter = ({ onChange }: IForm) => {
  const styles = useStyles();

  const { loadOnlineChatters, activeCreator } = useElectronContext();
  const [total, setTotal] = useState<number | string>('');

  const handleChange = useCallback((value: string) => {
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setTotal(+value);
    }
  }, []);

  const handleReload = useCallback(async () => {
    if (!activeCreator?.id) {
      return;
    }

    await loadOnlineChatters(activeCreator?.id);
  }, [activeCreator, loadOnlineChatters]);

  useEffect(() => {
    onChange(total);
  }, [total, onChange]);

  return (
    <Box sx={styles.root}>
      <Box>
        Who spent ≥ <Input value={total} onChange={handleChange} />$
      </Box>
      <IconButton onClick={handleReload}>
        <Reload />
      </IconButton>
    </Box>
  );
};

export default Filter;
