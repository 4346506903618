import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { IMedia } from '../../../../models/media';
import { scrappingService } from '../../../../services/ScrapingService';
import { useElectronContext } from '../ElectronProvider';

import { IVaultProviderContext } from './interfaces';

const DefaultContext = {
  isLoading: false,
  media: [],
};

export const ContentContext =
  React.createContext<IVaultProviderContext>(DefaultContext);

export const useVaultProviderContext = () => React.useContext(ContentContext);

export const VaultProvider = ({ children }: { children: ReactNode }) => {
  const { activeChatterId, activeCreator } = useElectronContext();

  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState<IMedia[]>([]);

  const loadMedia = useCallback(
    async (creatorId: string, chatterId: string) => {
      setIsLoading(true);
      try {
        const data = await scrappingService.getMedia({
          creatorId,
          chatterId,
        });

        setMedia(data.media ?? []);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (!activeChatterId || !activeCreator?.id) {
      return;
    }

    loadMedia(activeCreator?.id, activeChatterId);
  }, [activeCreator?.id, activeChatterId]);

  return (
    <ContentContext.Provider value={{ isLoading, media }}>
      {children}
    </ContentContext.Provider>
  );
};
