import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      paddingTop: '30px',
      paddingBottom: '26px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '40px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        paddingTop: '23px',
        paddingBottom: '23px',
        gap: '36px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        paddingTop: '15px',
        paddingBottom: '13px',
        gap: '20px',
      },

      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        height: '1px',
        background: 'rgba(0, 175, 240, 0.05)',
      },
    },
    content: {
      padding: '45px 28px 20px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        padding: '25px 21px 15px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        padding: '25px 15px 15px',
      },
    },
    hidden: {
      display: 'none',
    },
  } as Record<string, any>;
};

export default useStyles;
