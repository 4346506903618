import React from 'react';

import Card from '../Card/Card';
import { Row } from './Row/Row';

import { useChatterCharms } from '../../../providers/ChatterCharmsProvider';

import { ICreators } from '../../../models/creators';

import { ReactComponent as AssignedMembersIcon } from "assets/images/editAccount/assignedMembers.svg";

import styles from './AssignedMembers.module.scss';

interface Props {
  data: ICreators
}

export const AssignedMembers = ({ data }: Props) => {
  const { rolesMap } = useChatterCharms();

  return (
    <Card title='Assigned Members' icon={<AssignedMembersIcon />}>
      {data.members.length ? data.members.map((member) => {
        const role = rolesMap?.get(member.roleId);
        if (role) {
          const { color } = role;
          const bg = color + "50";

          return (
            <Row
              isLine
              key={member.memberId}
              roleName={role.name}
              item={member}
              color={color}
              bg={bg}
            />
          )
        }
      }): <p className={styles.noData}>No assigned members</p>}
    </Card>
  )
}
