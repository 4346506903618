export enum MediaStatusEnum {
  Free = 'Free',
  NotPurchased = 'NotPurchased',
  Paid = 'Paid',
}

export interface IMedia {
  createdAt: string;
  expiredTime: number;
  image: string;
  price: string;
  ofMediaId: number;
  ofChatterId: string;
  creatorId: string;
  text: string;
  thumbName: string;
  status: MediaStatusEnum;
  updatedAt: string;
}

export interface ISubscriberMedia {
  media: IMedia[];
}
