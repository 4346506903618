import { CreatorsSessionStatus } from "constants/creators";

export interface IProxy {
    host: string;
    password: string;
    port: string;
    username: string;
}

export enum SubscriptionStatus {
    PAID = 'PAID',
    UNPAID = 'UNPAID',
    TRIAL = 'TRIAL',
}

export interface CreatorSubscription {
    endDate: string
    status: SubscriptionStatus
}

export const NOT_SET_PROXY = "NOT_SET_PROXY";

export interface ICreators {
    imageLink: string;
    //bodyType: string;
    country: string;
    notes: string;
    city: string;
    //bustSize: string;
    name: string;
    ofLink: string;
    fetish: string[];
    birthDate: string;
    //height: string;
    conversationalStyle: string;
    proxyCountry?: string;
    sex: string;

    ofId?: number;
    ofNick?: string;
    subscriptionPrice?: string;
    sessionStatus?: CreatorsSessionStatus;
    agencyId?: string;
    id?: string;
    proxy?: IProxy;
    proxyId?: string;
    updatedAt?: string;
    createdAt?: string;
    subscription: CreatorSubscription;
    members: CreatorMember[];
    training?: { status: string };
    changeProxy?: boolean;
}

export interface ICreatorsList {
    items: ICreators[];
    cursor?: string;
};

export interface IBodyType {
    name: string;
    description: string;
}

export interface ICreatorsDictionary {
    sex?: string[];
    conversationalStyle?: string[];
    fetish?: string[];
    proxyCountry?: string[];
}

export interface CreatorMember {
    roleId:	string
    memberId:	string
    memberName:	string
}
