export interface IMainStatistics {
    totalEarnings: string;
    totalPurchase: string;
    avgPerFan: string;
    activeFans: string;
}

export interface IEarnings {
    value: string;
    sum: string;
    percentage?: string;
}

export interface IEarningsChargebacks {
    totalAmount: string;
    totalCount: number;
}

export interface IEarningsHeatmap {
    data: Array<{ x: string, y: string }>,
    name: string
}

export interface IEarningsStatsItem {
    total: string;
    delta: string;
    chart: Array<IEarnings>
}

export interface IEarningsStats {
    total: IEarningsStatsItem,
    post: IEarningsStatsItem,
    stream: IEarningsStatsItem,
    rSubscription: IEarningsStatsItem,
    tip: IEarningsStatsItem,
    subscription: IEarningsStatsItem,
    message: IEarningsStatsItem
}


export interface IRunRate {
    runRate30: string;
    runRate7: string;
    accountNumber: number;
}

export interface ITopFanUser {
    totalSpent: number;
    avgSpent: number;
    name: string;
    lastPurchase: string;
    userId: string;
    username: string;
}

export const chartStatusesConfig = [
    "All",
    "Subscription",
    "Recurring subscription",
    "Tip",
    "Post",
    "Message",
    "Stream"
]

export enum ChartStatuses {
    ALL = "All",
    SUBS = "Subscriptions",
    REC_SUBS = "Reccurring subscriptions",
    TIPS = "Tips",
    POSTS = "Posts",
    MESSAGE = "Message",
    STREAMS = "Streams"
}

export interface StatisticFilters {
    creatorIds: string[];
    from: string,
    to: string,
    revenue: string;
    type?: string;
}

export enum EarningTxType {
    Subscription = 'subscription',
    RecurringSubscription = 'recurring subscription',
    Tip = 'tip',
    Post = 'post',
    Message = 'message',
    Stream = 'stream',
  }