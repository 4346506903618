import React, { useEffect, useState } from 'react';
import { classNames } from 'utils/classNames';
import { BillingColumns, getEarningColor } from 'constants/billing';
import { IColumn } from 'models/table';
import { Button, SizeButton, ThemeButton } from 'components/Button/Button';
import CustomizedTable from 'components/CustomizedTable/CustomizedTable';
import styles from './SubscriptionTable.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getBillingBalance,
  getBillingSubscriptions,
  loadCurrentBalance,
  loadSubscriptions,
} from 'store/reducers/billing';
import {
  ISelectedPlanData,
  ISubscriptions,
  PlanAccount,
  subscriptionStatusMap,
} from 'models/billing';
import moment from 'moment';
import { billingService } from 'services/BillingService';
import { SubscriptionStatus } from '../../../models/creators';
import { modalActions } from '../../../store/reducers/modal';
import { ModalType } from '../../../constants/modal';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import { Tooltip } from '../../Tooltip/Tooltip';
import { useSnackbar } from 'notistack';
import { ApiErrorCode, getErrorMessage } from '../../../constants/messages';

const columns: IColumn[] = [
  {
    id: BillingColumns.ACCOUNT,
    label: 'Account',
    width: 300,
    isFixed: true,
  },
  {
    id: BillingColumns.EARNINGS,
    label: 'Earnings',
    width: 150,
    isFixed: true,
  },
  {
    id: BillingColumns.PLAN,
    label: '',
    width: 200,
    isFixed: true,
    align: 'left',
  },
  {
    id: BillingColumns.M1,
    fieldName: PlanAccount.M1,
    label: '1 Month',
    width: 200,
    align: 'center',
    isFixed: true,
  },
  {
    id: BillingColumns.M3,
    fieldName: PlanAccount.M3,
    label: '3 Months',
    width: 200,
    align: 'center',
    isFixed: true,
  },
  {
    id: BillingColumns.M6,
    fieldName: PlanAccount.M6,
    label: '6 Months',
    width: 200,
    tooltipContent:
      '6 Months plan provides top priority for AI Copilot development',
    isOpenTooltip: true,
    tooltipTimeout: 5000,
    align: 'center',
    isFixed: true,
  },
];

interface SubscriptionTableProps {
  className?: string;
}

export const SubscriptionTable = ({ className }: SubscriptionTableProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedPlans, setSelectedPlans] = useState<ISelectedPlanData[]>([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { items, loading } = useAppSelector(getBillingSubscriptions);
  const dispatch = useAppDispatch();

  const balance = useAppSelector(getBillingBalance);

  const totalWithDiscount = selectedPlans.reduce(
    (total, data) => total + (data?.discount || data?.regular || 0),
    0,
  );
  const total = selectedPlans.reduce(
    (total, data) => total + (data?.regular || 0),
    0,
  );

  const onClickMonthPlanBtn = (value: ISelectedPlanData) => {
    if (paymentLoading || value.disabled) return;

    const updatedSelectedPlans = [...selectedPlans];
    const index = updatedSelectedPlans.findIndex(
      (item) => item?.creatorId === value?.creatorId,
    );

    // If any plan is selected for cretor. Check if you clicked on the same plan (toggle) or another from the one creator
    if (index !== -1) {
      // Toggle implementation. When you click on the same plan to turn it off
      if (updatedSelectedPlans[index]?.plan === value.plan) {
        updatedSelectedPlans.splice(index, 1);
      } else {
        updatedSelectedPlans[index] = value;
      }
    } else updatedSelectedPlans.push(value);

    setSelectedPlans(updatedSelectedPlans);
  };

  const onClickMakePayment = async () => {
    if (!selectedPlans.length) return;

    if (totalWithDiscount > balance || balance === 0) {
      enqueueSnackbar(
        getErrorMessage(ApiErrorCode.INSUFFICIENT_BALANCE_ERROR),
        {
          variant: 'error',
        },
      );
      return;
    }

    setPaymentLoading(true);
    try {
      const data = selectedPlans.map(({ creatorId, plan }) => ({
        creatorId,
        plan,
      }));
      const response = await billingService.createPaymentSubscriptions(data);
      dispatch(loadCurrentBalance());
      dispatch(loadSubscriptions());
      dispatch(
        modalActions.setModalType({
          type: ModalType.BILLING_PAYMENT_SUCCESSFUL,
        }),
      );
    } catch (e) {}
    setSelectedPlans([]);
    setPaymentLoading(false);
  };

  const onRenderColumnItem = (
    item: ISubscriptions,
    index: number,
    column: IColumn,
  ) => {
    const fieldName = column?.fieldName;

    switch (column?.id) {
      case BillingColumns.ACCOUNT: {
        const { creatorName, creatorUsername, creator } = item;
        return (
          <div className={styles.account}>
            <div className={styles.avatar}>
              <img src={creator?.imageLink} alt="" />
            </div>
            <div className={styles.info}>
              <p className={styles.name}>{creatorName}</p>
              <p className={styles.ofNick}>@{creatorUsername}</p>
            </div>
          </div>
        );
      }
      case BillingColumns.EARNINGS: {
        const { stats30D } = item;
        return <p className={styles.earnings}>${stats30D}</p>;
      }
      case BillingColumns.PLAN: {
        const { status, endDate } = item;

        return (
          <div className={styles.statusPlan}>
            <p className={styles.status}>{subscriptionStatusMap[status]}</p>
            {status !== SubscriptionStatus.UNPAID && (
              <p className={styles.expiry}>
                Expiry {moment(endDate).format('MMMM DD, YYYY')}
              </p>
            )}
          </div>
        );
      }
      case BillingColumns.M1:
      case BillingColumns.M3:
      case BillingColumns.M6: {
        const creatorId = item.creatorId;
        const color = getEarningColor(item.stats30D);

        const disabled = item.status === SubscriptionStatus.PAID;

        const planPrices = item[fieldName as PlanAccount] || {};
        const { discount = 0, regular = 0 } = planPrices;
        const value = discount || regular;

        const isSelected =
          selectedPlans.findIndex(
            (item) => item?.creatorId === creatorId && item.plan === fieldName,
          ) !== -1;

        return (
          <>
            <span
              style={{
                borderColor: color,
                backgroundColor: isSelected ? color : undefined,
              }}
              className={classNames(styles.plan, {
                [styles.disabled]: disabled,
              })}
              onClick={() =>
                onClickMonthPlanBtn({
                  plan: fieldName as PlanAccount,
                  creatorId,
                  discount,
                  regular,
                  disabled,
                })
              }
            >
              {!!discount && (
                <span className={styles.discount}>${regular}</span>
              )}
              <span>${value}</span>
            </span>
            {disabled && (
              <Tooltip
                className={styles.tooltip}
                placement="top"
                title="Subscription may be renewed after the end of the current one"
              >
                <InfoIcon className={styles.info} />
              </Tooltip>
            )}
          </>
        );
      }
      default: {
        if (fieldName && item) {
          return <p>{(item as any)[fieldName]}</p>;
        }
        return <></>;
      }
    }
  };

  return (
    <div className={styles.table}>
      <div className={styles.top}>
        <p className={styles.title}>Subscriptions</p>
        <p>Select a plan for each account</p>
      </div>
      <CustomizedTable
        items={items}
        columns={columns}
        onRenderColumnItem={onRenderColumnItem}
        loading={loading}
      />
      <div className={styles.footer}>
        <div className={styles.totals}>
          <p>
            Total Amount to Pay: <span>${totalWithDiscount}</span>
          </p>
          <p>Money Saved: ${total - totalWithDiscount}</p>
        </div>
        <Button
          className={styles.makePaymentBtn}
          theme={ThemeButton.PRIMARY}
          onClick={onClickMakePayment}
          loading={paymentLoading}
          size={SizeButton.M}
          disabled={!selectedPlans.length}
        >
          Make payment
        </Button>
      </div>
    </div>
  );
};
