import { ReactComponent as OnlyfansIcon } from 'assets/images/statistics/onlyfans.svg';
import { ReactComponent as PurchaseIcon } from 'assets/images/statistics/purchase.svg';
import { ReactComponent as ActiveFansIcon } from 'assets/images/statistics/active-fans.svg';
import { ReactComponent as AvgPerFanIcon } from 'assets/images/billing/balance.svg';
import { IMainStatistics } from 'models/statistics';

export type MainStatisticsFieldNames = keyof IMainStatistics;

export interface MainStatisticsItemType {
    title: string,
    value: string,
    Icon: React.VFC<React.SVGProps<SVGSVGElement>>;
    isMoney?: boolean;
}

export const mainStatisticsList: Record<MainStatisticsFieldNames, MainStatisticsItemType > = {
    totalEarnings: {
        title: "Total Earnings",
        value: "",
        Icon: OnlyfansIcon,
        isMoney: true
    },
    totalPurchase: {
        title: "Total Purchases",
        value: "",
        Icon: PurchaseIcon
    },
    activeFans: {
        title: "Active Fans",
        value: "",
        Icon: ActiveFansIcon
    },
    avgPerFan: {
        title: "Avg. Per Fan",
        value: "",
        Icon: AvgPerFanIcon,
        isMoney: true
    }
}

export enum TopFansColumns {
    No,
    NAME,
    USERNAME,
    LAST_PURCHASE,
    AVG_SPENT,
    TOTAL_SPENT
}

export enum TimeRangeLabel {
    TODAY = "Today",
    YESTERDAY = "Yesterday",
    THIS_WEEK = "This Week", 
    THIS_MONTH = "This Month"
}