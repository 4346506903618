import { useEffect } from "react";
import {useAppDispatch} from "../../store";
import Members from "components/MembersTable/Members";
import { loadMembersList} from "../../store/reducers/members";

const MembersPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadMembersList());
  }, [dispatch]);

  return <Members />;
};

export default MembersPage;
