import { useCallback, useEffect, useState } from 'react';
import styles from './BillingPayment.module.scss';
import { classNames } from 'utils/classNames';
import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as BrandLogo } from 'assets/images/temp/blue-logo.svg';
import { Button, ThemeButton } from 'components/Button/Button';
import { BillingPaymentStep } from 'constants/billing';
import { AccountStep } from './AccountStep/AccountStep';
import { BusinessPurchaseStep } from './BusinessPurchaseStep/BusinessPurchaseStep';
import { useAppDispatch, useAppSelector } from 'store';
import { createOrUpdatePaymentCustomer, getBillingAmount } from 'store/reducers/billing';
import { billingService } from 'services/BillingService';
import { IPaymentAccountInfo, IPaymentBusinessPurchase, IPaymentCustomer } from 'models/billing';
import { paymentPopupSchema, rolesSchema } from 'utils/validators';
import { IValidateFlags } from 'models/validation';
import { yupToFormErrors } from 'formik';
import { PAYMENT_IDS } from 'config/api';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import axios from 'axios';
import { ApiErrorCode, getErrorMessage } from 'constants/messages';



interface BillingPaymentProps {
    onClose?: () => void;
    className?: string;
}

export const BillingPayment = ({ className, onClose }: BillingPaymentProps) => {
    const [activeStep, setActiveStep] = useState(BillingPaymentStep.ACCOUNT);
    const [paymentCustomer, setPaymentCustomer] = useState<Partial<IPaymentCustomer>>({ isBusiness: false });
    const [validateErrors, setValidateErrors] = useState<IValidateFlags<IPaymentCustomer>>({});
    const [loading, setLoading] = useState(false);

    const billingAmount = useAppSelector(getBillingAmount);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setValidateErrors({});
    }, [paymentCustomer]);

    const onChangePaymentCustomer = useCallback((value: any, targetName?: string) => {
        targetName && setPaymentCustomer(prev => ({ ...prev, [targetName]: value }) as IPaymentCustomer);
    }, [setPaymentCustomer]);

    const validateStep = useCallback(async () => {
        const validationSchema = activeStep === BillingPaymentStep.ACCOUNT ? paymentPopupSchema.accountStep : paymentPopupSchema.businessPurchaseStep;

        try {
            await validationSchema.validate({ ...paymentCustomer }, { abortEarly: false });
        } catch (errors) {
            setValidateErrors(yupToFormErrors(errors));
            return false;
        }

        return true;
    }, [activeStep, paymentCustomer]);

    const onClickNextStepBtn = useCallback(async () => {
        const isValidStep = await validateStep();

        if (!isValidStep) return;

        if (activeStep === BillingPaymentStep.ACCOUNT && paymentCustomer.isBusiness) {
            setActiveStep(BillingPaymentStep.BUSINESS_PURCHASE);
            return;
        } else {
            setLoading(true);
            try {
                const response = await dispatch(createOrUpdatePaymentCustomer(paymentCustomer as IPaymentCustomer));

                if (response.meta.requestStatus === "rejected") {
                    setLoading(false);
                    return;
                };

                const payment = await billingService.createPaymentInvoice(+billingAmount!);
                const redirectLink = payment?.invoice?.hosted_invoice_url;
                const id = payment?.invoice?.id;

                if (redirectLink && id) {
                    window.open(payment.invoice.hosted_invoice_url, '_blank');
                    localStorage.setItem(PAYMENT_IDS, id);
                    onClose?.();
                }
            } catch(errors) {
                if (axios.isAxiosError(errors)) {
                    const { data } = errors.response ?? {};
            
                    const errorCode: ApiErrorCode = data?.errors[0]?.message;
                    const errorMessage = getErrorMessage(errorCode);
                    setValidateErrors({...validateErrors, postalCode: errorMessage});
                  }
            }

            setLoading(false);
        }
    }, [activeStep, paymentCustomer, billingAmount, dispatch, onClose, validateStep]);

    const onBackStep = () => {
        setActiveStep(BillingPaymentStep.ACCOUNT);
    }

    const renderForm = useCallback(() => {
        switch (activeStep) {
            case BillingPaymentStep.ACCOUNT: {
                const { email, phone, name, country, province, isBusiness, postalCode } = paymentCustomer;
                const data: IPaymentAccountInfo = {
                    email,
                    phone,
                    name,
                    country,
                    province,
                    isBusiness,
                    postalCode
                };

                return <AccountStep data={data} errors={validateErrors} onChange={onChangePaymentCustomer} />
            }
            case BillingPaymentStep.BUSINESS_PURCHASE: {
                const { vatType, city, businessName, businessId, postalCode, fullAddress } = paymentCustomer;
                const data: IPaymentBusinessPurchase = {
                    vatType,
                    city,
                    businessName,
                    businessId,
                    postalCode,
                    fullAddress,
                };

                return <BusinessPurchaseStep data={data} errors={validateErrors} onChange={onChangePaymentCustomer} />
            }
        }
    }, [paymentCustomer, activeStep, validateErrors, onChangePaymentCustomer]);



    return (
        <div className={classNames(styles.container, {}, [className])}>
            <div className={styles.header}>
                {activeStep !==  BillingPaymentStep.ACCOUNT && (
                    <span onClick={onBackStep} className={styles.back}><ArrowIcon /></span>
                )}

                <div className={styles.logo}>
                    <BrandLogo />

                </div>
                <CloseIcon className={styles.close} onClick={onClose} />
            </div>
            <div className={styles.body}>
                <div className={styles.balance}>
                    <p className={styles.title}>Add funds balance:</p>
                    <p className={styles.value}>${billingAmount}</p>
                </div>
                <div className={styles.form}>{renderForm()}</div>
            </div>
            <div className={styles.next}>
                <Button theme={ThemeButton.PRIMARY} loading={loading} onClick={onClickNextStepBtn}>Next</Button>
            </div>

        </div>
    );
};
