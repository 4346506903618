import { Moment } from 'moment';
import styles from './DatePicker.module.scss';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DatePickerProps } from '@mui/x-date-pickers';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { classNames } from 'utils/classNames';
import { INamedFieldProps, NamedField } from '../NamedField/NamedField';
import { ThemeField } from 'models/fields';

interface IDatePickerProps extends DatePickerProps<Moment>, INamedFieldProps {
    theme?: ThemeField;
    name?: string;
    placeholder?: string;
    className?: string;
    onChangeValue?: (value: string, targetName?: string) => void;
}

export const DateInputPicker: React.FC<IDatePickerProps> = ({
    theme = ThemeField.DARK,
    name,
    fieldName,
    placeholder,
    onChangeValue,
    error,
    className = '',
    ...props
}) => {
    return (
        <NamedField
            fieldName={fieldName}
            error={error}
            className={classNames(styles.dateInputPicker, { [styles.error]: error }, [className, theme])}
        >
            <div className={styles.inputWrapper}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        {...props}
                        slotProps={{ popper: { className: styles.calendar }}}
                        slots={{ openPickerIcon: CalendarIcon }}
                        format='DD/MM/YYYY'
                        onChange={value => value && onChangeValue?.(value.format(), name)}
                    />
                </LocalizationProvider>

            </div>
        </NamedField>
    );
};
