import { Box, Stack, Button } from '@mui/material';

import { TAGS } from './constants';

import useStyles from './styles';

interface IProps {
  x: number;
  y: number;
  onClick: (tag: string) => void;
}
const Suggections = ({ x, y, onClick }: IProps) => {
  const styles = useStyles();

  return (
    <Stack sx={styles.suggestion(x, y)} direction="column">
      {TAGS.map(({ label }) => (
        <Button key={label} onClick={() => onClick(label)} sx={styles.button}>
          {label}
        </Button>
      ))}
    </Stack>
  );
};

export default Suggections;
