const vatInfo = [
    { country: 'Andorra', enum: 'ad_nrt', description: 'Andorran NRT Number', example: 'A-123456-Z' },
    { country: 'Argentina', enum: 'ar_cuit', description: 'Argentinian Tax ID Number', example: '12-3456789-01' },
    { country: 'Australia - Business', enum: 'au_abn', description: 'Australian Business Number (AU ABN)', example: '12345678912' },
    { country: 'Australia - Taxation', enum: 'au_arn', description: 'Australian Taxation Office Reference Number', example: '123456789123' },
    { country: 'Austria', enum: 'eu_vat', description: 'European VAT Number', example: 'ATU12345678' },
    { country: 'Belgium', enum: 'eu_vat', description: 'European VAT Number', example: 'BE0123456789' },
    { country: 'Bolivia', enum: 'bo_tin', description: 'Bolivian Tax ID', example: '123456789' },
    { country: 'Brazil - CNPJ', enum: 'br_cnpj', description: 'Brazilian CNPJ Number', example: '01.234.456/5432-10' },
    { country: 'Brazil - CPF', enum: 'br_cpf', description: 'Brazilian CPF Number', example: '123.456.789-87' },
    { country: 'Bulgaria - UIC', enum: 'bg_uic', description: 'Bulgaria Unified Identification Code', example: '123456789' },
    { country: 'Bulgaria', enum: 'eu_vat', description: 'European VAT Number', example: 'BG0123456789' },
    { country: 'Canada - BN', enum: 'ca_bn', description: 'Canadian BN', example: '123456789' },
    { country: 'Canada - GST/HST', enum: 'ca_gst_hst', description: 'Canadian GST/HST Number', example: '123456789RT0002' },
    { country: 'Canada (British Columbia)', enum: 'ca_pst_bc', description: 'Canadian PST Number', example: 'PST-1234-5678' },
    { country: 'Canada (Manitoba)', enum: 'ca_pst_mb', description: 'Canadian PST Number', example: '123456-7' },
    { country: 'Canada (Saskatchewan)', enum: 'ca_pst_sk', description: 'Canadian PST Number', example: '1234567' },
    { country: 'Canada (Québec)', enum: 'ca_qst', description: 'Canadian QST Number', example: '1234567890TQ1234' },
    { country: 'Chile', enum: 'cl_tin', description: 'Chilean TIN', example: '12.345.678-K' },
    { country: 'China', enum: 'cn_tin', description: 'Chinese Tax ID', example: '123456789012345678' },
    { country: 'Colombia', enum: 'co_nit', description: 'Colombian NIT Number', example: '123.456.789-0' },
    { country: 'Costa Rica', enum: 'cr_tin', description: 'Costa Rican Tax ID', example: '1-234-567890' },
    { country: 'Croatia', enum: 'eu_vat', description: 'European VAT Number', example: 'HR12345678912' },
    { country: 'Cyprus', enum: 'eu_vat', description: 'European VAT Number', example: 'CY12345678Z' },
    { country: 'Czech Republic', enum: 'eu_vat', description: 'European VAT Number', example: 'CZ1234567890' },
    { country: 'Denmark', enum: 'eu_vat', description: 'European VAT Number', example: 'DK12345678' },
    { country: 'Dominican Republic', enum: 'do_rcn', description: 'Dominican RCN Number', example: '123-4567890-1' },
    { country: 'Ecuador', enum: 'ec_ruc', description: 'Ecuadorian RUC Number', example: '1234567890001' },
    { country: 'Egypt', enum: 'eg_tin', description: 'Egyptian Tax Identification Number', example: '123456789' },
    { country: 'El Salvador', enum: 'sv_nit', description: 'El Salvadorian NIT Number', example: '1234-567890-123-4' },
    { country: 'Estonia', enum: 'eu_vat', description: 'European VAT Number', example: 'EE123456789' },
    { country: 'Europe', enum: 'eu_oss_vat', description: 'European One Stop Shop VAT Number for non-Union scheme', example: 'EU123456789' },
    { country: 'Finland', enum: 'eu_vat', description: 'European VAT Number', example: 'FI12345678' },
    { country: 'France', enum: 'eu_vat', description: 'European VAT Number', example: 'FRAB123456789' },
    { country: 'Georgia', enum: 'ge_vat', description: 'Georgian VAT', example: '123456789' },
    { country: 'Germany', enum: 'eu_vat', description: 'European VAT Number', example: 'DE123456789' },
    { country: 'Greece', enum: 'eu_vat', description: 'European VAT Number', example: 'EL123456789' },
    { country: 'Hong Kong', enum: 'hk_br', description: 'Hong Kong BR Number', example: '12345678' },
    { country: 'Hungary', enum: 'eu_vat', description: 'European VAT Number', example: 'HU12345678' },
    { country: 'Hungary (adószám)', enum: 'hu_tin', description: 'Hungary Tax Number', example: '12345678-1-23' },
    { country: 'Iceland', enum: 'is_vat', description: 'Icelandic VAT', example: '123456' },
    { country: 'India', enum: 'in_gst', description: 'Indian GST Number', example: '12ABCDE3456FGZH' },
    { country: 'Indonesia', enum: 'id_npwp', description: 'Indonesian NPWP Number', example: '12.345.678.9-012.345' },
    { country: 'Ireland', enum: 'eu_vat', description: 'European VAT Number', example: 'IE1234567AB' },
    { country: 'Ireland (Northern)', enum: 'eu_vat', description: 'Northern Ireland VAT Number', example: 'XI123456789' },
    { country: 'Israel', enum: 'il_vat', description: 'Israel VAT', example: '000012345' },
    { country: 'Italy', enum: 'eu_vat', description: 'European VAT Number', example: 'IT12345678912' },
    { country: 'Japan (Hōjin Bangō)', enum: 'jp_cn', description: 'Japanese Corporate Number', example: '1234567891234' },
    { country: 'Japan (Tōroku Kokugai Jigyōsha)', enum: 'jp_rn', description: 'Japanese Registered Foreign Businesses\' Registration Number', example: '12345' },
    { country: 'Japan (Tōroku Bangō)', enum: 'jp_trn', description: 'Japanese Tax Registration Number', example: 'T1234567891234' },
    { country: 'Kenya', enum: 'ke_pin', description: 'Kenya Revenue Authority Personal Identification Number', example: 'P000111111A' },
    { country: 'Latvia', enum: 'eu_vat', description: 'European VAT Number', example: 'LV12345678912' },
    { country: 'Liechtenstein', enum: 'li_uid', description: 'Liechtensteinian UID Number', example: 'CHE123456789' },
    { country: 'Lithuania', enum: 'eu_vat', description: 'European VAT Number', example: 'LT123456789123' },
    { country: 'Luxembourg', enum: 'eu_vat', description: 'European VAT Number', example: 'LU12345678' },
    { country: 'Malaysia - FRP', enum: 'my_frp', description: 'Malaysian FRP Number', example: '12345678' },
    { country: 'Malaysia - ITN', enum: 'my_itn', description: 'Malaysian ITN', example: 'C 1234567890' },
    { country: 'Malaysia - SST', enum: 'my_sst', description: 'Malaysian SST Number', example: 'A12-3456-78912345' },
    { country: 'Malta', enum: 'eu_vat', description: 'European VAT Number', example: 'MT12345678' },
    { country: 'Mexico', enum: 'mx_rfc', description: 'Mexican RFC Number', example: 'ABC010203AB9' },
    { country: 'Netherlands', enum: 'eu_vat', description: 'European VAT Number', example: 'NL123456789B' },
    { country: 'New Zealand', enum: 'nz_gst', description: 'New Zealand GST Number', example: '123456789' },
    { country: 'Norway', enum: 'no_vat', description: 'Norwegian VAT Number', example: '123456789MVA' },
    { country: 'Peru', enum: 'pe_ruc', description: 'Peruvian RUC Number', example: '12345678901' },
    { country: 'Philippines', enum: 'ph_tin', description: 'Philippines Tax Identification Number', example: '123456789012' },
    { country: 'Poland', enum: 'eu_vat', description: 'European VAT Number', example: 'PL1234567890' },
    { country: 'Portugal', enum: 'eu_vat', description: 'European VAT Number', example: 'PT123456789' },
    { country: 'Romania', enum: 'eu_vat', description: 'European VAT Number', example: 'RO1234567891' },
    { country: 'Romania', enum: 'ro_tin', description: 'Romanian Tax ID Number', example: '1234567890123' },
    { country: 'Russia - INN', enum: 'ru_inn', description: 'Russian INN', example: '1234567891' },
    { country: 'Russia - KPP', enum: 'ru_kpp', description: 'Russian KPP', example: '123456789' },
    { country: 'Saudi Arabia', enum: 'sa_vat', description: 'Saudi Arabia VAT', example: '123456789012345' },
    { country: 'Serbia', enum: 'rs_pib', description: 'Serbian PIB Number', example: '123456789' },
    { country: 'Singapore - GST', enum: 'sg_gst', description: 'Singaporean GST', example: 'M12345678X' },
    { country: 'Singapore - UEN', enum: 'sg_uen', description: 'Singaporean UEN', example: '123456789F' },
    { country: 'Slovakia', enum: 'eu_vat', description: 'European VAT Number', example: 'SK1234567891' },
    { country: 'Slovenia', enum: 'eu_vat', description: 'European VAT Number', example: 'SI12345678' },
    { country: 'Slovenia (davčna številka)', enum: 'si_tin', description: 'Slovenia Tax Number', example: '12345678' },
    { country: 'South Africa', enum: 'za_vat', description: 'South African VAT Number', example: '4123456789' },
    { country: 'South Korea', enum: 'kr_brn', description: 'Korean BRN', example: '123-45-67890' },
    { country: 'Spain - CIF', enum: 'es_cif', description: 'Spanish NIF Number (previously Spanish CIF Number)', example: 'A12345678' },
    { country: 'Spain', enum: 'eu_vat', description: 'European VAT Number', example: 'ESA1234567Z' },
    { country: 'Sweden', enum: 'eu_vat', description: 'European VAT Number', example: 'SE123456789123' },
    { country: 'Switzerland', enum: 'ch_vat', description: 'Switzerland VAT Number', example: 'CHE-123.456.789 MWST' },
    { country: 'Taiwan', enum: 'tw_vat', description: 'Taiwanese VAT', example: '12345678' },
    { country: 'Thailand', enum: 'th_vat', description: 'Thai VAT', example: '1234567891234' },
    { country: 'Turkey', enum: 'tr_tin', description: 'Turkish Tax Identification Number', example: '0123456789' },
    { country: 'Ukraine', enum: 'ua_vat', description: 'Ukrainian VAT', example: '123456789' },
    { country: 'United Arab Emirates', enum: 'ae_trn', description: 'United Arab Emirates TRN', example: '123456789012345' },
    { country: 'United Kingdom', enum: 'gb_vat', description: 'United Kingdom VAT Number', example: 'GB123456789' },
    { country: 'United States', enum: 'us_ein', description: 'Employer Identification Number', example: '12-3456789' },
    { country: 'Uruguay', enum: 'uy_ruc', description: 'Uruguayan RUC Number', example: '123456789012' },
    { country: 'Venezuela', enum: 've_rif', description: 'Venezuelan RIF Number', example: 'A-12345678-9' },
    { country: 'Vietnam', enum: 'vn_tin', description: 'Vietnamese Tax ID Number', example: '1234567890' },
];

export default vatInfo;