import { memo, useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';

import { statisticsService } from 'services/StatisticsService';
import { StatisticFilters } from 'models/statistics';
import {
  MainStatisticsFieldNames,
  mainStatisticsList,
} from 'constants/statistics';
import styles from './MainStatistics.module.scss';

interface MainStatisticsProps {
  className?: string;
  filters: StatisticFilters;
}

const MainStatistics = ({ className, filters }: MainStatisticsProps) => {
  const [data, setData] = useState(mainStatisticsList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const updatedData = { ...data };
      const response = await statisticsService.getMainStatistics(filters);
      Object.entries(response?.data).forEach(([key, value]) => {
        const isMoney = updatedData[key as MainStatisticsFieldNames].isMoney;
        updatedData[key as MainStatisticsFieldNames].value = isMoney
          ? `$ ${value}`
          : value;
      });
      setData(updatedData);
      setLoading(false);
    })();
  }, [filters]);

  return (
    <div className={styles.container}>
      {Object.values(data).map(({ title, value, Icon }) => (
        <div className={styles.tile}>
          <Icon className={styles.icon} />

          <div className={styles.info}>
            <p>{title}</p>
            <div className={styles.data}>
              {!loading ? (
                <p className={styles.value}>{value}</p>
              ) : (
                <Skeleton variant="rectangular" sx={{ height: '90%' }} />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(MainStatistics);
