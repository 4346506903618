const useStyles = () => ({
  root: { width: '100%' },
  title: {
    color: 'var(--black-color)',
    fontFamily: 'var(--font-family-main)',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginBottom: '16px',
  },
  text: {
    fontSize: '16px',
  },
  emojis: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  button: {
    padding: '8px 8px 4px',
    borderRadius: '4px',
    border: '1px solid #E7E7E7',
    position: 'relative',
  },
  remove: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: '12px',
  },
  add: {
    marginTop: '16px',
  },
  picker: {
    position: 'absolute',
    bottom: '112px',
  },
  actions: {
    fontFamily: 'var(--font-family-main)',
    fontWeight: 500,
    fontSize: '16px',
    display: 'flex',
    gap: '16px',
    marginTop: '22px',
  },
  cancel: {
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: 'rgba(138, 150, 163, 0.12)',
    color: 'var(--black-color)',
  },
  save: {
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: 'var(--accent-color)',
    color: 'var(--white-color)',
  },
});

export default useStyles;
