import React from 'react';
import { Button } from '@mui/material';

import { ITab } from './interfaces';

import useStyles from './useStyles';

const Tab = ({ label, onClick, active }: ITab) => {
  const styles = useStyles();

  return (
    <Button onClick={onClick} sx={styles.root(active)}>
      {label}
    </Button>
  );
};

export default Tab;
