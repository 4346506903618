import { BaseService } from './BaseService';

import { ISubscriber, ISubscriberInfo } from '../models/subscribers';
import { ISubscriberMedia } from '../models/media';
import { IHelper } from '../models/helpers';

import {
  getStatisticsCreatorOnlineApi,
  getStatisticsCreatorChatterInfoApi,
  getStatisticsCreatorChatterMediaApi,
  getStatisticsCreatorChatterHelperApi,
} from '../config/api';

class ScrapingService extends BaseService {
  public async getOnlineSubscribers(
    creatorId: string,
  ): Promise<{ subscribers: ISubscriber[] }> {
    return this.get(getStatisticsCreatorOnlineApi(creatorId));
  }

  public async getChatterInfo({
    creatorId,
    chatterId,
  }: {
    creatorId: string;
    chatterId: string;
  }): Promise<{ info: ISubscriberInfo }> {
    return this.get(getStatisticsCreatorChatterInfoApi(creatorId, chatterId));
  }

  public async getMedia({
    creatorId,
    chatterId,
  }: {
    creatorId: string;
    chatterId: string;
  }): Promise<ISubscriberMedia> {
    return this.get(getStatisticsCreatorChatterMediaApi(creatorId, chatterId));
  }

  public async getHelpers({
    creatorId,
    chatterIds,
  }: {
    creatorId: string;
    chatterIds: string[];
  }): Promise<{
    items: IHelper[];
  }> {
    return this.get(getStatisticsCreatorChatterHelperApi(creatorId), {
      chatterIds,
    });
  }
}

export const scrappingService = new ScrapingService();
