import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ProxyStep.module.scss';
import { Button, ThemeButton } from 'components/Button/Button';
import Select from 'components/fields/Select/Select';
import { ICreators, ICreatorsDictionary } from 'models/creators';
import { convertToDropdownArray, convertToDropdownItem } from 'utils/convert';
import { IValidateFlags } from 'models/validation';
import { yupToFormErrors } from 'formik';
import { connectCreatorSchema } from 'utils/validators';
import { IDropdownOption } from 'models/fields';


type ProxyDataType = Pick<ICreators, "proxyCountry">;

interface ProxyStepProps {
    data: ProxyDataType;
    dictionary: ICreatorsDictionary;
    isEdit?: boolean;
    disabled?: boolean;
    loading?: boolean;
    onNextStep: () => void;
    onChangeData: (value: string, targetName?: string) => void;
}

export const ProxyStep = memo(({ data, dictionary, isEdit, disabled, loading, onNextStep, onChangeData }: ProxyStepProps) => {
    const [validateErrors, setValidateErrors] = useState<IValidateFlags<ProxyDataType>>({});

    const proxyOptions = useMemo(() => convertToDropdownArray(dictionary.proxyCountry), [dictionary]);

    useEffect(() => {
        setValidateErrors({});
    }, [data]);

    const onClickConnectCreator = useCallback(async () => {
        try {
            await connectCreatorSchema.proxyStep.validate({ ...data }, { abortEarly: false });
            onNextStep();
        } catch (errors) {
            setValidateErrors(yupToFormErrors(errors));
        }

    }, [data, onNextStep]);

    const list = useMemo(() => {
        if (isEdit) {
            return [
                "You can change the proxy once a month. If you have any problems, please contact support.",
                "You won't face any issues with OnlyFans based on your proxy location.",
                "It's all about having fast, secure, and reliable connections.",
                "Our proxies come from a trusted provider, ensuring fast and secure connections."
            ]
        }

        return [
            "We've got the most popular countries covered, not every single one.",
            "You won't face any issues with OnlyFans based on your proxy location.",
            "It's all about having fast, secure, and reliable connections.",
            "Our proxies come from a trusted provider, ensuring fast and secure connections."
        ]
    }, [isEdit])

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                {isEdit ? 'Edit Proxy' :`Let's set it up`}
            </h2>
            <p className={styles.subtitle}>Just a heads-up:</p>
            <ul className={styles.list}>
                {list.map((value, index) => (<li key={index}>{value}</li>))}
            </ul>
            <div className={styles.actions}>
                <Select
                    name="proxyCountry"
                    options={proxyOptions}
                    className={styles.select}
                    placeholder="Select a Proxy Country"
                    value={convertToDropdownItem(data.proxyCountry)}
                    onChange={(value, actionMeta) => {
                        const targetName = actionMeta.name;
                        const updatedValue = value as IDropdownOption;
                        onChangeData(updatedValue.value, targetName!);
                    }}
                    error={validateErrors.proxyCountry}
                />
                <Button theme={ThemeButton.PRIMARY} onClick={onClickConnectCreator} disabled={disabled} loading={loading}>
                    {isEdit ? 'Edit' : 'Continue'}
                </Button>
            </div>
        </div>
    );
});
