import { LegacyRef, useEffect, useRef, useState } from "react"

const useImageLoaded = () => {
    const [loaded, setLoaded] = useState(false)
    const ref = useRef<LegacyRef<HTMLImageElement> | undefined>()

    const onLoad = () => {
        setLoaded(true)
    }

    useEffect(() => {
        // @ts-ignore
        if (ref.current && ref.current.complete) {
            onLoad();
        }
    })

    return [ref, loaded, onLoad]
}

export default useImageLoaded;