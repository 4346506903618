import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import ElectronSidebar from '../ElectronSidebar';

import OFSIdebar from '../OFSidebar/OFSIdebar';

import { useElectronContext } from '../../providers/ElectronProvider';
import useMainListener from '../../hooks/useMainListener';

import useStyles from './useStyles';

import WebviewDashboard from '../WebviewDashboard';
import { CreatorsSessionStatus } from '../../../../constants/creators';
import { getCreatorsList } from '../../../../store/reducers/creators';
import useElectronApplicationUpdate from 'modules/electron/hooks/useElectronApplicationUpdate';
import { useChatterCharms } from '../../../../providers/ChatterCharmsProvider';

interface IElectronContainer {
  children: React.ReactNode;
}

const ElectronContainerView = ({ children }: IElectronContainer) => {
  const styles = useStyles();
  const { ofUrl, activeCreator } = useElectronContext();
  const { creators } = useChatterCharms();

  const [activeCreatorStatus, setActiveCreatorStatus] = useState('');

  useMainListener();
  useElectronApplicationUpdate();

  useEffect(() => {
    if (!activeCreator?.id) {
      return;
    }

    //Update activeCreator data if creators is updated
    const newCreatorData = creators.find(
      (item) => item.id === activeCreator?.id,
    );

    setActiveCreatorStatus(newCreatorData?.sessionStatus ?? '');
  }, [creators, activeCreator?.id]);

  const isSidebar =
    !!activeCreator &&
    !!ofUrl &&
    activeCreatorStatus === CreatorsSessionStatus.ACTIVE;

  return (
    <Stack display="flex" flexDirection="row" sx={styles.root}>
      <ElectronSidebar />
      <Box sx={styles.webview(isSidebar)}>
        {ofUrl ? <WebviewDashboard /> : children}
      </Box>
      {isSidebar ? <OFSIdebar /> : null}
    </Stack>
  );
};

export default ElectronContainerView;
