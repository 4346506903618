import React, { useCallback, useEffect, useState } from 'react';

import {
  IContentContext,
  IContentProviderProps,
  ISubscriber,
} from './interfaces';

import { scrappingService } from '../../../../services/ScrapingService';
import { useAppSelector } from '../../../../store';
import { getUserAuthData } from '../../../../store/reducers/auth';
import { ICreators } from '../../../../models/creators';
import { ISubscriberInfo } from '../../../../models/subscribers';

const DefaultContext = {
  isAuth: false,
  isLoading: false,
  activeCreator: null, //creatorId
  ofUrl: '',
  activateLoginCreatorId: null,
  activateLoginSessionId: null,
  subscribers: [],
  activeChatterId: null,
  setOfUrl: () => {},
  setActivateLoginCreatorId: () => {},
  setActivateLoginSessionId: () => {},
  setActiveCreator: () => {},
  setActiveChatterId: () => {},
  setIsLoading: () => {},
  loadChatterInfo: async () => {},
  loadOnlineChatters: async () => {},
  isActivatingModel: false,
  setIsActivatingModel: () => {},
};

export const ContentContext =
  // @ts-ignore
  React.createContext<IContentContext>(DefaultContext);

export const useElectronContext = () => React.useContext(ContentContext);

export const ElectronProvider = ({ children }: IContentProviderProps) => {
  const isAuth = useAppSelector(getUserAuthData);

  const [activeCreator, setActiveCreator] = useState<ICreators | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [subscribers, setSubscribers] = useState<ISubscriber[]>([]);
  const [activeChatterId, setActiveChatterId] = useState<string | null>(null);

  const [ofUrl, setOfUrl] = useState<string>('');

  const [isActivatingModel, setIsActivatingModel] = useState(false);

  //It's used for activation profile on first login
  const [activateLoginCreatorId, setActivateLoginCreatorId] = useState<
    null | string
  >(null);
  const [activateLoginSessionId, setActivateLoginSessionId] = useState<
    null | string
  >(null);

  const loadOnlineChatters = useCallback(async (creatorId: string) => {
    setIsLoading(true);
    try {
      const { subscribers } =
        await scrappingService.getOnlineSubscribers(creatorId);

      setSubscribers(subscribers);
    } catch (e) {
      setSubscribers([]);
      console.log('e', e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!isAuth || !activeCreator || !activeCreator.id) {
      return;
    }

    loadOnlineChatters(activeCreator.id);
    // (async (creatorId) => {})(activeCreator.id);

    return () => {
      unmounted = true;
    };
  }, [isAuth, activeCreator, loadOnlineChatters]);

  useEffect(() => {
    //Update Chatter
    setActiveChatterId(null);
  }, [activeCreator]);

  const loadChatterInfo = useCallback(
    async (creatorId: string, chatterId: string) => {
      try {
        const data = await scrappingService.getChatterInfo({
          creatorId,
          chatterId,
        });

        return data?.info ?? null;
      } catch (e) {
        return null;
      }
    },
    [],
  );

  return (
    <ContentContext.Provider
      value={{
        isLoading,
        activeCreator,
        subscribers,
        activeChatterId,
        ofUrl,
        activateLoginCreatorId,
        activateLoginSessionId,
        setActiveChatterId,
        setActiveCreator,
        setOfUrl,
        setActivateLoginSessionId,
        setActivateLoginCreatorId,
        setIsLoading,
        loadChatterInfo,
        loadOnlineChatters,
        isActivatingModel,
        setIsActivatingModel,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
