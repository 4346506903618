import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import { useOfSidebarContext } from './providers';
import { HomePage, AccountPage } from './pages';

import { OF_SIDEBAR_ROUTE } from './constants/enums';
import { useElectronContext } from '../../providers/ElectronProvider';

import useStyles from './useStyles';

const OFSidebarView = () => {
  const styles = useStyles();

  const { sidebarRoute, setSidebarRoute, isActiveOFOpen } =
    useOfSidebarContext();

  const { activeCreator, activeChatterId } = useElectronContext();

  useEffect(() => {
    setSidebarRoute(OF_SIDEBAR_ROUTE.HOME);
  }, [activeCreator?.id]);

  useEffect(() => {
    if (!activeChatterId) {
      setSidebarRoute(OF_SIDEBAR_ROUTE.HOME);
    }
  }, [activeChatterId]);

  const renderPage = () => {
    switch (sidebarRoute) {
      case OF_SIDEBAR_ROUTE.HOME: {
        return <HomePage />;
      }

      case OF_SIDEBAR_ROUTE.ACCOUNT: {
        return <AccountPage />;
      }
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.overlay(isActiveOFOpen)}>{renderPage()}</Box>
    </Box>
  );
};

export default OFSidebarView;
