import React from 'react';

import { TooltipProps } from '@mui/material';
import DefaultTooltip from '@mui/material/Tooltip';
import { classNames } from 'utils/classNames';
import styles from "./Tooltip.module.scss";

interface BaseTooltipProps extends TooltipProps {
    children: JSX.Element;
    className?: string;
}

export const Tooltip = ({ className, children, ...props }: BaseTooltipProps) => {

    return (
        <DefaultTooltip
            {...props}
            slotProps={{ popper: { className: classNames(styles.tooltip, {}, [className]) } }}
            arrow
        >
            {children}
        </DefaultTooltip>
    );
};
