import React, { useEffect, useMemo, useState } from 'react';

import styles from './EarningsStats.module.scss';
import { classNames } from 'utils/classNames';
import { IEarningsStats, IEarningsStatsItem, StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';
import { Skeleton } from '@mui/material';
import Chart, { Props } from "react-apexcharts";

interface EarningsStatsProps {
    filters: StatisticFilters;
    className?: string;
}

const initialOptions: Props = {
    options: {
        dataLabels: {
            enabled: false
        },
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        xaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false,
        },
        grid: {
            show: false
        },
        tooltip: {
            enabled: false
        },
        stroke: {
            width: 2,
            colors: ["#47AF2D", "#F2383A", "#FFFFFF"]
        }
    },
    series: [{
        data: [] 
    }],
}

export const EarningsStats = ({ filters, className }: EarningsStatsProps) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IEarningsStats>();

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const response = await statisticsService.getCreatorEarningStats(filters);
                setData(response);
            } catch {}
            setLoading(false);
        })();

    }, [filters]);


    const renderItem = (title: string, info: IEarningsStatsItem | undefined) => {
        const { total, delta, chart } = info || {};
        const roundedDelta =  (delta && parseInt(delta)) || 0;
        const isLossStatus =  roundedDelta < 0;

        const settings = JSON.parse(JSON.stringify(initialOptions));
        const series = chart?.map(item => Number(item.sum));
        const isSingeValue = series?.length === 1;
        const commonSum = series?.reduce((sum, value) => sum + (value || 0), 0);

        if (isSingeValue) {
            series.push(series[0]);
        }

        settings.series[0].data = series;
        
        const colors = settings.options.stroke.colors;
        isLossStatus && (settings.options.stroke.colors = [colors[1]]);
        !commonSum && (settings.options.stroke.colors = [colors[2]]);

        const prefixSymbol = roundedDelta > 0 ? "+" : "";  

        const deltaValue = !commonSum ? "0" : `${prefixSymbol}${roundedDelta}%`

        return (
            <div className={styles.item}>
                <div className={styles.info}>
                    {loading ? <Skeleton /> : (
                        <div className={styles.earningValues} style={{ color: settings.options.stroke.colors[0]}}>
                            {deltaValue}
                        </div>
                    )}
                    <div className={styles.typeTitle}>{title}</div>
                </div>
                <div className={styles.chart}>
                    <Chart width={120} height={60} options={settings.options} series={settings.series} type="line" />
                </div>
            </div>
        )
    }

    return (
        <div className={classNames(styles.container, {}, [className])}>
            {renderItem("Subscriptions", data?.subscription)}
            {renderItem("Messages", data?.message)}
            {renderItem("Recurring Subscription", data?.rSubscription)}
            {renderItem("Tips", data?.tip)}
            {renderItem("Stream", data?.stream)}
            {renderItem("Posts", data?.post)}
        </div>
    );
};
