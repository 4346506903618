import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Button, Box, Typography } from '@mui/material';
import { ArrowUp } from '../../../../../icons';

import { IProps } from './interfaces';

import useStyles from './useStyles';

const Collapsible = ({ children, title, icon }: IProps) => {
  const styles = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState<string | number>('unset');

  useEffect(() => {
    const newHeight = isOpen ? ref.current?.getBoundingClientRect().height : 0;
    setHeight(newHeight ?? 0);
  }, [isOpen]);

  const handleClick = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  const keyframe = useMemo(() => {
    return {
      '@keyframes open-collapse': {
        '0%': {
          overflow: 'hidden',
        },
        '100%': {
          overflow: 'unset',
        },
      },
      '@keyframes close-collapse': {
        '0%': {
          overflow: 'unset',
        },
        '100%': {
          overflow: 'hidden',
        },
      },

      animation: `${height ? 'open-collapse' : 'close-collapse'} 0.2s ease`,
      animationFillMode: 'forwards',
    };
  }, [height]);

  return (
    <div>
      <Button onClick={handleClick} sx={styles.button(isOpen)}>
        <Box sx={styles.iconTitle}>
          {icon ?? null}
          <Typography sx={styles.title} component="h2">
            {title}
          </Typography>
        </Box>
        <Typography component="span" sx={styles.arrow(isOpen)}>
          <ArrowUp />
        </Typography>
      </Button>

      <Box sx={{ ...styles.collapse, ...keyframe }} style={{ height }}>
        <Box ref={ref} sx={styles.content}>
          {children}
        </Box>
      </Box>
    </div>
  );
};

export default Collapsible;
