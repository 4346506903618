import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as BrandLogo } from 'assets/images/temp/blue-logo.svg';

import useStyles from './useStyles';

interface IProps {
  children?: ReactElement;
}

const Header = ({ children }: IProps) => {
  const styles = useStyles();

  return (
    <Box sx={styles.header}>
      <Box sx={styles.main}>
        <Box sx={styles.logo}>
          <BrandLogo />
        </Box>
        <Typography component={'h1'} sx={styles.title}>
          ChatterCharms
        </Typography>
      </Box>

      {children ?? null}
    </Box>
  );
};

export default Header;
