import React from 'react';
import { Box } from '@mui/material';
import { format } from 'date-fns';

import InfoBox from '../InfoBox/InfoBox';

import { IPurchasingBehaviorProps } from './interfaces';
import useStyles from './useStyles';

const PurchasingBehavior = ({ details }: IPurchasingBehaviorProps) => {
  const styles = useStyles();

  const { bought = 0, total = 0 } = details.buyRate;
  const buyRate = (bought / total) * 100;

  const behaviorData = [
    { title: 'Total Amount Spent', value: `$${details.total}` },
    { title: 'Last purchase', value: `$${details.lastPurchasePrice}` },
    {
      title: 'Buy Rate',
      value:
        +total === 0 ? '0%' : `${Math.ceil(buyRate)}% (${bought}/${total})`,
    },
    { title: 'Highest Price', value: `$${details.highestPrice}` },
    { title: 'Average Price', value: `$${details.averagePrice}` },
    {
      title: 'Last Purchase Date',
      value: details.lastPurchaseDate
        ? format(new Date(details.lastPurchaseDate), 'MMM dd,yyyy')
        : '-',
    },
  ];
  return (
    <Box sx={styles.root}>
      {behaviorData.map(({ title, value }, key) => (
        <InfoBox title={title} value={value} key={key} />
      ))}
    </Box>
  );
};

export default PurchasingBehavior;
