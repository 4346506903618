import React, { useCallback, memo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
    assignMemberCreator,
    removeMemberCreator,
    getMembersState,
    membersActions,
} from "../../../store/reducers/members";
import { getModalLoading } from "../../../store/reducers/modal";

import { ReactComponent as CloseIcon } from "assets/images/cross.svg";

import { Button, ThemeButton } from "components/Button/Button";
import { ReactComponent as AssignedMembersIcon } from "assets/images/editAccount/assignedMembers.svg";
import Select from "components/fields/Select/Select";


import styles from "./AssignCreator.module.scss";

import { useChatterCharms } from "../../../providers/ChatterCharmsProvider";
import { IDropdownOption } from "models/fields";

interface AssignCreatorProps {
    onClose?: () => void;
}

export const AssignCreator = memo(({ onClose }: AssignCreatorProps) => {
    const dispatch = useAppDispatch();

    const { creatorMap } = useChatterCharms();

    const { memberActiveModal, membersCreators } = useAppSelector(getMembersState);

    const loading = useAppSelector(getModalLoading);

    const [selectedValue, setSelectedValue] = useState<IDropdownOption | null>(null);

    const handleClose = useCallback(() => {
        dispatch(membersActions.setMemberActiveModal(null));
        onClose?.();
    }, []);

    const handleAssignCreator = useCallback(async () => {
        if (!selectedValue?.value || !memberActiveModal?.id) {
            return;
        }

        dispatch(assignMemberCreator({
            memberId: memberActiveModal?.id,
            creatorId: selectedValue?.value,
        }));

        setSelectedValue(null);
    }, [selectedValue?.value, memberActiveModal?.id]);

    const handleRemoveCreator = useCallback(
        async (creatorId: string) => {
            if (!memberActiveModal?.id) {
                return;
            }

            dispatch(
                removeMemberCreator({
                    memberId: memberActiveModal?.id,
                    creatorId: creatorId,
                })
            );
        },
        [memberActiveModal?.id]
    );

    const activeMemberCreators = React.useMemo(() =>
        memberActiveModal?.id
            ? membersCreators
                .filter((item) => item.memberId === memberActiveModal?.id)
                .map((item) => item.creatorId)
            : [],
        [memberActiveModal?.id, membersCreators]);

    const nonAssignMemberCreators = React.useMemo(
        () =>
            memberActiveModal?.id && creatorMap
                ? [...creatorMap].filter(
                    ([creatorId]) =>
                        !activeMemberCreators.includes(creatorId as "string")
                )
                : [],
        [memberActiveModal?.id, activeMemberCreators, creatorMap]
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <CloseIcon className={styles.close} onClick={handleClose} />
            </div>

            <div className={styles.body}>
                <div className={styles.icon}>
                    <AssignedMembersIcon />
                </div>
                <h2 className={styles.title}>Assign Account</h2>
                <p className={styles.description}>
                    Make sure you link to a Creator's account. Once done, we'll establish
                    a new, distinct session for it.
                </p>
                <div className={styles.actions}>
                    <div className={styles.assignCreator}>
                        <Select
                            className={styles.input}
                            options={nonAssignMemberCreators.map(([_, creator]) => ({
                                value: creator?.id,
                                label: creator?.name,
                            }))}
                            value={selectedValue}
                            onChange={(value) => {
                                setSelectedValue(value as IDropdownOption);
                            }}
                        />
                        <Button
                            theme={ThemeButton.PRIMARY}
                            onClick={handleAssignCreator}
                            disabled={!selectedValue || !memberActiveModal || loading}
                        >
                            Assign Account
                        </Button>
                    </div>
                    <div className={styles.creatorsList}>
                        {activeMemberCreators?.map((creatorId) => {
                            const creatorData = creatorMap?.get(creatorId);

                            if (!creatorData) {
                                return null;
                            }

                            return (
                                <div className={styles.creator} key={creatorData.id}>
                                    <div className={styles.info}>
                                        <div className={styles.avatar}>
                                            {creatorData.imageLink && <img src={creatorData.imageLink} alt="Avatar creator"/>}
                                        </div>
                                        <div className={styles.username}>
                                            <p className={styles.name}>{creatorData.name}</p>
                                            <p className={styles.ofNick}>@{creatorData.ofNick}</p>
                                        </div>
                                    </div>
                                    <Button
                                        theme={ThemeButton.DARK_OUTLINE}
                                        className={styles.removeBtn}
                                        onClick={() => handleRemoveCreator(creatorData.id!)}
                                        disabled={loading}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
});
