import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { useAppSelector, useAppDispatch } from 'store';

import { ModalType } from 'constants/modal';
import styles from './Modal.module.scss';

import { useDisableBodyScroll } from 'hooks/useDisableBodyScroll';
import { modalActions } from 'store/reducers/modal';
import withModalParam from 'hoc/withModalParam';
import { ConnectCreator } from './ConnectCreator/ConnectCreator';
import { AddMembers } from './AddMembers/AddMembers';
import { AssignCreator } from './AssignCreator/AssignCreator';
import { MembersRole } from './MembersRole/MembersRole';
import { AddOrUpdateRole } from './AddOrUpdateRole/AddOrUpdateRole';
import { BillingHistory } from './BillingHistory/BillingHistory';
import { BillingPayment } from './BillingPayment/BillingPayment';
import { PaymentSuccess } from './PaymentSuccess/PaymentSuccess';
import { classNames } from 'utils/classNames';
import { IPaymentInvoice } from 'models/billing';
import { SuccessfullyUpdated } from './SuccessfullyUpdated/SuccessfullyUpdated';
import { AiCopilot } from './AiCopilot/AiCopilot';
import { SuccessfulPayment } from './SuccessfulPayment/SuccessfulPayment';
import { SuccessfulInfo } from './SuccessfulInfo/SuccessfulInfo';
import { EditProxy } from './EditProxy/EditProxy';
import { AvailableUpdate } from './AvailableUpdate/AvailableUpdate';

const ModalWindow: React.FC = () => {
    const { modalType, config } = useAppSelector(state => state.modal);
    const dispatch = useAppDispatch();

    const isModalOpened = useMemo(() => modalType !== ModalType.NONE, [modalType]);

    const onRequestClose = useCallback(() => {
        dispatch(modalActions.setModalType({ type: ModalType.NONE, config: undefined }));
    }, [dispatch]);

    useDisableBodyScroll(isModalOpened);

    const renderBody = () => {
        switch (modalType) {
            case ModalType.СONNECT_CREATOR: {
                return <ConnectCreator onClose={onRequestClose} />
            }
            case ModalType.ADD_MEMBERS: {
                return <AddMembers onClose={onRequestClose} />
            }
            case ModalType.ASSIGN_CREATOR: {
                return <AssignCreator onClose={onRequestClose} />
            }
            case ModalType.MEMBERS_ROLE: {
                return <MembersRole onClose={onRequestClose} />
            }
            case ModalType.ADD_ROLE:
            case ModalType.UPDATE_ROLE: {
                return <AddOrUpdateRole isEdit={modalType === ModalType.UPDATE_ROLE} onClose={onRequestClose} />
            }
            case ModalType.BILLING_HISTORY: {
                return <BillingHistory onClose={onRequestClose} />
            }
            case ModalType.BILLING_PAYMENT: {
                return <BillingPayment onClose={onRequestClose} />
            }
            case ModalType.PAYMENT_SUCCESSFUL: {
                return <PaymentSuccess data={config?.data as IPaymentInvoice} onClose={onRequestClose} />
            }
            case ModalType.SUCCESSFULLY_UPDATED: {
                return <SuccessfullyUpdated data={config?.data as { text: string }} onClose={onRequestClose} />
            }
            case ModalType.AI_COPILOT: {
                return <AiCopilot onClose={onRequestClose} />
            }
            case ModalType.BILLING_PAYMENT_SUCCESSFUL: {
                return <SuccessfulPayment data={config?.data as { title: string, text: string }} onClose={onRequestClose}  />
            }
            case ModalType.SUCCESSFUL_INFO: {
                return <SuccessfulInfo onClose={onRequestClose}  />
            }
            case ModalType.EDIT_PROXY: {
                return <EditProxy onClose={onRequestClose}  />
            }
            case ModalType.AVAILABLE_UPDATE: {
                return <AvailableUpdate onCancel={onRequestClose} />
            }
            default: {
                return null;
            }
        }
    };

    return (
        <Modal
            isOpen={isModalOpened}
            ariaHideApp={false}
            //shouldCloseOnOverlayClick={!loading || config?.shouldCloseOnOverlayClick}
            shouldCloseOnOverlayClick={false}
            className={classNames(styles.modal, {}, [])}
            overlayClassName={styles.overlay}
            onRequestClose={onRequestClose}
        >
            <div className={styles.body}>{renderBody()}</div>
        </Modal>
    );
};

export default withModalParam(ModalWindow);

