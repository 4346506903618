import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTHORIZATION_TOKEN, ID_TOKEN, REFRESH_TOKEN } from "config/api";
import { ErrorData, IAgencyFormData, ISignInRequest, ISignUpRequest } from "models/auth";
import { authService } from "services/AuthService";
import { StateSchema, ThunkConfig } from "store";
import { IMember } from '../../models/members';
import axios from "axios";
import { loadRoles } from "./roles";
import { loadCreatorsList } from "./creators";


export const signIn = createAsyncThunk<
    void,
    ISignInRequest,
    ThunkConfig<string | ErrorData[]>
>("auth/signIn", async (authData, { rejectWithValue, dispatch }) => {
    try {
        const { email, password, isStepForSignUp } = authData;
        const { accessToken, refreshToken, idToken } = await authService.signIn({ email, password });

        if (accessToken && refreshToken && idToken) {
            localStorage.setItem(AUTHORIZATION_TOKEN, accessToken);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);
            localStorage.setItem(ID_TOKEN, idToken);
            !isStepForSignUp && await dispatch(initAuthData());
        }
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const { data } = err.response ?? {};
            if (data?.errors) {
                return rejectWithValue(data?.errors);
            }
        }

        return rejectWithValue("Cannot sign in");
    }
});

export const signUp = createAsyncThunk<
    void,
    ISignUpRequest,
    ThunkConfig<string>
>("auth/signUp", async (authData, { rejectWithValue, dispatch }) => {
    try {
        const { email, password, name, agencyName, zone, memberToken, type } = authData;
        let isCreate;

        if (memberToken) {
            isCreate = await authService.signUpLink({
                email,
                password,
                name,
                memberToken,
            });
        } else {
            isCreate = await authService.signUp({ email, password, name });
        }

        if (isCreate) {
            // TODO: fix -  memberToken indicates whether the user is invited via the generated link. 
            // isStepForSign - this flag determines whether it is necessary to initialize data (roles, permissions necessary for routing, etc.).
            // Important: with a regular signup, we cannot receive data (permissions) BEFORE the Agency is created. 4
            // However, when registering using a link, you do not need to create an agency, so we initialize the data right away
            // For clarity, implemented through the conditions of the ternary operator
            const signInResult = await dispatch(signIn({ email, password, isStepForSignUp: memberToken ? false : true  }));

            if (signInResult.meta.requestStatus === "fulfilled" && !memberToken) {
                await authService.createAgency({ agencyName, zone, type }); // TODO: fix
                await dispatch(initAuthData());
            }
        }
    } catch (err) {
        if (axios.isAxiosError(err)) {
            const { data } = err.response ?? {};
            if (data?.errors) {
                return rejectWithValue(data?.errors);
            }
        }

        return rejectWithValue("Failed to load data");
    }
});

export const initAuthData = createAsyncThunk<
    IMember,
    void,
    ThunkConfig<string>
>("auth/initAuthData", async (_, { dispatch, rejectWithValue }) => {
    try {
        const authToken = localStorage.getItem(AUTHORIZATION_TOKEN);
        if (!authToken) {
            throw new Error();
        }

        const { profile } = await authService.getProfile();

        if (profile?.id) {
            const preloadPromises = [
                dispatch(loadRoles()), 
                dispatch(loadAgency()), 
                dispatch(loadCreatorsList())
            ] // TODO: draft solution

            await Promise.all([...preloadPromises]);

            return profile;
        }

        return rejectWithValue("Cannot load auth data");
    } catch (e) {
        return rejectWithValue("Cannot load auth data");
    }
});

export const loadAgency = createAsyncThunk<
    IAgencyFormData,
    void,
    ThunkConfig<string>
>("auth/loadAgency", async (_, { rejectWithValue }) => {
    try {
        const { agency } = await authService.getAgency();

        return agency ?? [];
    } catch {
        return rejectWithValue("Error");
    }
});

export const updateDataAgency = createAsyncThunk<
  void,
  { name: string, zone: string },
  ThunkConfig<string>
>("auth/updateAgency", async ({ name, zone}, { rejectWithValue, dispatch }) => {
    try {
        await authService.updateAgency({ agencyName: name, zone });
        await dispatch(loadAgency());
    } catch {
        return rejectWithValue("Error");
    }
});

export interface AuthSchema {
    authData: IMember | undefined;
    agency?: IAgencyFormData;
    _inited: boolean;
    loading: boolean;
    error?: string | ErrorData[];
}

const initialState: AuthSchema = {
    authData: undefined,
    _inited: false,
    loading: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAuthData: (state: AuthSchema, action: PayloadAction<IMember | undefined>) => {
            state.authData = action.payload;
        },
        updateAuthErrors: (state: AuthSchema, action: PayloadAction<string | ErrorData[] | undefined>) => {
            state.error = action.payload;
        },
        logout: (state: AuthSchema) => {
            localStorage.removeItem(AUTHORIZATION_TOKEN);
            state.authData = undefined;
        },
    },
    extraReducers: builder => {
        builder.addCase(initAuthData.fulfilled, (state, action) => {
            state.loading = false;
            state._inited = true;
            state.authData = action.payload;
            state.error = undefined;
        });
        builder.addCase(initAuthData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(initAuthData.rejected, (state) => {
            state.loading = false;
            state._inited = true;
            state.authData = undefined;
        });
        builder.addCase(loadAgency.fulfilled, (state, action) => {
            state.agency = action.payload;
        });
        builder.addCase(signIn.rejected, (state, action) => {
            state.error = action.payload as string | ErrorData[];
        });
        builder.addCase(signUp.rejected, (state, action) => {
            state.error = action.payload as string | ErrorData[];
        });
    },
});

export const getUserAuthErrors = (state: StateSchema) => state.auth.error;
export const getUserAuthData = (state: StateSchema) => state.auth.authData;
export const getInitAppLoading = (state: StateSchema) => state.auth.loading;
export const getUserAgency = (state: StateSchema) => state.auth.agency;
export const getAuthInited = (state: StateSchema) => state.auth._inited;

export const { actions: authActions } = authSlice;
export const { reducer: authReducer } = authSlice;
