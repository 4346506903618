import React, { useEffect, useState } from 'react';

import styles from './MonthlyEstimate.module.scss';
import { classNames } from 'utils/classNames';
import { IRunRate, StatisticFilters } from 'models/statistics';
import { Skeleton } from '@mui/material';
import { statisticsService } from 'services/StatisticsService';
import moment from 'moment';


interface MonthlyEstimateProps {
    filters: StatisticFilters;
    className?: string;
}

export const MonthlyEstimate = ({ filters, className }: MonthlyEstimateProps) => {
    const [data, setData] = useState<IRunRate>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { to } = filters;
                const startMonthlyDate = moment(to).subtract(30, "days").utc().format();
                const response = await statisticsService.getCreatorRunRate({...filters, from: startMonthlyDate});
                setData(response?.data);
            } catch {}
            setLoading(false);
        })();

    }, [filters]);

    const renderTile = (title: string, value?: string | number) => {
        return (
            <div className={styles.tile}>
                <p className={styles.title}>{title}</p>
                {!loading ? <p className={styles.value}>{value}</p> : <Skeleton variant="rectangular" />}
            </div>
        )
    }

    return (
        <div className={classNames(styles.container, {}, [className])}>
            {renderTile("Accounts", data?.accountNumber)}
            {renderTile("Monthly Estimate based on 7-Day Run Rate", `$ ${data?.runRate7}`)}
            {renderTile("Monthly Estimate based on 30-Day Run Rate", `$ ${data?.runRate30}`)}
        </div>
    );
};
