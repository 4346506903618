import React, { useCallback, useEffect, useState } from 'react';

import styles from './ForgotPassword.module.scss';
import Input from 'components/fields/Input/Input';
import { Button, ThemeButton } from 'components/Button/Button';
import { ThemeField } from 'models/fields';
import { forgotPasswordSchema } from 'utils/validators';
import { yupToFormErrors } from 'formik';
import { authService } from 'services/AuthService';
import axios from 'axios';
import { ApiErrorCode, getErrorMessage } from 'constants/messages';

interface ForgotPasswordProps {
  onNext: () => void;
  onCancel: () => void;
  className?: string;
}

export const ForgotPassword = ({ onNext, onCancel }: ForgotPasswordProps) => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
  }, [email]);

  const onClickRequestBtn = useCallback(async () => {
    setLoading(true);
    try {
      await forgotPasswordSchema.validate({ email }, { abortEarly: false });
      const { status } = await authService.resetPassword(email);

      if (status === 'OK') {
        onNext();
      }
    } catch (errors) {
      if (axios.isAxiosError(errors)) {
        const { data } = errors.response ?? {};

        const errorCode: ApiErrorCode = data?.errors[0]?.message;
        const errorMessage = getErrorMessage(errorCode);
        setError(errorMessage || '');
      } else {
        const { email } = yupToFormErrors<{ email: string }>(errors);
        setError(email || '');
      }
    }
    setLoading(false);
  }, [email]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Forgot Password</h1>
      <p className={styles.description}>
        Provide your account's email for which you want to reset your password
      </p>
      <Input
        theme={ThemeField.LIGHT}
        placeholder="Ex: abc@example.com"
        readonly={loading}
        value={email}
        onChange={(value) => setEmail(value.trim())}
        fieldName="E-mail"
        className={styles.input}
        error={error}
        disabled={loading}
        mandatory
      />
      <Button
        disabled={loading}
        theme={ThemeButton.PRIMARY}
        className={styles.button}
        onClick={onClickRequestBtn}
      >
        Request
      </Button>
      <Button
        disabled={loading}
        theme={ThemeButton.OUTLINE}
        className={styles.button}
        onClick={onCancel}
        loading={loading}
      >
        Cancel
      </Button>
    </div>
  );
};
