import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';

import { useElectronContext } from '../../../../providers/ElectronProvider';
import Account from './Account';
import OnlineFans from '../OnlineFans';
import Filter from './Filter';
import Loader from '../Loader';

import { IProps } from './interfaces';

import classes from './Dashboard.module.scss';

import useStyles from './useStyles';

const Dashboard = ({ disableOnlyFansIndicator }: IProps) => {
  const styles = useStyles();
  const { isLoading, subscribers } = useElectronContext();

  const [filterTotal, setFilterTotal] = useState<number | string>('');

  const handleChange = useCallback(
    (value: number | string) => setFilterTotal(value),
    [],
  );

  return (
    <Box sx={styles.root}>
      {disableOnlyFansIndicator ? null : (
        <OnlineFans total={subscribers.length} />
      )}
      <Filter onChange={handleChange} />
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={styles.list}>
          {subscribers
            .filter((subscriber) => +subscriber.totalSpent >= +filterTotal)
            .map((subscriber) => (
              <Account key={subscriber.id} subscriber={subscriber} />
            ))}
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
