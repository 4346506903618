export const SIGN_UP_API = '/auth/sign-up';
export const SIGN_UP_LINK_API = '/auth/sign-up/link';
export const SIGN_IN_API = '/auth/sign-in';
export const REFRESH_TOKEN_API = '/auth/refresh';
export const RESET_PASSWORD_API = '/auth/forgot';
export const CONFIRM_PASSWORD_API = '/auth/confirm-forgot';

export const AGENCIES_API = '/manager/agencies';
export const MEMBERS_API = '/manager/members';
export const PROFILE_API = '/manager/members/profile';

export const ROLES_API = '/manager/roles';
export const ROLES_PERMISSIONS_API = '/manager/roles/permissions';

export const STATISTICS_MAIN_API = '/statistics/creator/main';
export const STATISTICS_EARNING_API = '/statistics/creator/earning';
export const STATISTICS_BEST_DAY_API = '/statistics/creator/day';
export const STATISTICS_BEST_HOUR_API = '/statistics/creator/hour';
export const STATISTICS_EARNINGS_HEATMAP_API = '/statistics/creator/heatmap';
export const STATISTICS_RUN_RATE_API = '/statistics/creator/run-rate';
export const STATISTICS_TOP_FANS_API = '/statistics/creator/top-fans';
export const STATISTICS_EARNING_BREAKDOWN_API = '/statistics/creator/breakdown';
export const STATISTICS_EARNING_CHARGEBACKS_API =
  '/statistics/creator/chargebacks';
export const STATISTICS_EARNING_STATS_API =
  '/statistics/creator/earnings-stats';

export const getStatisticsCreatorOnlineApi = (creatorId: string) =>
  `/statistics/creators/${creatorId}/scraping/online-subscribers`;

export const getStatisticsCreatorChatterInfoApi = (
  creatorId: string,
  chatterId: string,
) => `/statistics/creators/${creatorId}/scraping/${chatterId}/info`;

export const getStatisticsCreatorChatterMediaApi = (
  creatorId: string,
  chatterId: string,
) => `/statistics/creators/${creatorId}/scraping/${chatterId}/media`;

export const getStatisticsCreatorChatterHelperApi = (creatorId: string) =>
  `/statistics/creators/${creatorId}/scraping/helpers`;

export const CREATORS_API = '/manager/creators';
export const CREATORS_DICTIONARIES_API = '/manager/creators/dictionaries';
export const CREATORS_CHECK_API = '/manager/creators/check';
export const CREATORS_PROXY_API = '/manager/proxy';
export const CREATORS_PROFILE_LINK_API = '/manager/creators/of-profile';

export const getCreatorSessionApi = (creatorId: string) =>
  `/manager/creators/${creatorId}/session`;
export const getActivateCreatorSessionApi = (creatorId: string) =>
  `/manager/creators/${creatorId}/session/activate`;
export const getDeactivateCreatorSessionApi = (creatorId: string) =>
  `/manager/creators/${creatorId}/session/deactivate`;

export const getRequestActivateCreatorApi = (id: string) =>
  `/manager/creators/${id}/session/request-activate`;

export const getRequestDeleteCreatorApi = (id: string) =>
  `/manager/creators/${id}/`;
export const getRequestChangeProxyApi = (id: string) =>
  `/manager/creators/${id}/proxy`;

export const getFanNotesApi = (creatorId: string, chatterId: string) =>
  `/statistics/creators/${creatorId}/scraping/${chatterId}/fan-notes`;

export const getScrapingMetadataApi = (creatorId: string, chatterId: string) =>
  `/statistics/creators/${creatorId}/scraping/${chatterId}/metadata`;

export const getUpdateCreatorApi = (id: string) => `/manager/creators/${id}/`;

export const MEMBERS_TOKEN_API = '/manager/members/token';
export const MEMBERS_CREATORS_API = '/manager/members/creators';
export const MEMBERS_PASSWORD_API = '/manager/members/password';
export const assignMemberCreatorApi = (memberId: string, creatorId: string) =>
  `/manager/members/${memberId}/creators/${creatorId}`;

export const PAYMENT = '/finance/payments';
export const PAYMENT_CUSTOMERS = '/finance/payments/customers';
export const PAYMENT_BALANCE = '/finance/payments/balance';
export const SUBSCRIPTIONS_HISTORY = '/finance/subscriptions/history';
export const SUBSCRIPTIONS = '/finance/subscriptions';
export const deleteMemberApi = (memberId: string) =>
  `/manager/members/${memberId}`;

export const updateMemberRoleApi = (memberId: string, roleId: string) =>
  `/manager/members/${memberId}/roles/${roleId}`;

export const updateMemberApi = (memberId: string) =>
  `/manager/members/${memberId}`;

export const AUTHORIZATION_TOKEN = 'Authorization';
export const ID_TOKEN = 'idToken';
export const REFRESH_TOKEN = 'Refresh';
export const PAYMENT_IDS = 'Payments';
