import { BrandCanvas } from "components/BrandCanvas/BrandCanvas";
import SignIn from "components/SignIn/SignIn";
import { RoutePath } from "config/routeConfig";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { getInitAppLoading, getUserAuthData } from "store/reducers/auth";

const SignInPage = () => {
    const isAuth = useAppSelector(getUserAuthData);
    const isAppInitLoading = useAppSelector(getInitAppLoading);
    const location = useLocation();

    if (isAuth && !isAppInitLoading) {
        return <Navigate to={RoutePath.accounts} replace state={{ from: location }} />;
    };

    return (
        <>
            <BrandCanvas />
            <SignIn />
        </>

    );
}

export default SignInPage;