
export interface Modal {
  type: ModalType;
  data?: unknown;
  callback?: () => any;
}


export enum ModalType {
    СONNECT_CREATOR = "СONNECT_CREATOR",
    ADD_MEMBERS = "ADD_MEMBERS",

    ADD_ROLE = "ADD_ROLE",
    UPDATE_ROLE = "UPDATE_ROLE",

    ASSIGN_CREATOR = "ASSIGN_CREATOR",
    MEMBERS_ROLE = "MEMBERS_ROLE",

    BILLING_HISTORY = "BILLING_HISTORY",
    BILLING_PAYMENT = "BILLING_PAYMENT",
    PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL",
    NONE = "NONE",

    SUCCESSFULLY_UPDATED = 'SUCCESSFULLY_UPDATED',
    AI_COPILOT = 'AI_COPILOT',
    BILLING_PAYMENT_SUCCESSFUL = 'BILLING_PAYMENT_SUCCESSFUL',

    SUCCESSFUL_INFO = "SUCCESSFUL_INFO", 
    EDIT_PROXY = "EDIT_PROXY",
    AVAILABLE_UPDATE = "AVAILABLE_UPDATE"

  }
