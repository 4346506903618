import React, { useEffect, useMemo, useState } from 'react';

import styles from './PaymentBalance.module.scss';
import { classNames } from 'utils/classNames';
import { Button, ThemeButton } from 'components/Button/Button';
import { useAppSelector } from 'store';
import {
  billingActions,
  getBillingAmount,
  getBillingBalance,
  getBillingLoading,
  getBillingSubscriptions,
} from 'store/reducers/billing';
import { useDispatch } from 'react-redux';
import { PaymentMethod } from 'constants/billing';
import { modalActions } from 'store/reducers/modal';
import { ModalType } from 'constants/modal';
import Input from 'components/fields/Input/Input';

import { Skeleton } from '@mui/material';
import { ReactComponent as BalanceIcon } from 'assets/images/billing/balance.svg';
import { ReactComponent as FundsIcon } from 'assets/images/billing/funds.svg';
import { ReactComponent as PaymentIcon } from 'assets/images/billing/payment.svg';
import Select from 'components/fields/Select/Select';
import { convertToDropdownArray, convertToDropdownItem } from 'utils/convert';

import moment from 'moment';

interface PaymentBalanceProps {
  className?: string;
}

const BillingLimits = {
  Min: 10,
  Max: 50000,
};

export const PaymentBalance = ({ className }: PaymentBalanceProps) => {
  const [isValidBillingAmount, setIsValidBillingAmount] = useState(true);
  const billingAmount = useAppSelector(getBillingAmount);
  const balance = useAppSelector(getBillingBalance);
  const loading = useAppSelector(getBillingLoading);
  const { items: subscriptions, loading: nextPaymentLoading } = useAppSelector(
    getBillingSubscriptions,
  );

  const dispatch = useDispatch();

  const nextPaymentDate = useMemo(() => {
    const endDateList = subscriptions.map(({ endDate }) => moment(endDate));
    return moment.min(endDateList);
  }, [subscriptions]);

  const nextPaymentAccounts = useMemo(() => {
    const list = subscriptions.filter(({ endDate }) =>
      moment(nextPaymentDate).isSame(endDate, 'day'),
    );
    return list.map(({ creatorUsername }) => creatorUsername);
  }, [nextPaymentDate, subscriptions]);

  const paymentMethodDropdownOptions = useMemo(
    () => [PaymentMethod.CREDIT_CARD],
    [],
  );

  useEffect(() => {
    const amount = +billingAmount!;
    if (!amount) {
      return;
    }

    setIsValidBillingAmount(
      amount >= BillingLimits.Min && amount <= BillingLimits.Max,
    );
  }, [billingAmount]);

  const onClickGoToPaymentBtn = () => {
    if (
      billingAmount?.trim() &&
      !isNaN(+billingAmount) &&
      +billingAmount >= +BillingLimits.Min &&
      +billingAmount <= BillingLimits.Max
    ) {
      dispatch(modalActions.setModalType({ type: ModalType.BILLING_PAYMENT }));
    } else {
      setIsValidBillingAmount(false);
    }
  };

  return (
    <div className={styles.payment}>
      <div className={classNames(styles.card, {}, [styles.balance])}>
        <div className={styles.icon}>
          <BalanceIcon />
        </div>
        <h3 className={styles.title}>Current balance</h3>
        <p className={styles.value}>
          {loading ? <Skeleton variant="rounded" /> : `$${balance}`}
        </p>
        <p className={styles.description}>
          This balance represents your available credits for purchasing services
          on our platform.
        </p>
      </div>
      <div className={classNames(styles.card, {}, [styles.funds])}>
        <div className={styles.icon}>
          <FundsIcon />
        </div>
        <h3 className={styles.title}>Add Funds</h3>
        <div className={styles.paymentMethod}>
          <Input
            value={billingAmount}
            onChange={(value) => dispatch(billingActions.setAmount(value))}
            className={classNames(styles.control, {}, [styles.input])}
            placeholder="Amount"
            error={
              !isValidBillingAmount
                ? `*Value must be more ${BillingLimits.Min}$ and less ${BillingLimits.Max}$`
                : ''
            }
          />
          <Select
            className={classNames(styles.control, {}, [styles.select])}
            placeholder="Payment Method"
            options={convertToDropdownArray(paymentMethodDropdownOptions)}
            defaultValue={PaymentMethod.CREDIT_CARD}
            isSearchable={false}
            value={convertToDropdownItem(PaymentMethod.CREDIT_CARD)}
          />
        </div>
        <Button
          theme={ThemeButton.PRIMARY}
          onClick={onClickGoToPaymentBtn}
          className={styles.paymentBtn}
          disabled={!billingAmount}
        >
          Go to payment
        </Button>
      </div>
      <div className={classNames(styles.card, {}, [styles.billingHistory])}>
        <div className={styles.icon}>
          <PaymentIcon />
        </div>
        <h3 className={styles.title}>Next Payment Due & Billing History</h3>
        <p className={styles.nextPayment}>
          {nextPaymentLoading ? (
            <Skeleton variant="rounded" />
          ) : (
            `on ${nextPaymentDate.format('MMMM DD, YYYY')}`
          )}
        </p>
        <div className={styles.history}>
          <div className={styles.accounts}>
            <p>
              Accounts: <span>{nextPaymentAccounts.length}</span>
            </p>
            {nextPaymentAccounts.length > 0 && (
              <span>@{nextPaymentAccounts.join(', ')}</span>
            )}
          </div>
          <Button
            className={styles.billingHistoryBtn}
            theme={ThemeButton.PRIMARY}
            onClick={() =>
              dispatch(
                modalActions.setModalType({ type: ModalType.BILLING_HISTORY }),
              )
            }
          >
            Billing History
          </Button>
        </div>
      </div>
    </div>
  );
};
