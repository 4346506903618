import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Collapsible from '../../../Collapsible';
import MainAccount from '../../../MainAccount';
import PurchasingBehavior from '../../../PurchasingBehavior';
import SubscriptionDetails from '../../../SubscriptionDetails';
import Loader from '../../../../Loader';

import {
  DollarSquare,
  MessageEdit,
  PersonalCard,
} from '../../../../../../../icons';

import { useElectronContext } from '../../../../../../../providers/ElectronProvider';

import { ISubscriberInfo } from '../../../../../../../../../models/subscribers';

import useStyles from './useStyles';

const FanInsights = () => {
  const styles = useStyles();

  const { activeChatterId, activeCreator, loadChatterInfo } =
    useElectronContext();

  const [isLoading, setIsLoading] = useState(false);
  const [chatter, setChatter] = useState<ISubscriberInfo | null>(null);

  const getChatterInfo = useCallback(
    async (creatorId: string, chatterId: string) => {
      try {
        setIsLoading(true);
        const info = await loadChatterInfo(creatorId, chatterId);

        setChatter(info);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (!activeChatterId || !activeCreator?.id) {
      return;
    }

    getChatterInfo(activeCreator.id, activeChatterId);
  }, [activeCreator?.id, activeChatterId]);

  if (isLoading) {
    return <Loader />;
  }

  if (!chatter) {
    return null;
  }

  return (
    <Box sx={styles.root}>
      <Collapsible title="Personal Information" icon={<PersonalCard />}>
        <MainAccount
          details={chatter.personalInfo}
          isLoading={isLoading}
          updateChatterInfo={getChatterInfo}
        />
      </Collapsible>
      <Collapsible title="Subscription Details" icon={<MessageEdit />}>
        <SubscriptionDetails details={chatter.subscriptionDetails} />
      </Collapsible>
      <Collapsible title="Purchasing Behavior" icon={<DollarSquare />}>
        <PurchasingBehavior details={chatter.purchasingBehaviour} />
      </Collapsible>
    </Box>
  );
};

export default FanInsights;
