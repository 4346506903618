import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { yupToFormErrors } from 'formik';

import Input from 'components/fields/Input/Input';
import styles from './SignIn.module.scss';
import { classNames } from 'utils/classNames';
import { IConfirmPassword, SignInStep } from 'models/auth';
import RequestForm from './RequestForm/RequestForm';
import { SuccessForm } from './SuccessForm/SuccessForm';
import { getAuthInited } from 'store/reducers/auth';
import { useAppSelector } from 'store';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { ConfirmPassword } from './ConfirmPassword/ConfirmPassword';

interface SignInFormProps {
  className?: string;
}

const SignIn = ({ className }: SignInFormProps) => {
  const [activeStep, setActiveStep] = useState(SignInStep.REQUST_FORM);
  const [showingProcess, setShowingProcess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirmPasswordData, setConfirmPasswordData] =
    useState<IConfirmPassword>();
  const [successText, setSuccessText] = useState('');

  const inited = useAppSelector(getAuthInited);

  const processingQueryValues = useCallback(async () => {
    const email = searchParams.get('email') ?? '';
    const code = searchParams.get('code') ?? '';

    if (email && code) {
      setConfirmPasswordData({ email, code });
      setSearchParams(undefined);
      setActiveStep(SignInStep.CONFIRM_PASSWORD);
    }
  }, [searchParams]);

  useEffect(() => {
    processingQueryValues();

    // animation page
    setTimeout(() => setShowingProcess(true), 1000);
  }, [inited]);

  const onClickForgotPassword = useCallback(() => {
    setActiveStep(SignInStep.FORGOT_PASSWORD);
  }, []);

  const onNextSuccessStep = useCallback((label: string) => {
    setActiveStep(SignInStep.SUCCESS);
    setSuccessText(label);
  }, []);

  const onCancel = useCallback(() => {
    setActiveStep(SignInStep.REQUST_FORM);
  }, []);

  const render = useCallback(() => {
    switch (activeStep) {
      case SignInStep.REQUST_FORM: {
        return <RequestForm onClickForgotPassword={onClickForgotPassword} />;
      }
      case SignInStep.FORGOT_PASSWORD: {
        const label =
          'The email has been sent. You will receive an email with a link to reset your password.';
        return (
          <ForgotPassword
            onNext={() => onNextSuccessStep(label)}
            onCancel={onCancel}
          />
        );
      }
      case SignInStep.CONFIRM_PASSWORD: {
        const label = 'The password has been successfully changed.';
        return (
          <ConfirmPassword
            data={confirmPasswordData}
            onNext={() => onNextSuccessStep(label)}
            onCancel={onCancel}
          />
        );
      }
      case SignInStep.SUCCESS: {
        return <SuccessForm text={successText} onDone={onCancel} />;
      }
    }

    return null;
  }, [activeStep, successText, confirmPasswordData]);

  return (
    <div
      className={classNames(
        styles.signIn,
        { [styles.showingProcess]: showingProcess },
        [className],
      )}
    >
      <div className={styles.form}>
        <div className={styles.body}>{render()}</div>
      </div>
    </div>
  );
};

export default SignIn;
