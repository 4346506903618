import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalType } from 'constants/modal';
import { IPaymentCustomer, ISubscriptions } from 'models/billing';
import { StateSchema, ThunkConfig } from 'store';
import { modalActions } from './modal';
import { billingService } from 'services/BillingService';
import { authActions } from './auth';
import { ErrorData } from 'models/auth';

export const createOrUpdatePaymentCustomer = createAsyncThunk<
    void,
    IPaymentCustomer,
    ThunkConfig<string>
>(
    'billing/createOrUpdatePaymentCustomer',
    async (data: IPaymentCustomer, { getState, rejectWithValue, dispatch }) => {
        const authData = getState().auth.authData;
        try {
            const stripeCustomerId = authData?.stripeCustomerId;
            const modifiedAuthData = stripeCustomerId ? (await billingService.updatePaymentCustomer(data)) : (await billingService.createPaymentCustomer(data));
            dispatch(authActions.updateAuthData(modifiedAuthData.member));
        } catch (ex) {
            return rejectWithValue("Error");
        }
    }
);

export const loadCurrentBalance = createAsyncThunk<
    number,
    void,
    ThunkConfig<string>
>(
    'billing/loadCurrentBalance',
    async (_, { rejectWithValue }) => {

        try {
            const response = await billingService.getBalance(); 
            return +response.balance;
        } catch (ex) {
            return rejectWithValue("Error");
        }
    }
);

export const loadSubscriptions = createAsyncThunk<
    ISubscriptions[],
    void,
    ThunkConfig<string>
>(
    'billing/loadSubscriptions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await billingService.getSubscriptions(); 

            return response.subscriptions;
        } catch (ex) {
            return rejectWithValue("Error");
        }
    }
);


export interface BillingSchema {
    amount?: string;
    subscriptions: {
        items: ISubscriptions[],
        loading: boolean
    };
    balance: number;
    loading: boolean;
    error?: string | ErrorData[];
}

const initialState: BillingSchema = {
    amount: undefined,
    subscriptions: {
        items: [],
        loading: false
    },
    balance: 0,
    loading: false,
};

export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        setAmount: (state: BillingSchema, action: PayloadAction<string | undefined>) => {
            state.amount = action.payload;
        },
        updateAuthErrors: (state: BillingSchema, action: PayloadAction<string | ErrorData[] | undefined>) => {
            state.error = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadCurrentBalance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadCurrentBalance.fulfilled, (state, action) => {
            state.loading = false;
            state.balance = action.payload;
        });

        builder.addCase(loadSubscriptions.pending, (state) => {
            state.subscriptions.loading = true;
        });
        builder.addCase(loadSubscriptions.fulfilled, (state, action) => {
            state.subscriptions.items = action.payload;
            state.subscriptions.loading = false;
        });
        builder.addCase(loadSubscriptions.rejected, (state) => {
            state.subscriptions.loading = false;
        });
    },
});

export const getBillingAmount = (state: StateSchema) => state.billing.amount;
export const getBillingBalance = (state: StateSchema) => state.billing.balance;
export const getBillingLoading = (state: StateSchema) => state.billing.loading;
export const getBillingSubscriptions = (state: StateSchema) => state.billing.subscriptions;

export const { actions: billingActions } = billingSlice;
export const { reducer: billingReducer } = billingSlice;
