import { MediaStatusEnum } from '../../../../../../../../../models/media';

export const MediaStatusTitleEnum = {
  [MediaStatusEnum.Free]: 'Free',
  [MediaStatusEnum.NotPurchased]: 'Not Purchased',
  [MediaStatusEnum.Paid]: 'Paid',
};

export const FILTER_MEDIA_TAGS = [
  { key: 'all', label: 'All' },
  {
    key: MediaStatusEnum.Free,
    label: MediaStatusTitleEnum[MediaStatusEnum.Free],
  },
  {
    key: MediaStatusEnum.Paid,
    label: MediaStatusTitleEnum[MediaStatusEnum.Paid],
  },
  {
    key: MediaStatusEnum.NotPurchased,
    label: MediaStatusTitleEnum[MediaStatusEnum.NotPurchased],
  },
];
