import React, { useState } from 'react';
import { Box } from '@mui/material';

import { useElectronContext } from '../../../../../providers/ElectronProvider';
import Loader from '../../Loader';
import Tab from './Tab';
import { TABS } from './constanrs';

import useStyles from './useStyles';

const Tabs = () => {
  const styles = useStyles();

  const { isLoading } = useElectronContext();
  const [activeTab, setActiveTab] = useState(TABS[0].label);

  return (
    <>
      <Box sx={styles.root}>
        {TABS.map(({ label }) => (
          <Tab
            key={label}
            label={label}
            onClick={() => setActiveTab(label)}
            active={activeTab === label}
          />
        ))}
      </Box>
      {isLoading ? (
        <Box sx={{ paddingTop: '45px' }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={styles.content}>
          {TABS.filter((tab) => tab.label === activeTab).map(
            ({ label, content }) => (
              <Box sx={label === activeTab ? {} : styles.hidden} key={label}>
                {content}
              </Box>
            ),
          )}
        </Box>
      )}
    </>
  );
};

export default Tabs;
