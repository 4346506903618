import React, { useCallback, useEffect, useState } from 'react';
import {
  useElectronContext,
  useVaultProviderContext,
} from '../../../providers';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';

function debounce(func: () => void, timeout = 300) {
  let timer: TimeoutId | undefined;

  return () => {
    if (!timer) {
      func();
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

const useVault = ({ ref }: { ref: React.MutableRefObject<any | null> }) => {
  const { activeCreator, activeChatterId } = useElectronContext();
  const { media } = useVaultProviderContext();

  const [isStyled, setIsStyled] = useState(false);

  useEffect(() => {
    setIsStyled(false);
  }, [activeCreator?.id, activeChatterId]);

  const handleStyles = useCallback(() => {
    ref.current.insertCSS(`
      .cc-updated:before {
        background: rgba(0, 0, 0, 0.5);
      }
      
      .cc-updated .cc-vault-price {
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 2;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 5px;
        color: #C60202;
        background: #DFC7C7;
        border-radius: 4px;
      }
      
      .cc-updated .cc-vault-price.free {
        color: #005E0F;
        background: #DFEAE0;
      }
      
      .cc-updated .cc-vault-price.paid {
        color: #00AFF0;
        background: #DFF4FB;
      }
      
    `);
  }, []);

  const runVaultMatch = useCallback(async () => {
    const runScript = (media: any) => {
      //@ts-ignore
      media.forEach(({ thumbName, status, price = 0 }) => {
        const image = document.querySelector(
          `.b-photos__item:not(.cc-updated) img[src*="${thumbName}"]`,
        );

        if (!image) {
          return;
        }

        const imageWrap = image.parentElement;

        if (!imageWrap) {
          return;
        }

        let total = +price > 0 ? price + '$' : '';
        if (status === 'Free') {
          total = 'Free';
        } else if (status === 'NotPurchased') {
          total += ' unpaid';
        }

        const priceElement = document.createElement('span');

        priceElement.setAttribute(
          'class',
          `cc-vault-price ${status.toLowerCase()}`,
        );
        priceElement.appendChild(document.createTextNode(total));

        imageWrap.appendChild(priceElement);
        imageWrap.classList.add('cc-updated');
      });

      return;
    };

    if (!ref.current) {
      return;
    }

    await ref.current.executeJavaScript(
      `(${runScript})(${JSON.stringify(media)})`,
    );
  }, [media]);

  useEffect(() => {
    if (!window.electronAPI || !media.length) {
      return;
    }

    const eventName = 'vault-media';

    const cb = () => {
      if (!isStyled) {
        handleStyles();
        setIsStyled(true);
      }

      runVaultMatch();
    };

    const debounceCb = debounce(cb, 500);

    window.electronAPI.receive(eventName, debounceCb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [media, runVaultMatch, handleStyles, isStyled]);
};

export default useVault;
