import React, { useCallback, useMemo, useState } from 'react';
import { yupToFormErrors } from 'formik';

import Input from '../../fields/Input/Input';
import { Button, ThemeButton } from '../../Button/Button';

import { ThemeField } from '../../../models/fields';
import { IValidateFlags } from '../../../models/validation';

import { ModalType } from '../../../constants/modal';
import { modalActions } from '../../../store/reducers/modal';

import { settingsPasswordFormSchema } from '../../../utils/validators';

import { useAppDispatch } from '../../../store';
import { updatePassword } from '../../../store/reducers/members';

import styles from './PasswordForm.module.scss';

type ValidateDataType = {
  oldPassword: string;
  newPassword: string;
  repeatedPassword: string
}

export const PasswordForm = () => {
  const dispatch = useAppDispatch();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const [loading, setLoading] = useState(false);

  const [validateErrors, setValidateErrors] = useState<IValidateFlags<ValidateDataType>>({});

  const resetState = useCallback(() => {
    setOldPassword('')
    setNewPassword('')
    setRepeatedPassword('')
  }, [])

  const onSubmit = useCallback( async () => {
    setValidateErrors({});
    setLoading(true);
      try {
        await settingsPasswordFormSchema.validate({ oldPassword, newPassword, repeatedPassword }, { abortEarly: false });
        const response = await dispatch(
          updatePassword({
            oldPassword,
            newPassword
          })
        );

       resetState();

        if (response.meta.requestStatus === "fulfilled") {
          dispatch(modalActions.setModalType({ type: ModalType.SUCCESSFULLY_UPDATED }));
        }
      }  catch (errors) {
        setValidateErrors(yupToFormErrors(errors));
      }
    setLoading(false);
  }, [oldPassword, newPassword, repeatedPassword]);

  const isDisabled = useMemo(() => (
   !oldPassword || !newPassword || !repeatedPassword
  ), [oldPassword, newPassword, repeatedPassword])

  return (
    <form className={styles.form}>
      <Input
        fieldName="Current Password"
        placeholder="●●●●●●●●●"
        theme={ThemeField.DARK}
        readonly={loading}
        value={oldPassword}
        onChange={setOldPassword}
        className={styles.input}
        error={validateErrors.oldPassword}
        disabled={loading}
        type='password'
      />
      <Input
        fieldName="Password"
        placeholder="●●●●●●●●●"
        theme={ThemeField.DARK}
        readonly={loading}
        value={newPassword}
        onChange={setNewPassword}
        className={styles.input}
        error={validateErrors.newPassword}
        disabled={loading}
        type='password'
      />
      <Input
        fieldName="Confirm New Password"
        placeholder="●●●●●●●●●"
        theme={ThemeField.DARK}
        readonly={loading}
        value={repeatedPassword}
        onChange={setRepeatedPassword}
        className={styles.input}
        error={validateErrors.repeatedPassword}
        disabled={loading}
        type='password'
      />
      <Button
        disabled={isDisabled}
        theme={ThemeButton.PRIMARY}
        className={styles.button}
        onClick={onSubmit}
        loading={loading}
      >
        Update
      </Button>
    </form>
  );
};
