import { IMember } from "../../../models/members";

import { getMembersState, membersActions } from "store/reducers/members";
import { modalActions } from "../../../store/reducers/modal";
import { ModalType } from "../../../constants/modal";
import { useAppDispatch, useAppSelector } from "store";

import { ReactComponent as PlusIcon } from "assets/images/add.svg";

import styles from "../Members.module.scss";
import { useMemo } from "react";
import { useChatterCharms } from "providers/ChatterCharmsProvider";

const CreatorCell = ({ item }: { item: IMember }) => {
    const { membersCreators } = useAppSelector(getMembersState);
    const { creatorMap } = useChatterCharms();
    const dispatch = useAppDispatch();

    const activeMemberAccountIds = useMemo(() =>
        membersCreators.filter(({ memberId }) => memberId === item?.id).map((item) => item.creatorId), [item?.id, membersCreators]);

    const memberAccountsNames = useMemo(() =>
        activeMemberAccountIds.map((creatorId) => creatorMap?.get(creatorId)?.name).filter(Boolean).join(', '),
    [activeMemberAccountIds, creatorMap]);

    return (
        <div className={styles.creatorAssigned}>
            {!memberAccountsNames ? (
                <div
                    className={styles.add}
                    onClick={() => {
                        dispatch(membersActions.setMemberActiveModal(item));
                        dispatch(modalActions.setModalType({ type: ModalType.ASSIGN_CREATOR }));
                    }}
                >
                    <PlusIcon /> Assign Account
                </div>
            ) : (
                <span>{memberAccountsNames}</span>
            )}
        </div>
    );
};

export default CreatorCell;
