import { classNames } from "utils/classNames";

import styles from "./Sidebar.module.scss";
import { useCallback, useMemo, useState } from "react";
import { SidebarItem } from "./SidebarItem/SidebarItem";
import { sidebarItemsList } from "constants/sidebar";
import { ReactComponent as LogOutIcon } from "../../assets/images/return.svg";
import { ReactComponent as BrandLogo } from "../../assets/images/defaultBrandLogo.svg";
import { useAppDispatch } from "store";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "config/routeConfig";
import { authActions } from "store/reducers/auth";

import usePermissions from "hooks/usePermissions";

interface SidebarProps {
    className?: string;
}

const Sidebar = ({ className }: SidebarProps) => {
    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { userPermissions, isSuperAdminPermission } = usePermissions();

    const onLogout = useCallback(() => {
        dispatch(authActions.logout());
        navigate(RoutePath.signIn);
    }, []);

    const itemsList = useMemo(() => {
        return sidebarItemsList
            .filter((item) => {
                if (!item.permissions?.length || !item.permissions || isSuperAdminPermission) {
                    return true;
                }

                const intersection = item.permissions.filter((permission) =>
                    userPermissions?.includes(permission)
                );

                return !!intersection.length;
            }).map((item) => <SidebarItem key={item.path} item={item} collapsed={collapsed} />);
    }, [collapsed, userPermissions, isSuperAdminPermission]);

    return (
        <nav className={classNames(styles.sidebar, {}, [className])}>
            <div className={styles.header}>
                <BrandLogo />
                <h1 className={styles.title}><span>Chatter</span>Charms</h1>
            </div>

            <div className={styles.menu}>{itemsList}</div>

            <div className={styles.logOut} onClick={onLogout}>
                <LogOutIcon />
                <span>Log Out</span>
            </div>
        </nav>
    );
};

export default Sidebar;
