import React, { useCallback } from 'react';

import styles from './ChipArea.module.scss';
import { classNames } from 'utils/classNames';
import { IDropdownOption, ThemeField } from 'models/fields';
import { INamedFieldProps, NamedField } from '../NamedField/NamedField';

export enum ChipAreaType {
    DEFAULT = "default",
    CLEAR = "clear"
}

interface ChipAreaProps extends INamedFieldProps {
    theme?: ThemeField;
    name?: string;
    fieldName?: string;
    value?: IDropdownOption[] | IDropdownOption | null;
    options?: IDropdownOption[];
    onChange?: (value: IDropdownOption[] | IDropdownOption, targetName?: string) => void;
    containerClassName?: string;
    className?: string;
    disabled?: boolean;
    multiple?: boolean;
    type?: ChipAreaType;
}

export const ChipArea = ({
    theme = ThemeField.DARK,
    value,
    name,
    disabled,
    fieldName,
    className,
    containerClassName,
    options,
    onChange,
    multiple,
    error,
    type = ChipAreaType.DEFAULT
}: ChipAreaProps) => {

    const getSelectedChipIndex = useCallback((chip: IDropdownOption) => {
        if (multiple && Array.isArray(value)) {
            return value?.findIndex(item => item.value === chip.value) !== -1
        } else {
            return chip.value === (value as IDropdownOption)?.value && chip.label === (value as IDropdownOption)?.label
        }

    }, [value, multiple]);

    const onClickChipItem = useCallback((chip: IDropdownOption) => {
        if (disabled) return;

        if (multiple && Array.isArray(value)) {
            const updatedValues = [...value];
            const selectedIndex = value?.findIndex(item => item.value === chip.value);

            // Toggle implementation
            selectedIndex !== -1 ? updatedValues.splice(selectedIndex, 1) : updatedValues.push(chip);

            onChange?.(updatedValues, name);
            return;
        }

        onChange?.(chip, name);

    }, [value, name, onChange, disabled, multiple]);

    return (
        <NamedField
            fieldName={fieldName}
            error={error}
            className={classNames(styles.container, { [styles.error]: error }, [containerClassName, styles[type], theme])}
        >
            <div className={classNames(styles.area, {}, [className])}>
                {options?.map((item, index) => (
                    <div
                        key={index}
                        className={classNames(styles.chip, { [styles.selected]: getSelectedChipIndex(item) })}
                        onClick={() => onClickChipItem(item)}>
                        {item.label}
                    </div>
                ))}
            </div>
        </NamedField>

    );
};
