import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      height: '100vh',
    },
    webview: (isWebview: boolean) => ({
      position: 'relative',
      width: isWebview ? 'calc(100% - 575px)' : 'calc(100%)',
      marginLeft: '75px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        width: isWebview ? 'calc(100% - 450px)' : 'calc(100%)',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        width: isWebview ? 'calc(100% - 340px)' : 'calc(100% )',
        marginLeft: '50px',
      },
    }),
  };
};

export default useStyles;
