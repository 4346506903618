import React from 'react';
import { Box, Button } from '@mui/material';
import { useAppSelector } from '../../../../store';

import { getCreatorsList } from '../../../../store/reducers/creators';
import { useElectronContext } from '../../providers/ElectronProvider/ElectronProvider';
import { getUserAuthData } from '../../../../store/reducers/auth';
import { CreatorsSessionStatus } from '../../../../constants/creators';

import { ReactComponent as HomeIcon } from 'assets/images/home.svg';

import useStyles from './useStyles';
import { NOT_SET_PROXY } from '../../../../models/creators';
import { useChatterCharms } from '../../../../providers/ChatterCharmsProvider';

const ElectronSidebar = () => {
  const styles = useStyles();

  const isAuth = useAppSelector(getUserAuthData);

  const { creators: creatorsList } = useChatterCharms();

  const { setActiveCreator, setOfUrl, setActiveChatterId, isActivatingModel } =
    useElectronContext();
  const handleClick = (data: string) => {
    setOfUrl(data);
  };

  return (
    <Box sx={styles.root}>
      <Button
        sx={styles.home}
        onClick={() => {
          setActiveCreator?.(null);

          handleClick('');
        }}
      >
        <HomeIcon />
      </Button>
      <Box display={'flex'} gap={2} flexDirection={'column'}>
        {isAuth &&
          creatorsList
            ?.filter(({ proxyId, proxy }) => proxyId !== NOT_SET_PROXY && proxy)
            ?.map((creator) => {
              const {
                id,
                ofNick,
                imageLink = null,
                name,
                sessionStatus,
              } = creator;

              return (
                <Button
                  sx={styles.button(
                    sessionStatus === CreatorsSessionStatus.ACTIVE,
                  )}
                  key={id}
                  onClick={() => {
                    if (sessionStatus !== CreatorsSessionStatus.ACTIVE) {
                      return;
                    }

                    setActiveCreator?.(creator);
                    setActiveChatterId(null);
                    handleClick('https://onlyfans.com/');
                  }}
                  disabled={isActivatingModel}
                >
                  {imageLink ? (
                    <img src={imageLink} alt={name} />
                  ) : (
                    `${ofNick?.charAt(0)}${ofNick?.charAt(1)}`.toUpperCase()
                  )}
                </Button>
              );
            })}
      </Box>
    </Box>
  );
};

export default ElectronSidebar;
