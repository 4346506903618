import { classNames } from 'utils/classNames';
import styles from './AgencyForm.module.scss';
import Input from 'components/fields/Input/Input';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ThemeButton } from 'components/Button/Button';
import Select from 'components/fields/Select/Select';
import { ReactComponent as AgencyIcon } from '../../../assets/images/agency.svg';
import { ReactComponent as TimezoneIcon } from '../../../assets/images/timezone.svg';
import { useAppDispatch } from 'store';
import { signUp } from 'store/reducers/auth';
import { timeZoneList } from 'constants/timeZones';
import { DeepPartial } from '@reduxjs/toolkit';
import { agencyFormSchema } from 'utils/validators';
import { yupToFormErrors } from 'formik';
import { AgencyType, IAgencyFormData, ISignUpRequest } from 'models/auth';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'config/routeConfig';
import { IDropdownOption, ThemeField } from 'models/fields';

interface AgencyFormProps {
    data: ISignUpRequest;
    className?: string;
    onChangeData: (value: string | boolean | AgencyType, targetName?: string) => void;
    onBack: () => void;
}

export const AgencyForm = ({ data, className, onChangeData, onBack }: AgencyFormProps) => {
    const [validateErrors, setValidateErrors] = useState<DeepPartial<IAgencyFormData>>({});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { zone, agencyName, type } = data;

    const isSolo = type === AgencyType.SOLO;
    // [TODO]: Draft solution
    const zoneSelectValue = useMemo(() => timeZoneList.find(item => item.label === zone && item.value === zone), [zone]);

    useEffect(() => {
        setValidateErrors({});
    }, [agencyName, zone]);

    const onClickSubmitButton = useCallback(async () => {
        setLoading(true);
        try {
            await agencyFormSchema.validate({ agencyName, zone, type }, { abortEarly: false });
            const response = await dispatch(signUp({ ...data }));

            if (response.meta.requestStatus === "fulfilled") {
                navigate?.(RoutePath.accounts);
            }
        } catch (errors) {
            setValidateErrors(yupToFormErrors(errors));
        }
        setLoading(false);
    }, [data, agencyName, zone, type, navigate, dispatch]);

    const onPressKeyButton = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onClickSubmitButton();
        }
    }

    return (
        <div className={classNames(styles.AgencyForm, {}, [className])}>
            <h1 className={styles.title}>Hello, {data?.name}!</h1>
            <Input
                name='agencyName'
                placeholder='ChatterCharms'
                theme={ThemeField.LIGHT}
                readonly={loading}
                value={agencyName}
                onChange={onChangeData}
                fieldName='Agency name'
                error={validateErrors.agencyName}
                disabled={isSolo}
                onKeyUp={onPressKeyButton}
                mandatory
            />
            <div
                className={styles.soloCreator}
                onClick={() => onChangeData(isSolo ? AgencyType.TEAM : AgencyType.SOLO , "type")}
            >
                <input
                    id="soloCreator"
                    type="checkbox"
                    onClick={(e) => e.stopPropagation()}
                    checked={isSolo}
                />
                <label htmlFor="soloCreator">I’m a solo Creator, without an Agency</label>
            </div>
            <Select
                name='zone'
                fieldName='Select time zone'
                placeholder='Ex. (GMT -9:00)'
                theme={ThemeField.LIGHT}
                options={timeZoneList}
                className={styles.input}
                isDisabled={loading}
                value={zoneSelectValue}
                onChange={(value, actionMeta) => {
                    const targetName = actionMeta.name;
                    const updatedValue = value as IDropdownOption;
                    onChangeData(updatedValue.value as string, targetName);
                }}
                error={validateErrors.zone}
                mandatory
            />
            <Button
                onClick={onClickSubmitButton}
                theme={ThemeButton.PRIMARY}
                className={styles.button}
                loading={loading}
            >
                Register
            </Button>

            <Button
                disabled={loading}
                onClick={onBack}
                theme={ThemeButton.OUTLINE}
            >
                Back
            </Button>
        </div>
    );
};
