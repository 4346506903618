import { BaseService } from "./BaseService";

import {
  assignMemberCreatorApi,
  MEMBERS_TOKEN_API,
  MEMBERS_CREATORS_API,
  updateMemberRoleApi,
  deleteMemberApi,
  updateMemberApi,
  MEMBERS_API, MEMBERS_PASSWORD_API,
} from '../config/api';
import { IMember, IMemberCreator, IMemberData } from '../models/members';

//TODO: use config for abortController
class MemberService extends BaseService {
  public async createMemberToken(
    data: { roleId: string },
    config: any = {}
  ): Promise<{ memberToken: "string" }> {
    return this.post(MEMBERS_TOKEN_API, data);
  }

  public async getMembers(): Promise<{ members: IMember[] }> {
    return this.get(MEMBERS_API);
  }

  public async getMembersCreators(config: any = {}): Promise<{
    membersCreators: IMemberCreator[];
  }> {
    return this.get(MEMBERS_CREATORS_API);
  }

  public assignMemberCreator(
    { memberId, creatorId }: { memberId: string; creatorId: string },
    config: any = {}
  ): Promise<IMemberCreator> {
    return this.post(assignMemberCreatorApi(memberId, creatorId));
  }

  public removeMemberCreator(
    { memberId, creatorId }: { memberId: string; creatorId: string },
    config: any = {}
  ): Promise<IMemberCreator> {
    return this.delete(assignMemberCreatorApi(memberId, creatorId));
  }

  public updateMemberRole(
    { memberId, roleId }: { memberId: string; roleId: string },
    config: any = {}
  ): Promise<IMemberCreator> {
    return this.patch(updateMemberRoleApi(memberId, roleId));
  }

  public deleteMember(memberId: string, config: any = {}) {
    return this.delete(deleteMemberApi(memberId));
  }

  public updateMemberProfile(memberId: string, data: IMemberData): Promise<IMember> {
    return this.patch(updateMemberApi(memberId), data);
  }

  public updateMemberPassword({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }): Promise<void> {
    return this.put(MEMBERS_PASSWORD_API, { oldPassword, newPassword });
  }
}

export const memberService = new MemberService();
