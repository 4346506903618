
import { PAYMENT, PAYMENT_CUSTOMERS, PAYMENT_BALANCE, SUBSCRIPTIONS_HISTORY, SUBSCRIPTIONS } from 'config/api';
import { BaseService } from './BaseService';
import { IPaymentCustomer, IPaymentInvoice, ISelectedPlanData, ISubscriptions, ISubscriptionsHistory } from 'models/billing';
import { IMember } from 'models/members';


class BillingService extends BaseService {
    public async getBalance(): Promise<{ balance: string }> {
        return this.get(PAYMENT_BALANCE);
    }

    public async getSubscriptionsHistory(): Promise<ISubscriptionsHistory> {
        return this.get(SUBSCRIPTIONS_HISTORY, { limit: 500 });
    }

    public async getSubscriptions(): Promise<{ subscriptions: ISubscriptions[] }> {
        return this.get(SUBSCRIPTIONS);
    }

    public async createPaymentSubscriptions(items: ISelectedPlanData[]): Promise<{ status: string }> {
        return this.post(SUBSCRIPTIONS, { items });
    }

    public async createPaymentCustomer(data: IPaymentCustomer): Promise<{ member: IMember }> {
        return this.post(PAYMENT_CUSTOMERS, data);
    }

    public async updatePaymentCustomer(data: IPaymentCustomer): Promise<{ member: IMember }> {
        return this.patch(PAYMENT_CUSTOMERS, data);
    }

    public async createPaymentInvoice(amount: number): Promise<{ invoice: IPaymentInvoice }> {
        return this.post(PAYMENT, { amount });
    }

    public async getPaymentInvoice(id: string): Promise<{ invoice: IPaymentInvoice }> {
        return this.get(`${PAYMENT}/${id}`);
    }

}

export const billingService = new BillingService();