import { Box } from '@mui/material';

import { MediaStatusTitleEnum } from '../constants';
import { MediaStatusEnum } from '../../../../../../../../../../models/media';

import useStyles from './useStyles';

const Tag = ({ name }: { name: MediaStatusEnum }) => {
  const styles = useStyles();

  return (
    // @ts-ignore
    <Box sx={{ ...styles.tag, ...(styles[name.toLowerCase()] ?? {}) }}>
      {MediaStatusTitleEnum[name]}
    </Box>
  );
};

export default Tag;
