import Chart, { Props } from "react-apexcharts";
import { memo, useEffect, useState } from 'react';
import { statisticsService } from 'services/StatisticsService';
import { StatisticFilters } from 'models/statistics';
import { ChartType } from '../Chart';
import variables from "assets/styles/variables.module.scss";

const initialOptions: Props = {
    options: {
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 5,
                borderRadiusApplication: "end",
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            labels: {
                rotate: -90,
                rotateAlways: true,
                style: {
                    colors: "#A3ADB1",
                },
            },
            categories: [],
            tickPlacement: 'on',
            axisTicks: {
                show: false
            },
            axisBorder: {
                color: "#474B56"
            }
        },
        yaxis: {
            //tickAmount: 3,
            min: 0,
            // @ts-ignore
            stepSize: 50,
            labels: {
                style: {
                    colors: "#A3ADB1",
                },
            },
        },
        colors: [variables.accentColor],
        tooltip: {
            marker: {
                show: false
            }
        },
        grid: {
            borderColor: "#474B56"
        }
    },
    series: [{
        name: "Earnings",
        data: []
    }],
};

interface ColumnChartProps {
    name: ChartType;
    className?: string;
    filters: StatisticFilters;
}

const ColumnChart = ({ name, filters, className }: ColumnChartProps) => {
    const [settings, setSettings] = useState(initialOptions);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = name === ChartType.BEST_HOUR ?
                    await statisticsService.getCreatorBestHour(filters) : await statisticsService.getCreatorBestDay(filters);

                const updatedSeries = data?.map(item => Number(item.sum));
                const updatedCategories = data?.map(item => item.value);
                const commonSum = updatedSeries?.reduce((sum, value) => sum + (value || 0), 0);

                const deepUpdatedSettings = JSON.parse(JSON.stringify(initialOptions));
                deepUpdatedSettings.options.xaxis.categories = updatedCategories;
                deepUpdatedSettings.series[0].data = updatedSeries;
                deepUpdatedSettings.options.xaxis.labels.rotate = name === ChartType.BEST_HOUR ? -90 : -45;
                deepUpdatedSettings.options.yaxis.labels.formatter = (val: string | number | number[]): string => {
                    return `$ ${Number(val)?.toFixed()}`;
                }
                (!commonSum || commonSum <= deepUpdatedSettings.options.yaxis.stepSize) && (deepUpdatedSettings.options.yaxis.tickAmount = 3);

                setSettings(deepUpdatedSettings);
            } catch { }

            setLoading(false)
        })();

    }, [filters, name]);

    return <Chart options={settings.options} series={settings.series} height={400} type="bar" />
};

export default memo(ColumnChart);
