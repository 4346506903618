import { memo, useCallback, useMemo, useState } from "react";

import { StateSchema, useAppDispatch, useAppSelector } from "store";
import { deleteMember, membersActions } from "../../store/reducers/members";
import { useChatterCharms } from "../../providers/ChatterCharmsProvider";

import CustomizedTable from "components/CustomizedTable/CustomizedTable";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import TableFilter from "./TableFilter";
import { Button, ThemeButton } from "components/Button/Button";

import styles from "./Members.module.scss";
import { classNames } from "utils/classNames";

import { IColumn } from "models/table";
import { MembersColumns } from "constants/members";

import { modalActions } from "store/reducers/modal";
import { ModalType } from "constants/modal";
import { IMember } from "models/members";

import { columns } from "./constants";
import { MemberAction, MembersProps } from "./interfaces";
import useMemberFilter from "./useMeberFilter";
import { ActionCell, MemberCell, RoleCell } from "./Columns";
import CreatorCell from "./Columns/CreatorCell";

const Members = memo(({ className }: MembersProps) => {
    const [activeAction, setActiveAction] = useState<MemberAction | null>(null);

    const dispatch = useAppDispatch();

    const { rolesMap } = useChatterCharms();

    const { loading, memberActiveModal } = useAppSelector((state: StateSchema) => state.members);

    const { members, filteredData, handleChange } = useMemberFilter();

    const handleDeleteMember = useCallback(async () => {
        if (!memberActiveModal) {
            return;
        }
        dispatch(deleteMember({ memberId: memberActiveModal.id }));
    }, [memberActiveModal]);

    const onRenderColumnItem = useCallback(
        (item: IMember, index: number, column: IColumn) => {
            const fieldName = column?.fieldName;

            switch (column?.id) {
                case MembersColumns.MEMBER: {
                    return <MemberCell item={item} />;
                }
                case MembersColumns.EMAIL: {
                    return <span className={styles.email}>{item.email}</span>;
                }
                case MembersColumns.ROLE: {
                    return <RoleCell item={item} />;
                }
                case MembersColumns.CREATOR_ASSIGNED: {
                    return <CreatorCell item={item} />;
                }
                case MembersColumns.ACTION: {
                    return (
                        <ActionCell item={item} onActiveActionChange={setActiveAction} />
                    );
                }
                default: {
                    if (fieldName && item) {
                        return <p>{(item as any)[fieldName]}</p>;
                    }
                    return <></>;
                }
            }
        },
        [rolesMap]
    );

    const addButton = useMemo(
        () => (
            <Button
                theme={ThemeButton.PRIMARY}
                className={styles.addBtn}
                onClick={() =>
                    dispatch(modalActions.setModalType({ type: ModalType.ADD_MEMBERS }))
                }
            >
                Add Members
            </Button>
        ), []);

    return (
        <>
            <div className={classNames(styles.membersTable, {}, [className])}>
                <div className={styles.header}>
                    <div className={styles.addMember}>
                        <h1 className={styles.title}>Members</h1>
                        {members.length > 0 && addButton}
                    </div>
                    <TableFilter onChange={handleChange} />
                </div>
                <div className={styles.tableContainer}>
                    <CustomizedTable
                        items={filteredData}
                        columns={columns}
                        onRenderColumnItem={onRenderColumnItem}
                        className={styles.table}
                        loading={loading}
                        emptyElement={members.length > 0 ? <span /> : addButton}
                    />
                </div>
            </div>
            {activeAction === MemberAction.Delete ? (
                <ConfirmationDialog
                    message={`Are you sure that you want to delete ${memberActiveModal?.name}?`}
                    title="Delete Member"
                    onConfirm={handleDeleteMember}
                    onClose={() => {
                        dispatch(membersActions.setMemberActiveModal(null));
                        setActiveAction(null);
                    }}
                />
            ) : null}
        </>
    );
});

export default Members;
