import React, { useState } from 'react';
import {
  IOFNotificationProps,
  IOFNotificationContext,
  NotificationStatusEnum,
} from './interfaces';

const DefaultContext = {
  status: NotificationStatusEnum.NONE,
  setStatus: () => {},
  triggerFlag: '',
  setTriggerFlag: () => {},
  isProcessing: false,
  setIsProcessing: () => {},
};

export const ContentContext =
  React.createContext<IOFNotificationContext>(DefaultContext);

export const useOFNotificationContext = () => React.useContext(ContentContext);

export const OFNotificationProvider = ({ children }: IOFNotificationProps) => {
  const [status, setStatus] = useState<NotificationStatusEnum>(
    NotificationStatusEnum.NONE,
  );

  const [triggerFlag, setTriggerFlag] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <ContentContext.Provider
      value={{
        status,
        setStatus,
        triggerFlag,
        setTriggerFlag,
        isProcessing,
        setIsProcessing,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
