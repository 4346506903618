import { memo, useEffect, useState } from 'react';
import Chart, { Props } from "react-apexcharts";
import { statisticsService } from 'services/StatisticsService';
import { IEarningsHeatmap, StatisticFilters, } from 'models/statistics';
import variables from "assets/styles/variables.module.scss";


const initialOptions: Props = {
    options: {
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#A3ADB1",
                },
                formatter: value => String(value)?.substring(0, 3), // TODO Fix
            },
        },
        xaxis: {
            labels: {
                style: {
                    colors: "#A3ADB1",
                },
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        chart: {
            toolbar: {
                show: false
            },
        },
        grid: {
            show: false
        },
        colors: ["#5BC0EB"],
        plotOptions: {
            heatmap: {
                enableShades: false,
                radius: 6,
                useFillColorAsStroke: false,
                colorScale: {
                    ranges: [
                        {
                            from: 0,
                            to: 0,
                            color: variables.primaryColor,
                        },
                        {
                            from: 1,
                            to: 20,
                            color: "#5BC0EB40",
                        },
                        {
                            from: 20,
                            to: 40,
                            color: "#5BC0EB60",
                        },
                        {
                            from: 40,
                            to: 60,
                            color: "#5BC0EB80",
                        },
                        {
                            from: 60,
                            color: "#5DC9F1",
                        },
                    ],
                },
            }
        },
        stroke: {
            width: 4,
            colors: [variables.secondaryColor]
        },
    },
    series: [],
};


interface HeatmapChartProps {
    name: string;
    filters: StatisticFilters;
    className?: string;
}

const HeatmapChart = ({ name, filters, className }: HeatmapChartProps) => {
    const [settings, setSettings] = useState(initialOptions);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = await statisticsService.getCreatorEarningHeatmap(filters);
                const deepUpdatedSettings = JSON.parse(JSON.stringify(initialOptions));
                deepUpdatedSettings.series = data;
    
                setSettings(deepUpdatedSettings);
            } catch {}

            setLoading(false)
        })();

    }, [filters, name]);

    return (<Chart options={settings.options} series={settings.series} height={400} type="heatmap" />);
};

export default memo(HeatmapChart);
