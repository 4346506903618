import { useCallback, useEffect, useState } from "react";

import { useAppSelector } from "../../store";
import { getMembersList } from "../../store/reducers/members";

import { ALL } from "./constants";

import { IMember } from "../../models/members";
import { getUserAuthData } from "../../store/reducers/auth";
import usePermissions from "hooks/usePermissions";

const useMemberFilter = () => {
    const { items: members } = useAppSelector(getMembersList);
    const user = useAppSelector(getUserAuthData);
    const { superAdminId, isSuperAdminPermission } = usePermissions();
    const [filteredData, setFilteredData] = useState<IMember[]>([]);

    const handleChange = useCallback((roleId: string) => {
        if (roleId === ALL) {
            setFilteredData(members.filter((member) => member.id !== user?.id));
            return;
        }

        setFilteredData(
            members.filter(
                (member) => member.roleId === roleId && member.id !== user?.id
            )
        );
    }, [members, user]);

    useEffect(() => {
        setFilteredData(members.filter((member) => member.id !== user?.id));
    }, [members, user]);

    return { members, filteredData: filteredData.filter(({ id }) => isSuperAdminPermission || superAdminId !== id), handleChange };
};

export default useMemberFilter;
