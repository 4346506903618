import { useCallback, useLayoutEffect } from "react";
import { useSnackbar } from 'notistack';
import axios from "axios";

import $api from "utils/axios";
import { useAppDispatch } from "store";
import { authActions } from "store/reducers/auth";
import { ApiErrorCode, ApiErrorStatusCode, getErrorMessage } from "constants/messages";
import { AUTHORIZATION_TOKEN, ID_TOKEN, REFRESH_TOKEN } from "config/api";
import { authService } from "services/AuthService";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "config/routeConfig";
import { modalActions } from "store/reducers/modal";
import { ModalType } from "constants/modal";
import * as Sentry from "@sentry/react";


const useHandleApiErrors = () => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const onRedirect = useCallback((path: string) => {
        dispatch(modalActions.setModalType({ type: ModalType.NONE, config: undefined }));
        // Todo: redirect to frist find path
        navigate(path);
    }, []);

    useLayoutEffect(() => {
        const responseInterceptor = $api.interceptors.response.use(
            response => response,
            (error: unknown) => handleError(error, onRedirect)
        );

        return () => {
            $api.interceptors.response.eject(responseInterceptor);
        };
    }, []);


    const handleError = async (error: unknown, onRedirect?: (path: string) => void) => {
        if (axios.isAxiosError(error)) {
            const { status, data } = error.response ?? {};
            const originalRequest = { ...error.config, headers: { ...error.config?.headers } };

            const errorCode: ApiErrorCode = data?.errors[0]?.message;
            const errorMessage = getErrorMessage(errorCode);

            // refresh token logic
            if (status === ApiErrorStatusCode.EXPIRED_ACCESS_TOKEN) {
                try {
                    const refreshToken = localStorage.getItem(REFRESH_TOKEN) || "";
                    const accessToken = localStorage.getItem(AUTHORIZATION_TOKEN) || "";
                    const { accessToken: updatedAccessToken, idToken: updatedIdToken } = await authService.refreshToken({ refreshToken, accessToken });

                    localStorage.setItem(AUTHORIZATION_TOKEN, updatedAccessToken!);
                    localStorage.setItem(ID_TOKEN, updatedIdToken!);

                    originalRequest.headers[AUTHORIZATION_TOKEN] = `Bearer ${updatedAccessToken}`;
                    originalRequest.headers[ID_TOKEN] = updatedIdToken;

                    return axios(originalRequest);
                } catch {
                    dispatch(authActions.logout());
                }
            }

            enqueueSnackbar(errorMessage, { variant: "error" });

            if (status === ApiErrorStatusCode.ERROR_PERMISSIONS_ACCESS && errorCode === ApiErrorCode.ROLE_PERMISSION_ERROR) {
                Sentry.captureException(error, { data: errorCode });
                dispatch(authActions.logout());
            }

        } else {
            console.error("An unexpected error occurred:", error);
        }

        return Promise.reject(error);
    };
};

export default useHandleApiErrors;