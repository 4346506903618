import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      borderRadius: '10px',
      border: '1px solid #E7E7E7',
      padding: '20px',

      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      gap: '8px',

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        borderRadius: '5px',
        padding: '15px 10px',
        gap: '7px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        borderRadius: '5px',
        padding: '10px',
        gap: '4px',
      },
    },
    title: {
      color: '#242628',
      textAlign: 'center',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      marginBottom: 0,
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '17px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '14px',
      },
    },
    text: {
      color: '#A3ADB1',
      fontSize: '19px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      marginBottom: 0,
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '14px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '10px',
      },
    },
  };
};

export default useStyles;
