import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';

import { IMedia } from '../../../../../../../../../../models/media';

import Tag from './../Tag';

import useStyles from './useStyles';

const Media = ({ media }: { media: IMedia }) => {
  const styles = useStyles();

  const printMedia = (src: string) => {
    if (src.includes('.mp4')) {
      return <video src={src} />;
    }

    return <img src={media.image} />;
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.media}>
        {media.image ? printMedia(media.image) : null}
        {media.price && +media.price > 0 ? (
          <Box sx={styles.overlay(media.status === 'Paid')}>
            <Typography component={'span'}>${media.price}</Typography>
          </Box>
        ) : null}
      </Box>
      <Box>
        <Tag name={media.status} />
        <Box sx={styles.text}>{media.text}</Box>
        <Box sx={styles.date}>
          {format(new Date(media.createdAt), 'MMM dd yyyy hh:mm a')}
        </Box>
      </Box>
    </Box>
  );
};

export default Media;
