import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useMemberToken = () => {
  const { search } = useLocation();

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (!search) {
      return;
    }

    const token = new URLSearchParams(search).get("token") ?? null;
    setToken(token);
  }, [search]);

  return { token };
};

export default useMemberToken;
