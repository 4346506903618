import { TableCell } from "@mui/material";
import { Tooltip } from "components/Tooltip/Tooltip";
import { IColumn } from "models/table";
import React, { useEffect, useState } from "react";
import styles from "./ColumnCell.module.scss";

const ColumnCell: React.FC<IColumn> = ({
    id,
    label,
    tooltipContent,
    isOpenTooltip,
    tooltipTimeout,
    align
}) => {
    const [showTooltip, setShowTooltip] = useState(isOpenTooltip);

    useEffect(() => {
        if (tooltipTimeout) setTimeout(() => setShowTooltip(false), tooltipTimeout);
    }, []);

    return (
        <Tooltip
            title={tooltipContent}
            key={id}
            open={showTooltip}
            placement='top'
            className={styles.tooltip}
        >
            <TableCell align={align} className={styles.tooltipCell}>{label}</TableCell>
        </Tooltip>
    )
};

export default ColumnCell;
