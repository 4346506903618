import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';

import { useVaultProviderContext } from '../../../../../../../providers';

import Media from './Media';
import Loader from '../../../../Loader';
import { FILTER_MEDIA_TAGS } from './constants';

import useStyles from './useStyles';

const History = () => {
  const styles = useStyles();

  const { media, isLoading } = useVaultProviderContext();

  const [status, setStatus] = useState(FILTER_MEDIA_TAGS[0].key);

  return (
    <>
      <Typography sx={styles.title}>Search by:</Typography>
      <Box sx={styles.tags}>
        {FILTER_MEDIA_TAGS.map(({ key, label }) => (
          <Button
            key={key}
            onClick={() => {
              setStatus(key);
            }}
            sx={styles.button(key === status)}
          >
            {label}
          </Button>
        ))}
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={styles.gallery}>
          {media
            .filter(
              (item) =>
                status === FILTER_MEDIA_TAGS[0].key || item.status === status,
            )
            .map((item) => (
              <Media media={item} key={item.ofMediaId} />
            ))}
        </Box>
      )}
    </>
  );
};

export default History;
