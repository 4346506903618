import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { IEmojiProviderContext, EmojiType } from './interfaces';
import { Emojis } from './constants';

const DefaultContext = {
  activeEmojis: [],
  setActiveEmojis: (emojis: EmojiType[]) => {},
  isHotbarModalOpen: false,
  setIsHotbarModalOpen: () => {},
  isEmojiModalOpen: false,
  setIsEmojiModalOpen: () => {},
  emojiItem: null,
  setEmojiItem: () => {},
};

export const ContentContext =
  React.createContext<IEmojiProviderContext>(DefaultContext);

export const useEmojiProviderContext = () => React.useContext(ContentContext);

export const EmojiProvider = ({ children }: { children: ReactNode }) => {
  const [activeEmojis, setActiveEmojis] = useState<EmojiType[]>([]);

  const [isHotbarModalOpen, setIsHotbarModalOpen] = useState(false);
  const [isEmojiModalOpen, setIsEmojiModalOpen] = useState(false);

  const [emojiItem, setEmojiItem] = useState<EmojiType | null>(null);

  useEffect(() => {
    const hotbar = localStorage.getItem('hotbar');

    setActiveEmojis((hotbar ? JSON.parse(hotbar) : Emojis) as EmojiType[]);
  }, []);

  const handleUpdateActiveEmojis = useCallback((emojis: EmojiType[]) => {
    setActiveEmojis(emojis);
    localStorage.setItem('hotbar', JSON.stringify(emojis));
  }, []);

  return (
    <ContentContext.Provider
      value={{
        activeEmojis,
        setActiveEmojis: handleUpdateActiveEmojis,
        isHotbarModalOpen,
        setIsHotbarModalOpen,
        isEmojiModalOpen,
        setIsEmojiModalOpen,
        emojiItem,
        setEmojiItem,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
