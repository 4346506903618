import { useCallback } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { EmojiClickData } from 'emoji-picker-react/dist/types/exposedTypes';

import { useEmojiProviderContext } from '../../../providers';

import Modal from '../../OFSidebar/components/Modal';

const ModalEmojiPicker = () => {
  const {
    isEmojiModalOpen: isOpen,
    setIsEmojiModalOpen,
    setEmojiItem,
  } = useEmojiProviderContext();

  const handleClose = useCallback(() => {
    setIsEmojiModalOpen(false);
  }, []);

  const handleEmojiClick = useCallback((emoji: EmojiClickData) => {
    setEmojiItem(emoji);
    handleClose();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      sx={{ paddingTop: '60px' }}
    >
      <EmojiPicker onEmojiClick={handleEmojiClick} />
    </Modal>
  );
};

export default ModalEmojiPicker;
