import { useCallback, useEffect } from 'react';
import { useAppSelector } from '../../../../../store';
import { getCreatorsList } from '../../../../../store/reducers/creators';
import { useElectronContext } from '../../../providers/ElectronProvider';
import { useChatterCharms } from '../../../../../providers/ChatterCharmsProvider';

interface IProxy {
  port: string;
  host: string;
  username: string;
  password: string;
}

const useProxy = ({ isReady }: { isReady: boolean }) => {
  const { creators: items } = useChatterCharms();
  const { activeCreator, activateLoginCreatorId } = useElectronContext();

  const handleSetProxy = useCallback(
    ({ port, host, username, password }: IProxy) => {
      window.electronAPI.send('set-proxy', {
        payload: {
          proxyRules: `http://${host}:${port}`,
          username,
          password,
        },
      });
    },
    [],
  );

  useEffect(() => {
    //If active creator
    if (!isReady || !activeCreator || !activeCreator?.proxy) {
      return;
    }

    handleSetProxy(activeCreator.proxy);
    //console.log('!!!!!!!send-proxy active creator!!!!!!');

    return () => {};
  }, [isReady, activeCreator, handleSetProxy]);

  useEffect(() => {
    //If activateLoginCreatorId(first login)
    if (!isReady || !activateLoginCreatorId) {
      return;
    }

    const creator = items.find((item) => item.id === activateLoginCreatorId);

    if (!creator || !creator?.proxy) {
      return;
    }

    handleSetProxy(creator.proxy);
    //console.log('!!!!!!!send-proxy activateLoginCreatorIdr!!!!!!');

    return () => {};
  }, [isReady, activateLoginCreatorId, handleSetProxy, items]);
};

export default useProxy;
