
import { useEffect } from "react";
import { useAppDispatch } from "store";
import { Billing } from "components/Billing/Billing";
import { loadCurrentBalance } from "store/reducers/billing";


const BillingPage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadCurrentBalance());
    }, [dispatch]);

    return <Billing />;
}

export default BillingPage;