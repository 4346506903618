import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { Country, ICountry } from 'country-state-city';
import { DateInputPicker } from '../../../../../../../../components/fields/DatePicker/DatePicker';

import Select from '../../../../../../../../components/fields/Select/Select';
import { convertToDropdownItem } from '../../../../../../../../utils/convert';
import { useElectronContext } from '../../../../../../providers/ElectronProvider';

import { scrapingMetadataService } from '../../../../../../../../services/ScrapingMetadataService';
import { IScrapinMetadata } from '../../../../../../../../models/scraping';

import styles from './MainAccountForm.module.scss';

const MainAccountForm = ({
  birthday,
  location,
}: {
  birthday: string;
  location: string;
}) => {
  const { activeCreator, activeChatterId } = useElectronContext();

  const handleUpdateMetadata = useCallback(
    async (data: IScrapinMetadata) => {
      if (!activeCreator?.id || !activeChatterId) {
        return;
      }

      try {
        await scrapingMetadataService.updateMetadata(
          activeCreator?.id,
          activeChatterId,
          data,
        );
      } catch (e) {
        console.log('Can not update metadata: ', e);
      }
    },
    [activeCreator?.id, activeChatterId],
  );

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: { birthday: birthday, location: location },
    onSubmit: async (values) => {
      await handleUpdateMetadata(values);
    },
    enableReinitialize: true,
  });

  const countryOptions = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        ...convertToDropdownItem(country.name),
        ...country,
      })),
    [],
  );

  const onChangeData = useCallback(
    async (value: any, targetName?: string) => {
      if (!targetName) {
        return;
      }

      handleUpdateMetadata({ ...values, [targetName]: value });
      setFieldValue(targetName, value);
    },
    [setFieldValue, values],
  );

  return (
    <div className={styles.box}>
      <DateInputPicker
        name="birthday"
        fieldName="Birthday"
        value={values.birthday ? moment.utc(values.birthday) : null}
        onChangeValue={onChangeData}
        maxDate={moment.utc().startOf('day')}
        className={styles.birthday}
      />
      <Select
        name="location"
        fieldName="Counry/Region"
        placeholder="Unknown"
        value={values.location ? convertToDropdownItem(values.location) : null}
        options={countryOptions}
        onChange={(value, actionMeta) => {
          const targetName = actionMeta.name;
          const updatedValue = value as ICountry;
          onChangeData(updatedValue.name, targetName!);
        }}
        onInputChange={(newValue, actionMeta) => {
          onChangeData(newValue, 'location');
        }}
        className={styles.location}
      />
    </div>
  );
};

export default MainAccountForm;
