export const timeZoneList = [
    {label: "GMT-12:00", value: "GMT-12:00"},
    {label: "GMT-11:00", value: "GMT-11:00"},
    {label: "GMT-10:00", value: "GMT-10:00"},
    {label: "GMT-09:00", value: "GMT-09:00"},
    {label: "GMT-08:00", value: "GMT-08:00"},
    {label: "GMT-07:00", value: "GMT-07:00"},
    {label: "GMT-06:00", value: "GMT-06:00"},
    {label: "GMT-05:00", value: "GMT-05:00"},
    {label: "GMT-04:00", value: "GMT-04:00"},
    {label: "GMT-03:00", value: "GMT-03:00"},
    {label: "GMT-02:00", value: "GMT-02:00"},
    {label: "GMT-01:00", value: "GMT-01:00"},
    {label: "GMT00:00", value: "GMT00:00"},
    {label: "GMT+01:00", value: "GMT+01:00"},
    {label: "GMT+02:00", value: "GMT+02:00"},
    {label: "GMT+03:00", value: "GMT+03:00"},
    {label: "GMT+04:00", value: "GMT+04:00"},
    {label: "GMT+05:00", value: "GMT+05:00"},
    {label: "GMT+06:00", value: "GMT+06:00"},
    {label: "GMT+07:00", value: "GMT+07:00"},
    {label: "GMT+08:00", value: "GMT+08:00"},
    {label: "GMT+09:00", value: "GMT+09:00"},
    {label: "GMT+10:00", value: "GMT+10:00"},
    {label: "GMT+11:00", value: "GMT+11:00"},
    {label: "GMT+12:00", value: "GMT+12:00"},
];