import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: (color: string, active: boolean) => ({
      borderRadius: '10px',
      border: '1px solid #AEAEAE',
      borderColor: active && color ? `${color}` : '#AEAEAE',
      background: active && color ? `${color}59` : '#F3F4F6',

      color: active && color ? `${color}` : '#555',
      fontSize: '19px',
      fontWeight: 400,
      padding: '8px 10px',
      textTransform: 'none',
      letterSpacing: '0.002px',

      [breakpoints.down(ScreenSizes.screenXXl)]: {
        borderRadius: '7px',
        fontSize: '14px',
        padding: '6px 9px',
      },

      [breakpoints.down(ScreenSizes.screenXl)]: {
        borderRadius: '5px',
        fontSize: '10px',
        padding: '4px 8px',
      },

      '&:hover': {
        color: color ?? '#C64802',
        border: `1px solid ${color ?? '#C64802'}`,
        background: color ? `${color}59` : 'rgba(240, 101, 0, 0.33)',
      },
    }),
  };
};

export default useStyles;
