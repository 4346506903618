import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useAppSelector } from '../store';
import { getRolesList } from '../store/reducers/roles';
import { getAuthInited, getUserAgency, getUserAuthData } from '../store/reducers/auth';

const usePermissions = () => {
  const { items: roles } = useAppSelector(getRolesList);
  const authUser = useAppSelector(getUserAuthData);
  const agency = useAppSelector(getUserAgency);
  const [userPermissions, setUserPermissions] = useState<string[] | null>(null);
  const [isSuperAdminPermission, setIsSuperAdminPermission] = useState<boolean>(false);
  const [superAdminId, setSuperAdminId] = useState<string>("");
  // Variable necessary when initializing the application, for correct redirection according to permissions (see AuthProvider logic)
  // [TODO]: rewrite the logic instead of useEffect to useMemo in order to process permissions immediately, and not after mounting (useEffect) the application
  const permissionMounted = useRef<boolean>(false);

  useEffect(() => {
    if (!permissionMounted.current) permissionMounted.current = true;

    if (!authUser?.roleId || !agency?.ownerId) return;

    const role = roles.find((role) => role.id === authUser.roleId);
    const isSuperAdmin = agency.ownerId === authUser.id;

    setSuperAdminId(agency.ownerId); // agency ownerID = adminId
    setIsSuperAdminPermission(isSuperAdmin);

    if (!role) {
      setUserPermissions(null);
      return;
    }

    setUserPermissions(role.permissions);

  }, [authUser, agency, roles]);

  return { userPermissions, isSuperAdminPermission, superAdminId, permissionMounted: permissionMounted.current };
};

export default usePermissions;
