import React, { useState, useEffect } from "react";

const useFetch = (loadData: any, options: any = {}) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const axiosAbortControllerRef = React.useRef<any>();
  const loadDataPromiseRef = React.useRef<any>();

  const callLoadData = React.useCallback(
    (params: any = {}) => {
      if (loadDataPromiseRef.current && axiosAbortControllerRef.current) {
        axiosAbortControllerRef.current.abort();
      }

      setLoading(true);

      axiosAbortControllerRef.current = new AbortController();

      loadDataPromiseRef.current = loadData(params, {
        signal: axiosAbortControllerRef.current
          ? axiosAbortControllerRef.current.signal
          : null,
      })
        .then((data: any) => {
          loadDataPromiseRef.current = null;
          setData(data !== undefined ? data : []);

          setLoading(false);
        })
        .catch(() => {
          loadDataPromiseRef.current = null;
          setLoading(false);
        });
    },
    [loadData]
  );

  useEffect(() => {
    return () => {
      axiosAbortControllerRef?.current?.abort();
    };
  }, [callLoadData]);

  return { data, loading, error, callLoadData };
};

export default useFetch;
