import React from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, ThemeButton } from "components/Button/Button";
import variables from "assets/styles/variables.module.scss";

interface IConfirmationDialog {
    message: string;
    title: string;
    onConfirm: any;
    onClose: any;
    cancelLabel?: string;
    confirmLabel?: string;
    children?: any;
    fullWidth?: boolean;
}

const ConfirmationDialog = (props: IConfirmationDialog) => {
    const {
        message,
        title,
        onConfirm,
        onClose,
        cancelLabel = "No",
        confirmLabel = "Yes",
        children,
        fullWidth,
    } = props;

    const [confirmationLoading, setConfirmationLoading] = React.useState(false);

    const handleConfirm = async (e: any) => {
        e.stopPropagation();
        setConfirmationLoading(true);
        await onConfirm();
        setConfirmationLoading(false);
        onClose();
    };

    const handleClose = (e: any) => {
        e.stopPropagation();
        onClose();
    };

    return (
        <Dialog
            open={true} 
            onClose={handleClose} 
            fullWidth={fullWidth} 
            PaperProps={{ sx: { bgcolor: variables.secondaryColor, color: variables.textColor } }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ mt: 1, mb: 1 }}>
                <DialogContentText align="left" sx={{ color: variables.textColor }}>{message}</DialogContentText>
                {children}
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={handleClose} theme={ThemeButton.DARK_OUTLINE}>
                    {cancelLabel}
                </Button>

                <Button
                    onClick={handleConfirm}
                    disabled={confirmationLoading}
                    color="primary"
                    theme={ThemeButton.PRIMARY}
                    loading={confirmationLoading}
                >
                    {confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
