export enum AccountsTableColumns {
    CREATOR,
    SUBS_PRICE,
    SESSION_STATUS,
    AI_STATUS,
    ACTIONS
}

export enum CreatorsSessionStatus {
    INACTIVE = 'Inactive',
    ACTIVE = 'Active',
    NOT_VERIFIED = 'NotVerified'
}

export interface CreatorsConfig {
    name: string;
    className: string;
}

export const creatorsSessionConfig: Record<CreatorsSessionStatus, CreatorsConfig> = {
    [CreatorsSessionStatus.INACTIVE]: {
        name: "Inactive",
        className: "inactive"
    },
    [CreatorsSessionStatus.ACTIVE]: {
        name: "Active",
        className: "active"
    },
    [CreatorsSessionStatus.NOT_VERIFIED]: {
        name: "Not Verified",
        className: "notVerified"
    },
}

export enum CreatorsActions {
    RECONNECT = "Reconnect",
    OPEN_OF = "Open OF"
}

export enum ConnectCreatorStep {
    REQUEST = "REQUEST",
    PROXY = "PROXY",
    BASIC_INFO = "BASIC_INFO",
    ABOUT = "ABOUT",
    SUCCESS = "SUCCESS",
}