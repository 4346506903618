import { memo, useEffect, useState } from 'react';
import { StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';
import { Skeleton } from '@mui/material';
import variables from 'assets/styles/variables.module.scss';

import Chart, { Props } from 'react-apexcharts';
import moment from 'moment';

const initialOptions: Props = {
  options: {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: '#474B56',
      },
    },
    yaxis: {
      //@ts-ignore
      stepSize: 50,
      min: 0,
      labels: {
        style: {
          colors: '#A3ADB1',
        },
      },
    },
    stroke: {
      width: 3,
      colors: [variables.accentColor],
      curve: 'smooth',
    },
    markers: {
      colors: [variables.accentColor],
    },
    tooltip: {
      marker: {
        show: false,
      },
    },
    grid: {
      borderColor: '#474B56',
    },
    fill: {
      type: 'solid',
      opacity: [0.1, 1],
    },
  },
  series: [
    {
      name: 'Income',
      type: 'area',
      data: [],
    },
  ],
};

interface LineChartProps {
  name: string;
  filters: StatisticFilters;
  className?: string;
}

const LineChart = ({ name, filters, className }: LineChartProps) => {
  const [loading, setLoading] = useState(false);

  const [settings, setSettings] = useState(initialOptions);

  const formatYAxis = (value: number, roundedValues: number[]) => {
    // Находим ближайшее округленное значение к текущему значению
    const roundedValue = roundedValues.reduce((prev, curr) => (
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    ));
    
    return `$ ${roundedValue}`;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await statisticsService.getCreatorEarning(filters);
        let updatedSeries = data?.map((item) => Number(item.sum));
        let updatedCategories = data?.map((item) => moment(item.value).format("YYYY-MM-DD, HH:mm"));
        const commonSum = updatedSeries?.reduce(
          (sum, value) => sum + (value || 0),
          0,
        );
        const roundedValues = [...new Set(updatedSeries.map(sValue => Math.round(sValue)))];
        // To show line on chart need 2 points
        const isSingeValue = updatedSeries.length === 1;

        if (isSingeValue) {
          updatedSeries.push(updatedSeries[0]);
          updatedCategories.push(updatedCategories[0]);
        }

        const deepUpdatedSettings = JSON.parse(JSON.stringify(initialOptions));

        deepUpdatedSettings.options.xaxis.categories = updatedCategories;
        deepUpdatedSettings.series[0].data = updatedSeries;
        deepUpdatedSettings.options.yaxis.labels.formatter = (
          val: number,
        ): string => {
          return  `$ ${Number(val)?.toFixed()}`;
        };

        /*deepUpdatedSettings.options.yaxis.tickAmount = roundedValues.length - 1 > 10 ? 10 : roundedValues.length;

        deepUpdatedSettings.options.tooltip = {
          marker: {
            show: false,
          },
          y: {
            formatter: (value: number) => `$ ${Math.round(Number(value))}`
          }
        };*/

        (!commonSum || isSingeValue) &&
          (deepUpdatedSettings.options.yaxis.tickAmount = 4);

        setSettings(deepUpdatedSettings);
      } catch { }

      setLoading(false);
    })();
  }, [filters]);

  if (loading) return <Skeleton height={400} />;

  return (
    <Chart
      options={settings.options}
      series={settings.series}
      height={400}
      type="line"
    />
  );
};

export default memo(LineChart);
