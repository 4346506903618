import React, { useCallback } from 'react';
import { Input as BaseInput } from '@mui/material';

import useStyles from './useStyles';

interface IInputProps {
  value?: string | number;
  onChange?: (value: string, targetName?: string) => void;
}

const Input = ({ value = '', onChange }: IInputProps) => {
  const styles = useStyles();

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
        onChange?.(e.target.value, e.target.name);
      }
    },
    [],
  );

  return (
    <BaseInput
      type={'text'}
      value={value}
      onChange={onChangeValue}
      sx={styles.root}
    />
  );
};

export default Input;
