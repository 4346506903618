import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AboutStep.module.scss';
import Input from 'components/fields/Input/Input';
import { Button, ThemeButton } from 'components/Button/Button';
import Select from 'components/fields/Select/Select';
import { ChipArea } from 'components/fields/ChipArea/ChipArea';
import { ICreators, ICreatorsDictionary } from 'models/creators';
import { convertToDropdownArray, convertToDropdownItem } from 'utils/convert';
import { useAppSelector } from 'store';
import { getModalLoading } from 'store/reducers/modal';
import { connectCreatorSchema } from 'utils/validators';
import { yupToFormErrors } from 'formik';
import { IValidateFlags } from 'models/validation';
import { IDropdownOption } from 'models/fields';

type AboutDataType =  Pick<ICreators, | "conversationalStyle" | "fetish">;

interface AboutStepProps {
    data: AboutDataType;
    onSubmit: () => void;
    onChangeData: (value: any, targetName?: string) => void;
    dictionary: ICreatorsDictionary;
}

export const AboutStep = memo(({ data, onSubmit, onChangeData, dictionary }: AboutStepProps) => {
    const [validateErrors, setValidateErrors] = useState<IValidateFlags<AboutDataType>>({});

    const loading = useAppSelector(getModalLoading);

    useEffect(() => {
        setValidateErrors({});
    }, [data]);
    
    const { conversationalStyle, fetish } = data;

    const [ conversationalStyleOptions, fetishOptions] = useMemo(() => {
        const { conversationalStyle, fetish } = dictionary;

        return [
            convertToDropdownArray(conversationalStyle),
            convertToDropdownArray(fetish),
        ]
    }, [dictionary]);

    const onClickConnectCreator = useCallback(async () => {
        try {
            await connectCreatorSchema.aboutStep.validate({ ...data }, { abortEarly: false });
            onSubmit();
        } catch (errors) {
            setValidateErrors(yupToFormErrors(errors));
        }

    }, [data, onSubmit]);

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>About</h2>
            <section className={styles.form}>
                {/*<Select
                    name="bodyType"
                    fieldName="Body Type"
                    placeholder="Select body type"
                    value={convertToDropdownItem(bodyType)}
                    onChange={(value, actionMeta) => {
                        const targetName = actionMeta.name;
                        const updatedValue = value as IDropdownOption;
                        onChangeData(updatedValue.value, targetName!);
                    }}
                    options={bodyTypeOptions}
                    className={styles.field}
                    isDisabled={loading}
                    error={validateErrors.bodyType}
                />*/}
                <Select
                    name="conversationalStyle"
                    fieldName='Conversational style'
                    placeholder="Choose your style"
                    value={convertToDropdownItem(conversationalStyle)}
                    onChange={(value, actionMeta) => {
                        const targetName = actionMeta.name;
                        const updatedValue = value as IDropdownOption;
                        onChangeData(updatedValue.value, targetName!);
                    }}
                    options={conversationalStyleOptions}
                    className={styles.field}
                    isDisabled={loading}
                    error={validateErrors.conversationalStyle}
                />
                {/*<Input
                    name="height"
                    fieldName='Height'
                    placeholder="160 ft"
                    value={height}
                    onChange={onChangeData}
                    className={styles.field}
                    disabled={loading}
                    error={validateErrors.height}
                />
                <Input
                    name="bustSize"
                    fieldName='Bust size'
                    placeholder="75C"
                    value={bustSize}
                    onChange={onChangeData}
                    className={styles.field}
                    disabled={loading}
                    error={validateErrors.bustSize}
                />*/}
                <ChipArea
                    name="fetish"
                    fieldName='Select fetish'
                    value={convertToDropdownArray(fetish as string[]) || []}
                    onChange={(value, targetName) => onChangeData((value as IDropdownOption[]).map(item => item.value), targetName)}
                    options={fetishOptions}
                    disabled={loading}
                    multiple={true}
                    error={validateErrors.fetish}
                />
            </section>
            <div className={styles.actions}>
                <Button 
                    onClick={onClickConnectCreator} 
                    theme={ThemeButton.PRIMARY} 
                    loading={loading}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
});
