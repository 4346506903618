import { useCallback, useEffect, useMemo, useState } from 'react';
import { yupToFormErrors } from 'formik';

import Input from '../../fields/Input/Input';
import Select from '../../fields/Select/Select';
import { Button, ThemeButton } from '../../Button/Button';

import { timeZoneList } from '../../../constants/timeZones';

import { compareStrings } from '../helpers/common';

import { ownerSettingsFormSchema } from '../../../utils/validators';

import { IValidateFlags } from '../../../models/validation';
import { IDropdownOption, ThemeField } from '../../../models/fields';

import { useAppDispatch, useAppSelector } from '../../../store';
import { getUserAgency, updateDataAgency } from '../../../store/reducers/auth';

import { AgencyType } from '../../../models/auth';

import { ModalType } from '../../../constants/modal';
import { modalActions } from '../../../store/reducers/modal';

import styles from './OwnerSettingsForm.module.scss';

type ValidateDataType = {
  agencyName: string;
  timezone: string
}

export const OwnerSettingsForm = () => {
  const dispatch = useAppDispatch();
  const agency = useAppSelector(getUserAgency);

  const [agencyName, setAgencyName] = useState('');
  const [timezone, setTimezone] = useState('');

  const [loading, setLoading] = useState(false);

  const [validateErrors, setValidateErrors] = useState<IValidateFlags<ValidateDataType>>({});

  const isSolo =  useMemo(() => agency?.type === AgencyType.SOLO, [agency]);

  const zoneSelectValue = useMemo(() => (
    timeZoneList.find(item => item.label === timezone && item.value === agency?.zone)
  ), [timezone, agency]);

  const isEqual = useMemo(() => (
    isSolo
      ? !!(agency?.zone && compareStrings(timezone, agency?.zone))
      : (
        !!(agency?.name && compareStrings(agencyName, agency?.name)) &&
        !!(agency?.zone && compareStrings(timezone, agency?.zone))
      )
  ), [agency, agencyName, timezone, isSolo])


  const onSubmit = useCallback( async () => {
    if (!agency) return;
    setLoading(true);
    setValidateErrors({});

    try {
      const validateSchema = isSolo ? ownerSettingsFormSchema.isSolo : ownerSettingsFormSchema.isAgency;
      await validateSchema.validate({ agencyName, timezone }, { abortEarly: false });

      const body = {
        name: isSolo ? agency.name || '' : agencyName,
        zone: timezone
      }

      const response = await dispatch(updateDataAgency(body));

      if (response.meta.requestStatus === "fulfilled") {
         dispatch(modalActions.setModalType({ type: ModalType.SUCCESSFULLY_UPDATED }));
      }
    }  catch (errors) {
      setValidateErrors(yupToFormErrors(errors));
    }
    setLoading(false);
  }, [agency, agencyName, timezone]);


  useEffect(() => {
    if (!agency || agencyName || timezone) return;

    if ((!agencyName || !timezone)) {
      setAgencyName(agency?.name || '')
      setTimezone(agency?.zone || '')
    }
  }, [agency]);

  return (
    <form className={styles.form}>
      {!isSolo && (
        <Input
          name='agencyName'
          placeholder='**********'
          theme={ThemeField.DARK}
          readonly={loading}
          value={agencyName}
          onChange={setAgencyName}
          fieldName='Agency name'
          className={styles.input}
          error={validateErrors.agencyName}
        />
      )}

      <Select
        name='timezone'
        fieldName='Timezone'
        placeholder='Ex. (GMT -9:00)'
        theme={ThemeField.DARK}
        options={timeZoneList}
        className={styles.select}
        isDisabled={loading}
        value={zoneSelectValue}
        onChange={(value) => {
          const updatedValue = value as IDropdownOption;
          setTimezone(updatedValue.value as string);
        }}
        error={validateErrors.timezone}
      />
      <Button
        onClick={onSubmit}
        theme={ThemeButton.PRIMARY}
        className={styles.button}
        loading={loading}
        disabled={isEqual}
      >
        Update
      </Button>
    </form>
  );
};
