import React, { useMemo } from 'react';

import Card from '../Card/Card';
import { Status } from './Status/Status';
import { Button, ThemeButton } from '../../Button/Button';

import { useAppDispatch } from '../../../store';

import { ReactComponent as SettingsIcon } from "assets/images/editAccount/settings.svg";

import styles from './ProxySettings.module.scss';
import { ICreators } from '../../../models/creators';
import { modalActions } from 'store/reducers/modal';
import { ModalType } from 'constants/modal';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';


interface Props {
    data: ICreators
}

export const ProxySettings = ({ data }: Props) => {
    const dispatch = useAppDispatch();
    const isActive = useMemo(() => !!(data?.proxy), [data]);
    const tooltipTitle = "You can change the proxy once a month. If you have any problems, please contact support.";

    const onEditProxy = () => {
        dispatch(modalActions.setModalType({ type: ModalType.EDIT_PROXY }))
    }

    return (
        <Card title={(
            <div className={styles.titleBox}>
                <span>Proxy settings</span>
                <Status isActive={isActive} />

                {!data?.changeProxy && (
                    <Tooltip
                        className={styles.tooltip}
                        placement="top"
                        title={tooltipTitle}
                    >
                        <InfoIcon className={styles.info} />
                    </Tooltip>
                )}
            </div>
        )}
            icon={<SettingsIcon />}
        >

            <div className={styles.countryBox}>
                <span className={styles.proxyCountry}>Proxy Country:</span>
                <span className={styles.country}>{data?.proxyCountry}</span>
            </div>

            <Button
                theme={ThemeButton.PRIMARY}
                className={styles.btn}
                onClick={onEditProxy}
                disabled={!data?.changeProxy}
            >
                Edit
            </Button>
        </Card>
    )
}
