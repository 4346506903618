import React, { useEffect } from 'react';

import { useElectronContext } from '../../../providers/ElectronProvider';

import { useAppDispatch } from '../../../../../store';
import { creatorsService } from '../../../../../services/CreatorsService';
import { loadCreatorsList } from '../../../../../store/reducers/creators';

const useCreatorLogout = ({
  ref,
}: {
  ref: React.MutableRefObject<any | null>;
}) => {
  const dispatch = useAppDispatch();
  const { activeCreator } = useElectronContext();

  useEffect(() => {
    if (!activeCreator || !window.electronAPI) {
      return;
    }

    const eventName = 'logout';

    const cb = async () => {
      try {
        const { sessionId } = await creatorsService.getCreatorSessionUrl(
          activeCreator.id!,
        );

        if (sessionId) {
          await creatorsService.getDeactivateCreatorSessionUrl(
            activeCreator.id!,
            {
              sessionId,
            },
          );
          dispatch(loadCreatorsList());
        }
      } catch (e) {}
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [activeCreator]);
};

export default useCreatorLogout;
