import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import { RoutePath } from "config/routeConfig";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { getUserAuthData } from "store/reducers/auth";
import ModalWindow from "components/Modal/Modal";

import usePermissions from "hooks/usePermissions";
import { getClosestSidebarPathIntersection } from "constants/sidebar";

interface IAuthProvider {
    children: React.ReactNode;
    pagePermissions?: string[] | null;
}

export const AuthProvider = ({ children, pagePermissions }: IAuthProvider) => {
    const isAuth = useAppSelector(getUserAuthData);
    const location = useLocation();

    const { userPermissions, isSuperAdminPermission, permissionMounted } = usePermissions();

    if (!isAuth) return <Navigate to={RoutePath.signIn} state={{ from: location }} replace />;

    if (!isSuperAdminPermission && permissionMounted) {
        // Check if current page available by permissions
        const intersection = pagePermissions?.filter((permission) => userPermissions?.includes(permission));

        if (!intersection?.length && location.pathname !== RoutePath.settings) {
            // if current page unavailable by permission, redirect to closest possible page (on sidebar) OR settings (if not intersections)
            const path = getClosestSidebarPathIntersection(userPermissions);
            return <Navigate to={path} state={{ from: location }} replace />;
        }
    }

    return (
        <>
            <Sidebar />
            <main className="page-wrapper">
                <Header />
                {children}
            </main>
        </>
    );
};
