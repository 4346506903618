import { memo, useCallback, useEffect } from "react";

import { createBrowserHistory } from "history";
import { AppRouteProps, routeConfig } from "config/routeConfig";

import { AuthProvider } from "./AuthProvider";
import { Routes, Route } from "react-router-dom";

export const history = createBrowserHistory();

const RouterProvider = (): JSX.Element => {
    const renderWithWrapper = useCallback((route: AppRouteProps) => {
        return (
            <Route
                key={route.path}
                path={route.path}
                element={
                    route.authOnly ? (
                        <AuthProvider pagePermissions={route?.permissions ?? null}>
                            {route.element}
                        </AuthProvider>
                    ) : (
                        route.element
                    )
                }
            />
        );
    }, []);

    return <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>;
};

export default memo(RouterProvider);
