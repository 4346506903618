import React from 'react';

import {
  ElectronProvider,
  OFNotificationProvider,
  VaultProvider,
  EmojiProvider,
} from '../../providers';
import { isElectron } from '../../helpers';
import ElectronContainerView from './ElectronContainerView';
import { useAppSelector } from '../../../../store';
import { getUserAuthData } from '../../../../store/reducers/auth';

interface IElectronContainer {
  children: React.ReactNode;
}

const ElectronContainer = ({ children }: IElectronContainer) => {
  const isAuth = useAppSelector(getUserAuthData);

  if (!isElectron || !isAuth) {
    return <>{children}</>;
  }

  return (
    <ElectronProvider>
      <OFNotificationProvider>
        <VaultProvider>
          <EmojiProvider>
            <ElectronContainerView>{children}</ElectronContainerView>
          </EmojiProvider>
        </VaultProvider>
      </OFNotificationProvider>
    </ElectronProvider>
  );
};

export default ElectronContainer;
