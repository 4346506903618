import React, { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Accounts.module.scss';
import { classNames } from 'utils/classNames';
import { Button, ThemeButton } from 'components/Button/Button';
import CustomizedTable from 'components/CustomizedTable/CustomizedTable';
import { IColumn } from 'models/table';
import {
  AccountsTableColumns,
  CreatorsActions,
  CreatorsSessionStatus,
  creatorsSessionConfig,
} from 'constants/creators';
import { modalActions } from 'store/reducers/modal';
import { ModalType } from 'constants/modal';
import { useAppDispatch, useAppSelector } from 'store';
import { getCreatorsInited, getCreatorsLoading } from 'store/reducers/creators';
import { ICreators, NOT_SET_PROXY, SubscriptionStatus } from 'models/creators';
import { ONLYFANS_URL } from 'constants/externalLinks';
import { creatorsService } from 'services/CreatorsService';
import { useElectronContext } from '../../modules/electron/providers/ElectronProvider';
import { useChatterCharms } from '../../providers/ChatterCharmsProvider';
import { RoutePath } from '../../config/routeConfig';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';

const columns: IColumn[] = [
  {
    id: AccountsTableColumns.CREATOR,
    fieldName: 'creator',
    label: 'Creator',
    width: 300,
  },
  {
    id: AccountsTableColumns.SUBS_PRICE,
    fieldName: 'subscriptionPrice',
    label: 'Subscription Price',
    width: 100,
  },
  {
    id: AccountsTableColumns.SESSION_STATUS,
    fieldName: 'sessionStatus',
    label: 'Session Status',
    width: 100,
  },
  {
    id: AccountsTableColumns.AI_STATUS,
    fieldName: 'training',
    label: 'AI Training Status',
    width: 150,
  },
  {
    id: AccountsTableColumns.ACTIONS,
    fieldName: 'actions',
    label: 'Actions',
    width: 400,
  },
];

interface AccountsProps {
  className?: string;
}

const Accounts = memo(({ className }: AccountsProps) => {
  const navigate = useNavigate();

  const loading = useAppSelector(getCreatorsLoading);
  const inited = useAppSelector(getCreatorsInited);
  const dispatch = useAppDispatch();

  const { creators: items } = useChatterCharms();

  const {
    setOfUrl,
    setActiveCreator,
    setActivateLoginCreatorId,
    setActivateLoginSessionId,
  } = useElectronContext();

  const [connectLoadingList, setConnectLoadingList] = useState<string[]>([]);

  const onEditAccount = useCallback((id: string) => {
    navigate(`${RoutePath.editAccount}?id=${id}`);
  }, []);

  const onClickActionButton = useCallback(async (creator: ICreators) => {
    const { sessionStatus, id } = creator;
    if (!id) {
      return;
    }

    if (
      sessionStatus === CreatorsSessionStatus.INACTIVE ||
      sessionStatus === CreatorsSessionStatus.NOT_VERIFIED
    ) {
      setConnectLoadingList((prev) => [...prev, id]);

      const { sessionId, creatorId } = await creatorsService.connectCreator(id);
      // const connectURL =
      //   ONLYFANS_URL +
      //   `?${qs.stringify({ creatorId, id: sessionId }, { encode: true })}`;
      setConnectLoadingList((prev) => prev.filter((item) => item !== id));
      //window.open(connectURL, '_blank');

      setOfUrl(ONLYFANS_URL);
      setActivateLoginCreatorId(creatorId);
      setActivateLoginSessionId(sessionId);
    } else {
      setActiveCreator?.(creator);
      setOfUrl(ONLYFANS_URL);

      //window.open(ONLYFANS_URL, '_blank');
    }
  }, []);

  const onRenderColumnItem = useCallback(
    (item: ICreators, index: number, column: IColumn) => {
      const fieldName = column?.fieldName;

      switch (column?.id) {
        case AccountsTableColumns.CREATOR: {
          const { name, imageLink, ofNick, id } = item;
          const isManage = true;

          return (
            <div className={styles.creator}>
              <div className={styles.avatar}>
                {imageLink && <img src={imageLink} alt="Avatar" />}
              </div>
              <div className={styles.info}>
                <p>{name}</p>
                <p className={styles.secondary}>{`@${ofNick}`}</p>
                {isManage && (
                  <Button
                    onClick={() => id && onEditAccount(id)}
                    className={styles.manageBtn}
                    theme={ThemeButton.DARK_OUTLINE}
                  >
                    Manage
                  </Button>
                )}
              </div>
            </div>
          );
        }
        case AccountsTableColumns.SESSION_STATUS: {
          const { sessionStatus = CreatorsSessionStatus.INACTIVE } = item;
          const { name, className } = creatorsSessionConfig[sessionStatus];

          return (
            <span
              className={classNames(styles.sessionStatus, {}, [
                styles[className],
              ])}
            >
              {name}
            </span>
          );
        }
        case AccountsTableColumns.AI_STATUS: {
          const isActiveSubscription =
            item?.subscription?.status === SubscriptionStatus.PAID ||
            item?.subscription?.status === SubscriptionStatus.TRIAL;
          const label =
            item.sessionStatus === CreatorsSessionStatus.ACTIVE &&
            isActiveSubscription
              ? 'AI tranning'
              : 'AI not training';
          return (
            <p>
              {label} <span>{item.training?.status || 0}%</span>
            </p>
          );
        }
        case AccountsTableColumns.ACTIONS: {
          const {
            sessionStatus = CreatorsSessionStatus.NOT_VERIFIED,
            id,
            proxy,
            proxyId,
            subscription,
            name,
          } = item;

          const action =
            sessionStatus === CreatorsSessionStatus.INACTIVE
              ? CreatorsActions.RECONNECT
              : CreatorsActions.OPEN_OF;
          const isUnpaid = subscription?.status === SubscriptionStatus.UNPAID;
          const disabled = isUnpaid || !proxy || proxyId === NOT_SET_PROXY;

          const tooltipTitle = isUnpaid
            ? 'Your subscription has expired, go to billing to renew it'
            : 'Your proxy is not active.';

          return (
            <div className={styles.actions}>
              <Button
                onClick={() => onClickActionButton(item)}
                className={styles.ofButton}
                theme={ThemeButton.PRIMARY}
                loading={connectLoadingList.includes(id!)}
                disabled={disabled}
              >
                {action}
              </Button>
              {disabled && (
                <Tooltip
                  className={styles.tooltip}
                  placement="top"
                  title={tooltipTitle}
                >
                  <InfoIcon className={styles.info} />
                </Tooltip>
              )}
            </div>
          );
        }
        case AccountsTableColumns.SUBS_PRICE: {
          const { subscriptionPrice } = item;
          return <p>{`Paid ($${subscriptionPrice})`}</p>;
        }
        default: {
          if (fieldName && item) {
            return <p>{(item as any)[fieldName]}</p>;
          }
          return <></>;
        }
      }
    },
    [connectLoadingList, onClickActionButton],
  );

  const connectButton = useMemo(
    () => (
      <Button
        theme={ThemeButton.PRIMARY}
        className={styles.connectBtn}
        onClick={() =>
          dispatch(
            modalActions.setModalType({ type: ModalType.СONNECT_CREATOR }),
          )
        }
      >
        Connect Account
      </Button>
    ),
    [],
  );

  return (
    <div className={classNames(styles.container, {}, [className])}>
      <div className={styles.header}>
        <h1 className={styles.title}>Accounts</h1>
        {items.length > 0 && connectButton}
      </div>
      <div className={styles.content}>
        <CustomizedTable
          items={items}
          columns={columns}
          onRenderColumnItem={onRenderColumnItem}
          className={styles.table}
          loading={loading && !inited}
          emptyElement={connectButton}
        />
      </div>
    </div>
  );
});

export default Accounts;
