import React, { useCallback } from 'react';
import { v4 } from 'uuid';
import { Box, IconButton, Typography } from '@mui/material';

import { Bell, BellOff, Refresh } from '../../../../../icons';
import { useElectronContext } from '../../../../../providers/ElectronProvider';
import { useOFNotificationContext } from '../../../../../providers';
import MainAccountForm from './MainAccountForm/MainAccountForm';
import Loader from '../../Loader';
import { IMainAccountProps } from './interfaces';
import useStyles from './useStyles';
import { NotificationStatusEnum } from '../../../../../providers/OFNotificationProvider/interfaces';

const MainAccount = ({
  details,
  isLoading,
  updateChatterInfo,
}: IMainAccountProps) => {
  const styles = useStyles();

  const { activeChatterId, activeCreator } = useElectronContext();
  const { status, setTriggerFlag, isProcessing } = useOFNotificationContext();

  const handleBell = () => {
    setTriggerFlag(v4());
  };

  const handleRefresh = useCallback(() => {
    if (!activeChatterId || !activeCreator?.id) {
      return;
    }

    updateChatterInfo(activeCreator.id, activeChatterId);
  }, [activeCreator?.id, activeChatterId]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box sx={styles.root}>
        <Box sx={styles.main}>
          <Box sx={styles.image}>
            {details.avatar ? (
              <img src={details.avatar} alt={details.name} />
            ) : null}
          </Box>
          <div>
            <Typography sx={styles.title}>{details.name}</Typography>
            <Typography sx={styles.email}>@{details.username}</Typography>
          </div>
        </Box>
        <Box sx={styles.controls}>
          {status === NotificationStatusEnum.NONE ? null : (
            <IconButton
              onClick={handleBell}
              sx={styles.button}
              disabled={isProcessing}
            >
              {status === NotificationStatusEnum.ON ? <Bell /> : <BellOff />}
            </IconButton>
          )}

          <IconButton onClick={handleRefresh} sx={styles.button}>
            <Refresh />
          </IconButton>
        </Box>
      </Box>
      <MainAccountForm
        birthday={details.birthday}
        location={details.location}
      />
    </>
  );
};

export default MainAccount;
