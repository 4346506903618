import {
    AGENCIES_API,
    CONFIRM_PASSWORD_API,
    PROFILE_API,
    REFRESH_TOKEN_API,
    RESET_PASSWORD_API,
    SIGN_IN_API,
    SIGN_UP_API,
    SIGN_UP_LINK_API,
} from "config/api";
import { BaseService } from "./BaseService";
import { IAgencyFormData, IAuthTokenData, IConfirmPassword, ISignInRequest, ISignUpRequest } from "models/auth";
import { IMember } from "../models/members";

class AuthService extends BaseService {
    public async signUp(data: ISignUpRequest) {
        return this.post(SIGN_UP_API, data);
    }

    public async signUpLink(data: ISignUpRequest) {
        return this.post(SIGN_UP_LINK_API, data);
    }

    public async createAgency(data: IAgencyFormData) {
        const agencyData = {
            name: data.agencyName,
            zone: data.zone,
            type: data.type,
        };

        return this.post(AGENCIES_API, agencyData);
    }

    public async updateAgency(data : { agencyName: string, zone: string }) {
        const agencyData = {
            name: data.agencyName,
            zone: data.zone,
        };

        return this.patch(AGENCIES_API, agencyData);
    }

    public async getAgency(): Promise<{ agency: IAgencyFormData }> {

        return this.get(AGENCIES_API);
    }

    public async signIn(data: ISignInRequest): Promise<IAuthTokenData> {
        return this.post(SIGN_IN_API, data);
    }

    public async refreshToken(data: IAuthTokenData): Promise<Omit<IAuthTokenData, 'refreshToken'>> {
        return this.post(REFRESH_TOKEN_API, data);
    }

    public async getRoles() {
        return this.get(SIGN_IN_API);
    }

    public async getProfile(): Promise<{ profile: IMember }> {
        return this.get(PROFILE_API);
    }

    public async resetPassword(email: string): Promise<{ status: string }> {
        return this.post(RESET_PASSWORD_API, { email } );
    }

    public async confirmPassword(data: IConfirmPassword): Promise<{ status: string }> {
        return this.post(CONFIRM_PASSWORD_API, data);
    }
}

export const authService = new AuthService();
