import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import App from './components/App';
import ElectronContainer from './modules/electron/components/ElectronContainer/ElectronContainer';

import { StoreProvider } from 'providers/StoreProvider';
import { ChatterCharmsProvider } from './providers/ChatterCharmsProvider';

import './assets/styles/index.scss';
import 'react-day-picker/dist/style.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <StoreProvider>
        <ChatterCharmsProvider>
          <ElectronContainer>
            <App />
          </ElectronContainer>
        </ChatterCharmsProvider>
      </StoreProvider>
    </SnackbarProvider>
  </BrowserRouter>,
);
