import React, { memo, useMemo } from 'react';

import styles from './SidebarItem.module.scss';
import { classNames } from 'utils/classNames';
import { SidebarItemType } from 'constants/sidebar';
import { Link, useLocation } from 'react-router-dom';


interface SidebarItemProps {
    item: SidebarItemType;
    collapsed: boolean;
}

export const SidebarItem = memo(({ item, collapsed }: SidebarItemProps) => {
    const location = useLocation();

    const isActive = useMemo(() => location.pathname === item.path, [location.pathname, item]) 

    return (
        <div className={classNames(styles.item, { [styles.collapsed]: collapsed, [styles.active]: isActive }, [])}>
            <Link to={item.path} className={styles.link}>
                <item.Icon className={styles.icon} />
                <span>{item.text}</span>
            </Link>
        </div>
    );
});

