import React, {
  memo,
  type ButtonHTMLAttributes,
  type FC,
  useCallback,
} from "react";

import styles from "./Button.module.scss";
import { classNames } from "utils/classNames";
import { CircularProgress } from "@mui/material";

export enum ThemeButton {
  CLEAR = "clear",
  PRIMARY = "primary",
  OUTLINE = "outline",
  DARK_OUTLINE = "darkOutline"
}

export enum SizeButton {
  M = "size_m",
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  theme?: ThemeButton;
  size?: SizeButton;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
}

export const Button: FC<ButtonProps> = memo((props: ButtonProps) => {
  const {
    className,
    children,
    theme = ThemeButton.CLEAR,
    size = SizeButton.M,
    disabled,
    loading,
    onClick,
    ...otherProps
  } = props;

  const onClickCustomButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (loading) return;

      onClick?.(event);
    },
    [loading, onClick]
  );

  return (
    <button
      type="button"
      className={classNames(
        styles.button,
        {
          [styles.disabled]: disabled,
          [styles.loading]: loading,
        },
        [className, styles[theme], styles[size]]
      )}
      disabled={disabled}
      onClick={onClickCustomButton}
      {...otherProps}
    >
      <CircularProgress color="inherit" className={styles.loader} />
      <div className={styles.children}>{children}</div>
    </button>
  );
});
