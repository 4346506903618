import { useCallback, useEffect, useState, memo, useMemo } from 'react';

import styles from './ConnectCreator.module.scss';
import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import { classNames } from 'utils/classNames';
import { ConnectCreatorStep } from 'constants/creators';
import { RequestStep } from './RequestStep/RequestStep';
import { ProxyStep } from './ProxyStep/ProxyStep';
import { BasicInfoStep } from './BasicInfoStep/BasicInfoStep';
import { AboutStep } from './AboutStep/AboutStep';
import { useAppDispatch, useAppSelector } from 'store';
import {
  creatorsActions,
  getCreatorData,
  getCreatorsDictionary,
  loadCreatorsDictionary,
} from 'store/reducers/creators';
import { useFormik } from 'formik';
import { ICreators } from 'models/creators';
import { ModalConfig, getModalLoading, modalActions } from 'store/reducers/modal';
import { ONLYFANS_URL } from 'constants/externalLinks';
import { creatorsService } from 'services/CreatorsService';

import { useElectronContext } from '../../../modules/electron/providers/ElectronProvider';
import { ModalType } from 'constants/modal';

interface ConnectCreatorProps {
  config?: ModalConfig[ModalType.СONNECT_CREATOR]
  onClose?: () => void;
}

export const ConnectCreator = memo(({ config, onClose }: ConnectCreatorProps) => {
  const [activeStep, setActiveStep] = useState(ConnectCreatorStep.REQUEST);
  const dictionary = useAppSelector(getCreatorsDictionary);
  const loading = useAppSelector(getModalLoading);
  const data = useAppSelector(getCreatorData);

  const dispatch = useAppDispatch();

  const {
    setOfUrl,
    setActivateLoginCreatorId,
    setActivateLoginSessionId,
  } = useElectronContext();

  useEffect(() => {
    dispatch(loadCreatorsDictionary());
  }, []);

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: { ...data } as ICreators,
    onSubmit: async (values) => {
      dispatch(modalActions.setModalLoading(true));
      try {
        const requestData = values;
        const { creator } = await creatorsService.createCreators(requestData);

        if (creator?.id) {
          dispatch(creatorsActions.addItemToCreatorsList(creator));
          
          if (creator?.proxy) {
            const { sessionId, creatorId } = await creatorsService.connectCreator(creator.id);
            setOfUrl(ONLYFANS_URL);
            setActivateLoginCreatorId(creatorId);
            setActivateLoginSessionId(sessionId);
          }
          
          dispatch(modalActions.setModalType({ type: ModalType.SUCCESSFUL_INFO }));

        }
      } catch {}
      dispatch(modalActions.setModalLoading(false));
    },
    enableReinitialize: true,
  });

  const steps = useMemo(
    () => Object.keys(ConnectCreatorStep) as ConnectCreatorStep[],
    [],
  );
  const backButtonShown = useMemo(
    () => (activeStep !== steps[0] && activeStep !== steps[steps.length - 1]) && activeStep !== ConnectCreatorStep.PROXY,
    [activeStep, steps],
  );

  const onNextStep = useCallback(() => {
    const indexStep = steps.indexOf(activeStep);
    const nextStep = steps[indexStep + 1];

    if (!nextStep) return;
    setActiveStep(nextStep);
  }, [activeStep, steps]);

  const onBackStep = useCallback(() => {
    if (loading) return;

    const indexStep = steps.indexOf(activeStep);
    const prevStep = steps[indexStep - 1];

    if (!prevStep) return;
    setActiveStep(prevStep);
  }, [activeStep, steps, loading]);

  const onSubmit = useCallback(() => handleSubmit(), [handleSubmit]);

  const onChangeData = useCallback(
    (value: any, targetName?: string) => {
      targetName && setFieldValue(targetName, value);
    },
    [setFieldValue],
  );

  const render = (): JSX.Element | null => {
    switch (activeStep) {
      case ConnectCreatorStep.REQUEST: {
        const { ofLink } = values;
        return (
          <RequestStep
            data={{ ofLink }}
            onChangeData={onChangeData}
            onNextStep={onNextStep}
          />
        );
      }
      case ConnectCreatorStep.PROXY: {
        const { proxyCountry } = values;
        return (
          <ProxyStep
            data={{ proxyCountry }}
            dictionary={dictionary}
            onChangeData={onChangeData}
            onNextStep={onNextStep}
          />
        );
      }

      case ConnectCreatorStep.BASIC_INFO: {
        const { name, birthDate, country, city, notes, sex } = values;
        return (
          <BasicInfoStep
            data={{ name, birthDate, country, city, notes, sex }}
            dictionary={dictionary}
            onChangeData={onChangeData}
            onNextStep={onNextStep}
          />
        );
      }
      case ConnectCreatorStep.ABOUT: {
        const { conversationalStyle, fetish } = values;
        return (
          <AboutStep
            data={{ conversationalStyle, fetish }}
            onChangeData={onChangeData}
            onSubmit={onSubmit}
            dictionary={dictionary}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.header, {
          [styles.withoutBackBtn]: !backButtonShown,
        })}
      >
        <span onClick={onBackStep}><ArrowIcon /></span>
        <CloseIcon className={styles.close} onClick={onClose} />
      </div>

      {render()}
      {activeStep !== ConnectCreatorStep.SUCCESS && (
        <div className={styles.dots}>
          {steps.map((item, index) => (
            <div
              key={index}
              className={classNames(styles.dot, {
                [styles.active]: item === activeStep,
              })}
            />
          ))}
        </div>
      )}
    </div>
  );
});
