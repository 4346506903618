const BellOff = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.77278 11.4572L2.70323 11.9523L2.77278 11.4572ZM9.22722 11.4572L9.29677 11.9523L9.22722 11.4572ZM1.16978 8.53662L0.709266 8.34186L1.16978 8.53662ZM2.60938 4.88889C2.60938 3.01726 4.12663 1.5 5.99826 1.5V0.5C3.57435 0.5 1.60938 2.46497 1.60938 4.88889H2.60938ZM2.60938 6.05661V4.88889H1.60938V6.05661H2.60938ZM1.5 9.37764C1.5 9.14743 1.54655 8.92937 1.63029 8.73138L0.709266 8.34186C0.574352 8.66087 0.5 9.01121 0.5 9.37764H1.5ZM2.84233 10.9621C2.07025 10.8536 1.5 10.1813 1.5 9.37764H0.5C0.5 10.6556 1.41385 11.7712 2.70323 11.9523L2.84233 10.9621ZM6 11.2385C5.05743 11.2385 3.84089 11.1023 2.84233 10.9621L2.70323 11.9523C3.71025 12.0938 4.98413 12.2385 6 12.2385V11.2385ZM9.15767 10.9621C8.15911 11.1023 6.94257 11.2385 6 11.2385V12.2385C7.01587 12.2385 8.28975 12.0938 9.29677 11.9523L9.15767 10.9621ZM10.5 9.37764C10.5 10.1813 9.92975 10.8536 9.15767 10.9621L9.29677 11.9523C10.5862 11.7712 11.5 10.6556 11.5 9.37764H10.5ZM10.3694 8.73071C10.4533 8.92888 10.5 9.14717 10.5 9.37764H11.5C11.5 9.0108 11.4255 8.66008 11.2903 8.34079L10.3694 8.73071ZM9.38715 4.88889V6.05165H10.3872V4.88889H9.38715ZM5.99826 1.5C7.86989 1.5 9.38715 3.01726 9.38715 4.88889H10.3872C10.3872 2.46497 8.42218 0.5 5.99826 0.5V1.5ZM11.2903 8.34079C11.1957 8.11752 11.0858 7.90086 10.9808 7.69799C10.873 7.49002 10.7723 7.29996 10.6812 7.10677C10.4991 6.72067 10.3872 6.38397 10.3872 6.05165H9.38715C9.38715 6.60556 9.57375 7.10287 9.77667 7.53324C9.87806 7.74829 9.99137 7.96208 10.0928 8.15791C10.1969 8.35885 10.2912 8.54594 10.3694 8.73071L11.2903 8.34079ZM1.60938 6.05661C1.60938 6.38832 1.4978 6.72445 1.31639 7.1099C1.22562 7.30275 1.12518 7.49251 1.01782 7.70013C0.913087 7.90267 0.803528 8.11898 0.709266 8.34186L1.63029 8.73138C1.70831 8.54689 1.80234 8.36009 1.90609 8.15946C2.0072 7.96392 2.12013 7.75047 2.22119 7.53574C2.42344 7.10602 2.60938 6.60952 2.60938 6.05661H1.60938Z"
        fill="#00AFF0"
      />
      <path
        d="M7.38672 13.2422C7.08813 13.6991 6.57754 14.0002 5.99783 14.0002C5.41812 14.0002 4.90754 13.6991 4.60895 13.2422"
        stroke="#00AFF0"
        strokeLinecap="round"
      />
      <path
        d="M1.38403 0.679932L11.384 12.6799"
        stroke="#00AFF0"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BellOff;
