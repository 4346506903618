import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      padding: ' 36px 28px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      background: 'inherit',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        padding: '26px 21px 0',
        gap: '25px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        padding: ' 10px 15px 0',
        gap: '16px',
      },
    },
    list: {
      height: 'calc(100vh - 290px)',
      overflow: 'auto',
    },
  };
};

export default useStyles;
