import React from 'react';
import { Box } from '@mui/material';
import Header from '../../components/Header';
import AccountHead from '../../components/AccountDashboard/AccountHead';
import AccountDashboard from '../..//components/AccountDashboard';

import useStyles from './useStyles';

const AccountPage = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.root}>
      <Header>
        <AccountHead />
      </Header>
      <AccountDashboard />
    </Box>
  );
};

export default AccountPage;
