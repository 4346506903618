const UserSquare = ({ color = "#FFFFFF" }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5001 26.5417H10.5001C8.96013 26.5417 7.6768 26.39 6.5918 26.0634C6.19513 25.9467 5.93846 25.5617 5.9618 25.1533C6.25346 21.665 9.78846 18.9233 14.0001 18.9233C18.2118 18.9233 21.7351 21.6533 22.0385 25.1533C22.0735 25.5733 21.8168 25.9467 21.4085 26.0634C20.3235 26.39 19.0401 26.5417 17.5001 26.5417ZM7.84013 24.57C8.61013 24.7217 9.48513 24.7917 10.5001 24.7917H17.5001C18.5151 24.7917 19.3901 24.7217 20.1601 24.57C19.5418 22.33 16.9868 20.6733 14.0001 20.6733C11.0135 20.6733 8.45846 22.33 7.84013 24.57Z"
      fill={color}
    />
    <path
      d="M17.5 2.33333H10.5C4.66671 2.33333 2.33337 4.66667 2.33337 10.5V17.5C2.33337 21.91 3.66337 24.325 6.83671 25.2233C7.09337 22.19 10.2084 19.7983 14 19.7983C17.7917 19.7983 20.9067 22.19 21.1634 25.2233C24.3367 24.325 25.6667 21.91 25.6667 17.5V10.5C25.6667 4.66667 23.3334 2.33333 17.5 2.33333ZM14 16.5316C11.69 16.5316 9.82337 14.6534 9.82337 12.3434C9.82337 10.0334 11.69 8.16667 14 8.16667C16.31 8.16667 18.1767 10.0334 18.1767 12.3434C18.1767 14.6534 16.31 16.5316 14 16.5316Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9999 17.4066C11.2116 17.4066 8.94824 15.1317 8.94824 12.3434C8.94824 9.55502 11.2116 7.29167 13.9999 7.29167C16.7882 7.29167 19.0516 9.55502 19.0516 12.3434C19.0516 15.1317 16.7882 17.4066 13.9999 17.4066ZM13.9999 9.04167C12.1799 9.04167 10.6982 10.5234 10.6982 12.3434C10.6982 14.175 12.1799 15.6566 13.9999 15.6566C15.8199 15.6566 17.3016 14.175 17.3016 12.3434C17.3016 10.5234 15.8199 9.04167 13.9999 9.04167Z"
      fill={color}
    />
  </svg>
);

export default UserSquare;
