import React from 'react';

import styles from './styles.module.scss';


export const NotFoundPage = () => {

    return (
        <div className={styles.container}>Page not found</div>
    );
};
