import React, { useCallback, useEffect } from 'react';
import { CreatorsSessionStatus } from '../../../../../constants/creators';
import { creatorsService } from '../../../../../services/CreatorsService';
import { useElectronContext } from '../../../providers/ElectronProvider';
import { loadCreatorsList } from '../../../../../store/reducers/creators';
import { useAppDispatch } from '../../../../../store';

const useCreatorAutoLogin = ({
  isReady,
  ref,
}: {
  isReady: boolean;
  ref: React.MutableRefObject<any | null>;
}) => {
  const dispatch = useAppDispatch();
  const {
    activeCreator,
    activateLoginCreatorId,
    activateLoginSessionId,
    setIsActivatingModel,
  } = useElectronContext();

  const handleSetLocalStorage = useCallback(
    async (bcTokenSha: string, _grecaptcha: string) => {
      if (!ref.current) {
        return;
      }

      await ref.current.executeJavaScript(
        `localStorage.setItem("bcTokenSha", "${bcTokenSha}"); localStorage.setItem("_grecaptch", "${_grecaptcha}"); `,
      );

      return;
    },
    [],
  );

  const handleReloadPage = useCallback(async () => {
    if (!ref.current) {
      return;
    }

    await ref.current.executeJavaScript(`location.reload();`);
  }, []);

  useEffect(() => {
    if (
      !activeCreator ||
      !isReady ||
      activeCreator.sessionStatus !== CreatorsSessionStatus.ACTIVE
    ) {
      return;
    }

    let unmounted = false;

    const run = async () => {
      console.log('run LOGIN!');
      let sessionId;

      try {
        setIsActivatingModel(true);
        const data = await creatorsService.getCreatorSessionUrl(
          activeCreator.id!,
        );

        const { session, bcTokenSha, _grecaptcha } = data;
        sessionId = data.sessionId;

        if (!session.length) {
          setIsActivatingModel(false);
          return;
        }

        await window.electronAPI.invokeCookieSet({
          payload: session,
        });

        if (bcTokenSha && _grecaptcha) {
          await handleSetLocalStorage(bcTokenSha, _grecaptcha);
        } else {
          throw new Error('bcTokenSha or _grecaptcha');
        }

        await handleReloadPage();
      } catch (error: any) {
        console.error(`error: ${error}`);

        if (sessionId) {
          console.log('getDeactivateCreatorSessionUrl 2');
          await creatorsService.getDeactivateCreatorSessionUrl(
            activeCreator.id!,
            {
              sessionId,
            },
          );
          dispatch(loadCreatorsList());
        }
      } finally {
        setIsActivatingModel(false);
      }
    };

    run();

    return () => {
      unmounted = true;
    };
  }, [activeCreator, isReady, handleSetLocalStorage, handleReloadPage]);

  useEffect(() => {
    if (!activateLoginCreatorId || !activateLoginSessionId) {
      return;
    }

    const run = async () => {
      await window.electronAPI.invokeCookieSet({
        payload: [],
      });
    };

    //TODO: remove localStorage!
    run();
  }, [activateLoginCreatorId, activateLoginSessionId]);
};

export default useCreatorAutoLogin;
