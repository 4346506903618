import { useMemo } from 'react';

import { useAppSelector } from '../store';
import { getCreatorsList } from '../store/reducers/creators';
import { getMembersState } from '../store/reducers/members';
import { getUserAuthData } from '../store/reducers/auth';

import usePermissions from './usePermissions';

const useCreators = () => {
  const user = useAppSelector(getUserAuthData);

  const { items } = useAppSelector(getCreatorsList);
  const { membersCreators } = useAppSelector(getMembersState);

  const { isSuperAdminPermission } = usePermissions();

  const filteredCreators = useMemo(() => {
    if (!user?.id) {
      return [];
    }

    if (isSuperAdminPermission) {
      return items;
    }

    const assignedCreatorIds = membersCreators
      .filter((item) => item.memberId === user?.id)
      .map((item) => item.creatorId);

    return items.filter(
      (item) => item?.id && assignedCreatorIds.includes(item.id),
    );
  }, [isSuperAdminPermission, items, membersCreators, user?.id]);

  return { creators: filteredCreators ?? [] };
};

export default useCreators;
