import {
  CREATORS_API,
  CREATORS_CHECK_API,
  CREATORS_DICTIONARIES_API,
  CREATORS_PROXY_API,
  getRequestActivateCreatorApi,
  getCreatorSessionApi,
  getActivateCreatorSessionApi,
  getDeactivateCreatorSessionApi,
  getRequestDeleteCreatorApi, updateMemberApi, getUpdateCreatorApi, CREATORS_PROFILE_LINK_API, getRequestChangeProxyApi,
} from 'config/api';
import { BaseService } from './BaseService';
import { ICreators, ICreatorsDictionary } from 'models/creators';
import { ICreatorSession } from '../models/sessions';
import { IMember, IMemberCreator, IMemberCreatorBody, IMemberData } from '../models/members';
export interface IGetCreatorsDictionary {
  dictionaries: {
    id: string;
    value: ICreatorsDictionary;
  };
}

class CreatorsService extends BaseService {
  public async getCreators(): Promise<{ creators: ICreators[] }> {
    return this.get(CREATORS_API);
  }

  public async getCreatorById(id: string): Promise<{ creator: ICreators }> {
    return this.get(`${CREATORS_API}/${id}`);
  }

  public async getCreatorsById(creatorId: string): Promise<ICreators | null> {
    try {
      const { creators } = await this.getCreators();

      return creators.find((creator) => creator.id === creatorId) ?? null;
    } catch (e) {
      console.log('e', e);
      return null;
    }
  }

  public async getCreatorsDictionaries(): Promise<IGetCreatorsDictionary> {
    return this.get(CREATORS_DICTIONARIES_API);
  }

  public async getCreatorsProxy(): Promise<{ countries: string[] }> {
    return this.get(CREATORS_PROXY_API);
  }

  public async checkLinkCreators(
    link: string,
  ): Promise<{ isCreator: boolean }> {
    return this.get(CREATORS_CHECK_API, { link });
  }

  public async createCreators(
    body: ICreators,
  ): Promise<{ creator: ICreators }> {
    return this.post(CREATORS_API, body);
  }

  public async connectCreator(
    id: string,
  ): Promise<{ creatorId: string; sessionId: string }> {
    return this.post(getRequestActivateCreatorApi(id));
  }

  public async getCreatorSessionUrl(
    creatorId: string,
  ): Promise<ICreatorSession> {
    return this.get(getCreatorSessionApi(creatorId));
  }

  public async activateCreatorSessionUrl(
    creatorId: string,
    data: ICreatorSession,
  ): Promise<{ isUpdated: boolean }> {
    return this.put(getActivateCreatorSessionApi(creatorId), data);
  }

  public async getDeactivateCreatorSessionUrl(
    creatorId: string,
    data: { sessionId: string },
  ): Promise<{ isUpdated: boolean }> {
    return this.put(getDeactivateCreatorSessionApi(creatorId), data);
  }

  public deleteCreator(id: string): Promise<void> {
    return this.delete(getRequestDeleteCreatorApi(id));
  }

  public updateCreatorData(id: string, data: IMemberCreatorBody): Promise<{ creator: ICreators }> {
    return this.patch(getUpdateCreatorApi(id), data);
  }

  public async getOFImageLink(link: string): Promise<{ profile: { imageUrl: string } }> {
    return this.get(CREATORS_PROFILE_LINK_API, { link });
  }

  public updateCreatorProxy(id: string, proxyCountry: string): Promise<{ creator: ICreators }> {
    return this.patch(getRequestChangeProxyApi(id), { proxyCountry });
  }
}

export const creatorsService = new CreatorsService();
