import Chart, { Props } from "react-apexcharts";
import { memo, useEffect, useMemo, useState } from 'react';
import { IEarnings, IEarningsChargebacks, StatisticFilters } from 'models/statistics';
import { statisticsService } from 'services/StatisticsService';
import variables from "assets/styles/variables.module.scss";


import styles from './PieChart.module.scss';
import { classNames } from 'utils/classNames';

const initialOptions: Props = {
    options: {
        labels: [],
        legend: {
            show: false
        },
        colors: ['#00AFF0', '#4BEEBD', '#FC975F', '#7A64B8', '#EC4899', '#FACC15'],
        stroke: {
            width: 3,
            colors: [variables.secondaryColor]
        },
    },
    series: [],
}


interface PieChartProps {
    name: string;
    filters: StatisticFilters;
    className?: string;
}

const EmptyPieChart = () => {
    return (
        <div className={styles.emptyPie}>
            <div className={styles.innerPiece}>No data to build a pie chart</div>
        </div>
    )
}

const PieChart = ({ name, filters, className }: PieChartProps) => {
    const [settings, setSettings] = useState(initialOptions);
    const [data, setData] = useState<IEarnings[]>();
    const [chargebacks, setChargebacks] = useState<IEarningsChargebacks>();
    const [loading, setLoading] = useState(false);
    const isEmpty = useMemo(() => (data?.reduce((sum, value) => sum + Number(value.sum), 0) || 0) === 0, [data]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const { data } = await statisticsService.getCreatorEarningBreakdown(filters);
                const chargebacks = await statisticsService.getCreatorEarningChargebacks(filters);
    
                const updatedLabels = data?.map(item => item.value);
                const updatedSeries = data?.map(item => Math.round(Number(item.sum)));
                // const updatedSeries = data?.map((item, index) => Number(`${index}0`)); 
    
                const deepUpdatedSettings = JSON.parse(JSON.stringify(initialOptions));
                deepUpdatedSettings.options.labels = updatedLabels;
                deepUpdatedSettings.series = updatedSeries;
                deepUpdatedSettings.options.tooltip = {
                    y: {
                        formatter: function (value: string) {
                            return `$ ${value}`
                        },
                    }

 
                };
    
                setSettings(deepUpdatedSettings);
                setData(data);
                setChargebacks(chargebacks);
            } catch {}


            setLoading(false);
        })();

    }, [filters]);

    return (
        <div className={styles.container}>
            <div className={styles.chart}>
                {isEmpty ? <EmptyPieChart /> : <Chart options={settings.options} series={settings.series} type="donut" />}
            </div>
            
            <div className={styles.legend}>
                {data?.map(({ value, sum }, index) => (
                    <div className={styles.item}>
                        <p className={styles.title}>{value}</p>
                        <div className={styles.stats}>
                            <span className={styles.marker} style={{ backgroundColor: settings.options?.colors?.[index]}} />
                            <span className={styles.earningsMoney}>$ {Math.round(Number(sum))}</span>
                            {/*<span className={styles.percents}>{percentage}%</span>*/}
                        </div>
                    </div>

                ))}

                <div className={classNames(styles.item, {}, [styles.total])}>
                    <p className={styles.title}>Chargebacks Value:</p>
                    <div className={styles.stats}>
                        <span className={styles.marker} />
                        <span className={styles.earningsMoney}>$ {Math.round(Number(chargebacks?.totalAmount)) || 0}</span>
                    </div>
                </div>

                <div className={classNames(styles.item, {}, [styles.total])}>
                    <p className={styles.title}>Number of Chargebacks:</p>
                    <div className={styles.stats}>
                        <span className={styles.marker} />
                        <span className={styles.earningsMoney}> {Math.round(Number(chargebacks?.totalCount)) || 0}</span>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default memo(PieChart);
