
import { STATISTICS_BEST_DAY_API, STATISTICS_BEST_HOUR_API, STATISTICS_EARNINGS_HEATMAP_API, STATISTICS_EARNING_API, STATISTICS_EARNING_BREAKDOWN_API, STATISTICS_EARNING_CHARGEBACKS_API, STATISTICS_EARNING_STATS_API, STATISTICS_MAIN_API, STATISTICS_RUN_RATE_API, STATISTICS_TOP_FANS_API } from 'config/api';
import { BaseService } from './BaseService';
import { IEarnings, IEarningsChargebacks, IEarningsHeatmap, IEarningsStats, IMainStatistics, IRunRate, ITopFanUser, StatisticFilters } from 'models/statistics';



class StatisticsService extends BaseService {
    public async getMainStatistics(filters: StatisticFilters): Promise<{ data: IMainStatistics }> {
        return this.get(STATISTICS_MAIN_API, { ...filters });
    }

    public async getCreatorEarning(filters: StatisticFilters): Promise<{ data: IEarnings[] }> {
        return this.get(STATISTICS_EARNING_API, { ...filters });
    }

    public async getCreatorBestDay(filters: StatisticFilters): Promise<{ data: IEarnings[] }> {
        return this.get(STATISTICS_BEST_DAY_API, { ...filters });
    }

    public async getCreatorBestHour(filters: StatisticFilters): Promise<{ data: IEarnings[] }> {
        return this.get(STATISTICS_BEST_HOUR_API, { ...filters });
    }

    public async getCreatorEarningHeatmap(filters: StatisticFilters): Promise<{ data: IEarningsHeatmap[] }> {
        return this.get(STATISTICS_EARNINGS_HEATMAP_API, { ...filters });
    }

    public async getCreatorRunRate(filters: StatisticFilters): Promise<{ data: IRunRate }> {
        return this.get(STATISTICS_RUN_RATE_API, { ...filters });
    }

    public async getTopSpendingFans(filters: StatisticFilters): Promise<{ users: ITopFanUser[] }> {
        return this.get(STATISTICS_TOP_FANS_API, { ...filters });
    }

    public async getCreatorEarningBreakdown(filters: StatisticFilters): Promise<{ data: IEarnings[] }> {
        return this.get(STATISTICS_EARNING_BREAKDOWN_API, { ...filters });
    }

    public async getCreatorEarningChargebacks(filters: StatisticFilters): Promise<IEarningsChargebacks> {
        return this.get(STATISTICS_EARNING_CHARGEBACKS_API, { ...filters });
    }

    public async getCreatorEarningStats(filters: StatisticFilters): Promise<IEarningsStats> {
        return this.get(STATISTICS_EARNING_STATS_API, { ...filters });
    }

}

export const statisticsService = new StatisticsService();