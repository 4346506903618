import { useCallback, memo, useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "assets/images/cross.svg";
import { ReactComponent as InviteLinkIcon } from "assets/images/invite-link.svg";
import { useAppDispatch, useAppSelector } from "store";
import Input from "components/fields/Input/Input";
import { Button, ThemeButton } from "components/Button/Button";
import Select from "../../fields/Select/Select";
import useFetch from "../../../hooks/useFetch";
import { getRolesList, loadRoles } from "store/reducers/roles";

import { memberService } from "../../../services/MemberService";

import styles from "./AddMembers.module.scss";
import { SIGN_UP_API } from "../../../config/api";
import { IDropdownOption } from "models/fields";
import { RoutePath } from "config/routeConfig";

interface AddMembersProps {
    onClose?: () => void;
}

export const AddMembers = memo(({ onClose }: AddMembersProps) => {
    const [role, setRole] = useState<IDropdownOption | null>(null);
    const [coping, setCoping] = useState(false);
    const [copied, setCopied] = useState(false);
    const [inviteLink, setInviteLink] = useState<null | string>(null);

    const dispatch = useAppDispatch();

    const { items: roles } = useAppSelector(getRolesList);

    const generateToken = useCallback(async ({ roleId }: { roleId: string }) => {
        return await memberService.createMemberToken({ roleId });
    }, []);

    const handleCopy = useCallback(async (text: string) => {
        setCoping(true);
        await navigator.clipboard.writeText(text);
        setCopied(true);
        setCoping(false);
    }, []);

    const { data, loading, callLoadData } = useFetch(generateToken);

    useEffect(() => {
        if (roles.length > 0) {
            return;
        }

        dispatch(loadRoles());
    }, [roles.length]);

    useEffect(() => {
        if (!role?.value) {
            return;
        }

        callLoadData({ roleId: role.value });
    }, [role?.value, callLoadData]);

    useEffect(() => {
        if (!data?.memberToken) {
            return;
        }

        setInviteLink(
            `${window.location.origin}${RoutePath.signUp}?token=${data.memberToken}`
        );
    }, [data?.memberToken]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <CloseIcon className={styles.close} onClick={onClose} />
            </div>

            <div className={styles.body}>
                <div className={styles.icon}>
                    <InviteLinkIcon />
                </div>
                <p className={styles.title}>Your Invite Link</p>
                <p className={styles.description}>
                    Share this link with your agency member, allowing them to create an
                    account and instantly join the agency
                </p>
                <div className={styles.actions}>
                    <Input
                        name="ofLink"
                        placeholder="https://onlyfans.com/...."
                        className={styles.input}
                        value={inviteLink ?? ""}
                    />
                    <Select
                        name="roleId"
                        options={roles.map(({ id, name }) => ({ value: id, label: name }))}
                        className={styles.select}
                        placeholder="Select Role"
                        value={role}
                        onChange={(newValue) => {
                            setCopied(false);
                            setRole((newValue as IDropdownOption) ?? null);
                        }}
                    />
                    <Button
                        theme={ThemeButton.PRIMARY}
                        disabled={loading || !inviteLink || coping}
                        onClick={() => handleCopy(inviteLink!)}
                    >
                        {copied ? "Copied" : "Copy"}
                    </Button>
                </div>
            </div>
        </div>
    );
});
