import { Popover } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { DayPicker, DayPickerRangeProps } from 'react-day-picker';
import styles from './DateRangePicker.module.scss';
import { classNames } from 'utils/classNames';
import { components } from 'react-select';
import moment from 'moment';
import { IDateUTCRange } from 'components/Statistics/Statistics';
import { ThemeField } from 'models/fields';
import { NamedField } from '../NamedField/NamedField';

type BasePickerRangeProps = Omit<DayPickerRangeProps, 'mode'>

interface IDateRangePickerProps extends BasePickerRangeProps {
    theme?: ThemeField;
    fieldName?: string;
    className?: string;
}

const DateRangePicker: React.FC<IDateRangePickerProps> = ({ 
    theme = ThemeField.DARK, 
    selected, 
    fieldName, 
    className, 
    onSelect, 
    ...props 
}) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const onClickOpenPicker = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onClickClosePicker = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const isPickerOpened = useMemo(() => Boolean(anchorEl), [anchorEl]);
    const id = isPickerOpened ? 'picker-popover' : undefined;

    const { DownChevron } = components;

    const getPickerPlaceholder = () => {
        if (!selected || !selected?.from) return;

        if (!selected.to || selected.to === selected.from) return moment(selected.from).format("DD MMM YY");

        return `${moment(selected.from).format("DD MMM YY")} – ${moment(selected.to).format("DD MMM YY")}`
    }

    return (
        <NamedField
            fieldName={fieldName}
            className={classNames(styles.field, {}, [className, theme])}
        >
            <>
                <button
                    className={classNames(styles.selector, { [styles.open]: isPickerOpened })}
                    aria-describedby={id}
                    onClick={onClickOpenPicker}
                >
                    <div>{getPickerPlaceholder()}</div>
                    <DownChevron className={styles.chevronIcon} />
                </button>

                <Popover
                    id={id}
                    open={isPickerOpened}
                    anchorEl={anchorEl}
                    onClose={onClickClosePicker}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    slotProps={{
                        paper: {
                            className: styles.paper
                        }
                    }}
                >
                    <DayPicker
                        mode="range"
                        onSelect={onSelect}
                        className={styles.picker}
                        selected={selected}
                        showOutsideDays
                        {...props}
                    />
                </Popover>
            </>
        </NamedField>

    );
}

export default DateRangePicker;