import React, { useCallback, useEffect } from 'react';
import { useEmojiProviderContext } from '../../../providers';

interface IWebView {
  ref: React.MutableRefObject<any | null>;
}

const useWebView = ({ ref }: IWebView) => {
  const { setIsHotbarModalOpen, setIsEmojiModalOpen } =
    useEmojiProviderContext();

  const handleLocalStorage = useCallback(async () => {
    if (!ref.current) {
      return;
    }

    const localStorageData = await ref.current.executeJavaScript(`
        JSON.stringify(window.localStorage);
      `);

    return JSON.parse(localStorageData);
  }, []);

  useEffect(() => {
    if (!window.electronAPI) {
      return;
    }

    const eventName = 'get-local-storage';

    const cb = (_: IpcRendererEvent, data: string[]) => {
      setTimeout(async () => {
        const storage = await handleLocalStorage();
        const payload = storage
          ? data.reduce(
              (acc, key: string) => ({
                ...acc,
                // @ts-ignore
                [key]: storage[key] ?? '',
              }),
              {},
            )
          : null;

        window.electronAPI.send(`${eventName}-response`, {
          payload,
        });
      }, 3000);
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, []);

  useEffect(() => {
    if (!ref.current || !window.electronAPI) {
      return;
    }

    const run = (event: any) => {
      if (event.channel == 'return-emojiPencil') {
        setIsHotbarModalOpen(true);
      } else if (event.channel == 'return-emojiPopup') {
        setIsEmojiModalOpen(true);
      } else if (event.channel == 'return-page-change') {
        setIsHotbarModalOpen(false);
        setIsEmojiModalOpen(false);
      }
    };

    ref.current.addEventListener('ipc-message', run);

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('ipc-message', run);
      }
    };
  }, [ref.current]);
};

export default useWebView;
