import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { IAccount } from './interfaces';

import { useElectronContext } from '../../../../../providers/ElectronProvider';
import { useOfSidebarContext } from '../../../providers';

import usePermissions from '../../../../../../../hooks/usePermissions';
import { OF_SIDEBAR_ROUTE } from '../../../constants/enums';
import { OFPermissions } from '../../../../../../../constants/roles';

import useStyles from './useStyles';

const Account = ({ subscriber }: IAccount) => {
  const styles = useStyles();

  const { setActiveChatterId, activeChatterId, setOfUrl } =
    useElectronContext();
  const { setSidebarRoute } = useOfSidebarContext();
  const { userPermissions, isSuperAdminPermission } = usePermissions();

  const { id, name, totalSpent, avatar } = subscriber;

  const handleClick = useCallback(() => {
    setActiveChatterId(subscriber.id);
    setSidebarRoute(OF_SIDEBAR_ROUTE.ACCOUNT);

    if (!isSuperAdminPermission) {
      const permissions: string[] = userPermissions ?? [];

      if (!permissions.includes(OFPermissions.CHAT_HIDING_FAN_BLOCKING)) {
        return;
      }
    }
    setOfUrl(`https://onlyfans.com/my/chats/chat/${id}/`);
  }, [subscriber, isSuperAdminPermission, userPermissions]);

  const isActive = activeChatterId && activeChatterId === subscriber.id;

  return (
    <Button type="button" onClick={handleClick} sx={styles.button}>
      <Box sx={{ ...styles.root, ...(isActive ? styles.active : {}) }}>
        <Box sx={styles.group}>
          <Box sx={styles.image}>
            {avatar ? <img src={avatar} alt={name} /> : null}
          </Box>
          <Typography component={'span'} sx={styles.name}>
            {name}
          </Typography>
        </Box>

        <Typography component={'span'} sx={styles.price}>
          {totalSpent}$
        </Typography>
      </Box>
    </Button>
  );
};

export default Account;
