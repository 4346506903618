import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectCreatorStep } from 'constants/creators';
import { ModalType } from 'constants/modal';
import { StateSchema } from 'store';

export interface ModalConfig {
    isFlexibleWidth?: boolean;
    data?: unknown;
    [ModalType.СONNECT_CREATOR]?: {
        steps?: ConnectCreatorStep[],
        isEdit?: boolean;
    }
    shouldCloseOnOverlayClick?: boolean;
}

export interface ModalSchema {
    modalType: ModalType;
    config?: ModalConfig;
    loading: boolean;
}

const initialState: ModalSchema = {
    modalType: ModalType.NONE,
    config: { isFlexibleWidth: true, shouldCloseOnOverlayClick: true },
    loading: false,
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setModalType: (state: ModalSchema, action: PayloadAction<{type: ModalType, config?: ModalConfig}>) => {
            state.modalType = action.payload.type;
            state.config = action.payload.config;
        },
        setModalLoading: (state: ModalSchema, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
    },
});

export const getModalLoading = (state: StateSchema) => state.modal.loading;

export const { actions: modalActions } = modalSlice;
export const { reducer: modalReducer } = modalSlice;
