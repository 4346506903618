// @ts-nocheck
// [TODO]: Rework all type in useImageLoaded
import styles from './BrandCanvas.module.scss';
import { classNames } from 'utils/classNames';
import brandLogo from 'assets/images/whiteBrandLogo.png';
import { useState } from 'react';
import useImageLoaded from 'hooks/useImageLoaded';

interface BrandCanvasProps {
    className?: string;
    isInitProccess?: boolean;
}

export const BrandCanvas = ({ className, isInitProccess }: BrandCanvasProps) => {
    const [ref, loaded, onLoad] = useImageLoaded();
      
    return (
        <div className={classNames(styles.BrandCanvas, { [styles.initializing]: isInitProccess }, [className])}>
            <img ref={ref} src={brandLogo} alt="Brand Logo"  />
        </div>
    );
};
