import React from 'react';

import ModalHotbar from './ModalHotbar';
import ModalEmojiPicker from './ModalEmojiPicker';

import { useEmojiProviderContext } from '../../providers';

const EmojiDashboard = () => {
  const { activeEmojis, setActiveEmojis } = useEmojiProviderContext();

  return (
    <>
      <ModalHotbar emojis={activeEmojis ?? []} onSave={setActiveEmojis} />
      <ModalEmojiPicker />
    </>
  );
};

export default EmojiDashboard;
