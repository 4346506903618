import { BaseService } from './BaseService';

import { getFanNotesApi } from '../config/api';

class FanNotesService extends BaseService {
  public async getFanNotes(
    creatorId: string,
    chatterId: string,
  ): Promise<{ notes: string }> {
    return this.get(getFanNotesApi(creatorId, chatterId));
  }

  public async updateFanNotes(
    creatorId: string,
    chatterId: string,
    data: { notes: string },
  ): Promise<{ notes: string }> {
    return this.put(getFanNotesApi(creatorId, chatterId), data);
  }
}

export const fanNotesService = new FanNotesService();
