import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Button, Box } from '@mui/material';

import Dashboard from '../../Dashboard';
import OnlineFans, { StyleEnum } from '../../OnlineFans';

import { useElectronContext } from '../../../../../providers/ElectronProvider';

import useStyles from './useStyles';
import { useOfSidebarContext } from '../../../providers';

const AccountHead = () => {
  const styles = useStyles();

  const ref = useRef<HTMLDivElement>(null);

  const { activeChatterId, subscribers } = useElectronContext();
  const { isActiveOFOpen: isOpen, setActiveOFOpen: setIsOpen } =
    useOfSidebarContext();

  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(isOpen ? (window.innerHeight - 90) * 0.7 : 0);
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [activeChatterId]);

  const handleClick = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  return (
    <Box sx={styles.root}>
      <Button onClick={handleClick} sx={styles.button(isOpen)}>
        <OnlineFans
          style={isOpen ? StyleEnum.Default : StyleEnum.Gray}
          total={subscribers.length}
        />
      </Button>
      <Box sx={styles.collapse} style={{ height }}>
        <div ref={ref}>
          <Dashboard disableOnlyFansIndicator />
        </div>
        <Button onClick={handleClick} sx={styles.hideButton}>
          Hide
        </Button>
      </Box>
    </Box>
  );
};

export default AccountHead;
