import { IDropdownOption } from "models/fields";



export const convertToDropdownItem = (value?: string, label?: string) => {
  if (value) {
    return { label: label || value, value } as IDropdownOption;
  }

  return null;
};


export const convertToDropdownArray = (array: string[] | undefined) => {
  if (array && array.length > 0) {
    return array.map(value => ({ label: value, value } as IDropdownOption));
  }

  return undefined;
};

export const getObjectKey = (obj: any, value?: string) => {
  return Object.keys(obj).find(key => obj[key] === value);
};
