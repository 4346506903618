import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { StateSchema, ThunkConfig } from "../index";

import { IMember, IMemberCreator, IMemberData } from '../../models/members';
import { memberService } from "../../services/MemberService";
import { modalActions } from "./modal";
import { initAuthData } from './auth';

export interface MembersSchema {
    list: {
        items: IMember[];
        cursor: undefined | null | IMember;
    };
    membersCreators: IMemberCreator[];
    memberActiveModal: IMember | null;
    loading: boolean;
    inited: boolean;
}

export const loadMembersList = createAsyncThunk<
    IMember[],
    void,
    ThunkConfig<string>
>("roles/loadMembersList", async (_, { rejectWithValue, dispatch }) => {
    try {
        dispatch(loadMembersCreatorsList());
        const { members } = await memberService.getMembers();

        return members ?? [];
    } catch {
        return rejectWithValue("Error");
    }
});

export const loadMembersCreatorsList = createAsyncThunk<
    IMemberCreator[],
    void,
    ThunkConfig<string>
>("roles/loadMembersCreatorsList", async (_, { rejectWithValue }) => {
    try {
        const { membersCreators } = await memberService.getMembersCreators();

        return membersCreators ?? [];
    } catch {
        return rejectWithValue("Error");
    }
});

export const assignMemberCreator = createAsyncThunk<
    void,
    { memberId: string; creatorId: string },
    ThunkConfig<string>
>("roles/assignMemberCreator", async (data, { rejectWithValue, dispatch }) => {
    try {
        dispatch(modalActions.setModalLoading(true));

        await memberService.assignMemberCreator(data);

        dispatch(loadMembersCreatorsList());

        dispatch(modalActions.setModalLoading(false));
    } catch {
        dispatch(modalActions.setModalLoading(false));
        return rejectWithValue("Error");
    }
});

export const removeMemberCreator = createAsyncThunk<
    void,
    { memberId: string; creatorId: string },
    ThunkConfig<string>
>("roles/removeMemberCreator", async (data, { rejectWithValue, dispatch }) => {
    try {
        dispatch(modalActions.setModalLoading(true));

        await memberService.removeMemberCreator(data);

        dispatch(loadMembersCreatorsList());
        dispatch(modalActions.setModalLoading(false));
    } catch {
        dispatch(modalActions.setModalLoading(false));
        return rejectWithValue("Error");
    }
});

export const updateMemberRole = createAsyncThunk<
    void,
    { memberId: string; roleId: string },
    ThunkConfig<string>
>("roles/updateMemberRole", async (data, { rejectWithValue, dispatch }) => {
    try {
        dispatch(modalActions.setModalLoading(true));

        await memberService.updateMemberRole(data);

        dispatch(loadMembersList());
        dispatch(modalActions.setModalLoading(false));
    } catch {
        dispatch(modalActions.setModalLoading(false));
        return rejectWithValue("Error");
    }
});

export const updateProfile = createAsyncThunk<
  void,
  { memberId: string, data: IMemberData },
  ThunkConfig<string>
>("roles/updateProfile", async ({ memberId, data}, { rejectWithValue, dispatch }) => {
    try {
        await memberService.updateMemberProfile(memberId, data);
        await dispatch(initAuthData());
    } catch {
        return rejectWithValue("Error");
    }
});

export const updatePassword = createAsyncThunk<
  void,
  { oldPassword: string; newPassword: string },
  ThunkConfig<string>
>("roles/updatePassword", async ({ oldPassword, newPassword}, { rejectWithValue, dispatch }) => {
    try {
        await memberService.updateMemberPassword({
            oldPassword,
            newPassword
        });
    } catch {
        return rejectWithValue("Error");
    }
});

export const deleteMember = createAsyncThunk<
    void,
    { memberId: string },
    ThunkConfig<string>
>("roles/deleteMember", async ({ memberId }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(modalActions.setModalLoading(true));

        await memberService.deleteMember(memberId);

        dispatch(loadMembersList());
        dispatch(modalActions.setModalLoading(false));
    } catch {
        dispatch(modalActions.setModalLoading(false));
        return rejectWithValue("Error");
    }
});

const initialState: MembersSchema = {
    list: {
        items: [],
        cursor: undefined,
    },
    membersCreators: [],
    memberActiveModal: null,
    loading: false,
    inited: false,
};

export const membersSlice = createSlice({
    name: "members",
    initialState,
    reducers: {
        setMemberActiveModal: (state: MembersSchema,action: PayloadAction<IMember | null>) => {
            state.memberActiveModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadMembersList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadMembersList.fulfilled, (state, action) => {
            state.loading = false;
            state.list.items = action.payload;

            !state.inited && (state.inited = true);
        });
        builder.addCase(loadMembersList.rejected, (state) => {
            state.loading = false;
            !state.inited && (state.inited = true);
        });
        builder.addCase(loadMembersCreatorsList.fulfilled, (state, action) => {
            state.membersCreators = action.payload;
        });
        builder.addCase(loadMembersCreatorsList.rejected, (state) => {
            state.membersCreators = [];
        });
    },
});

export const getMembersState = (state: StateSchema) => state.members;
export const getMembersList = (state: StateSchema) => state.members.list;

export const { actions: membersActions } = membersSlice;
export const { reducer: membersReducer } = membersSlice;
