import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { modalActions } from 'store/reducers/modal';
import { ModalType } from 'constants/modal';


const useElectronApplicationUpdate = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
      
    if (!window.electronAPI) return;
    const eventName = 'update-application-downloaded';
    window.electronAPI.receive(eventName, () => {
      dispatch(modalActions.setModalType({ type: ModalType.AVAILABLE_UPDATE, config: { shouldCloseOnOverlayClick: true } }))
    });

    return () => {
      window.electronAPI.receiveOff(eventName);
    };
  }, [dispatch]);


};

export default useElectronApplicationUpdate;
