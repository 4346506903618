import React, { memo, useCallback } from "react";

import styles from "./CustomizedTable.module.scss";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import { IColumn } from "models/table";
import { classNames } from "utils/classNames";
import { Skeleton } from "@mui/material";
import { Tooltip } from '../Tooltip/Tooltip';
import ColumnCell from "./ColumnCell/ColumnCell";

interface ISkeletonTable {
  columns: IColumn[];
  shimmerCount?: number;
}

const SkeletonTable: React.FC<ISkeletonTable> = memo(
  ({ columns, shimmerCount = 8 }) => {
    const items = [...Array(shimmerCount).keys()].map((foo) => foo + 1);

    const onRenderColumnItem = useCallback(() => {
      return <Skeleton variant="rounded" />;
    }, []);

    return (
      <CustomizedTable
        columns={columns}
        items={items}
        onRenderColumnItem={onRenderColumnItem}
      />
    );
  }
);

interface ICustomizedTable {
  items: any[];
  columns: IColumn[];
  className?: string;
  onRenderColumnItem?: (
    item: any,
    index: number,
    column: IColumn
  ) => JSX.Element;
  onClickRow?: (item: any) => void;
  loading?: boolean;
  emptyElement?: JSX.Element;
}

const CustomizedTable: React.FC<ICustomizedTable> = ({
  items,
  columns,
  className,
  loading,
  emptyElement,
  onRenderColumnItem,
  onClickRow,
}) => {
  if (loading) {
    return <SkeletonTable columns={columns} />;
  }

  if (!items.length && emptyElement) {
    return <div className={styles.emptyElement}>{emptyElement}</div>;
  }

  return (
    <div className={classNames(styles.container, {}, [className])}>
      <Box sx={{ width: "100%" }}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }}>
            <TableHead sx={{ backgroundColor: "#E8F4FF" }}>
              <TableRow>{columns.map((itemProps) => <ColumnCell {...itemProps} />)}</TableRow>
            </TableHead>
            <TableBody>
              {items.map((item: any, index: number) => (
                <TableRow
                  key={index}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() => onClickRow?.(item)}
                >
                  {columns.map((column) => {
                    const widthStyle = column.isFixed ? { width: column.width } : { maxWidth: column.width }
                    return (
                      <TableCell
                        align={column.align}
                        key={column.fieldName}
                        style={{ ...widthStyle, overflow: "hidden" }}
                      >
                        {onRenderColumnItem?.(item, index, column)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default CustomizedTable;
