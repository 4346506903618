const Refresh = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9898 15.8938C14.9823 15.8936 14.9748 15.8935 14.9672 15.8935L3.56355 15.8935L3.77721 15.6783C4.18548 15.267 4.18304 14.6027 3.77177 14.1944C3.3605 13.7861 2.69614 13.7886 2.28787 14.1998L0.298755 16.2036C-0.107398 16.6127 -0.107398 17.2729 0.298755 17.682L2.28787 19.6858C2.69614 20.097 3.3605 20.0995 3.77177 19.6912C4.18304 19.2829 4.18547 18.6186 3.77721 18.2073L3.56355 17.9921L14.941 17.9921C14.9498 17.9923 14.9585 17.9924 14.9673 17.9924C17.7512 17.9924 19.9948 15.7212 19.9948 12.9356V10.1733C19.9948 9.59377 19.5251 9.12399 18.9456 9.12399C18.3661 9.12399 17.8963 9.59377 17.8963 10.1733V12.9356C17.8963 14.569 16.59 15.8816 14.9898 15.8938Z"
        fill="#00AFF0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99922 4.09718C5.00672 4.09734 5.01425 4.09742 5.02179 4.09742L16.4255 4.09742L16.2118 4.31265C15.8035 4.72392 15.806 5.38828 16.2172 5.79655C16.6285 6.20482 17.2929 6.20238 17.7011 5.79111L19.6903 3.78737C20.0964 3.37823 20.0964 2.71804 19.6903 2.3089L17.7011 0.305161C17.2929 -0.10611 16.6285 -0.108543 16.2172 0.299724C15.806 0.70799 15.8035 1.37236 16.2118 1.78362L16.4255 1.99885L5.04799 1.99885C5.03924 1.99864 5.03048 1.99853 5.02168 1.99853C2.23783 1.99853 -0.00583267 4.26978 -0.00583267 7.0553V9.81766C-0.00583267 10.3972 0.463949 10.8669 1.04345 10.8669C1.62296 10.8669 2.09274 10.3972 2.09274 9.81766V7.0553C2.09274 5.4219 3.39906 4.10936 4.99922 4.09718Z"
        fill="#00AFF0"
      />
    </svg>
  );
};

export default Refresh;
