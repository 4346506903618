import React from 'react';
import { Button } from '@mui/material';

import { ITag } from './interfaces';

import useStyles from './styles';

const Tag = ({ label, color, onClick, active }: ITag) => {
  const styles = useStyles();
  return (
    <Button sx={styles.root(color, active)} onClick={onClick}>
      {label}
    </Button>
  );
};

export default Tag;
