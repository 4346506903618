import { BaseService } from './BaseService';
import { getScrapingMetadataApi } from '../config/api';

import { IScrapinMetadata } from '../models/scraping';

class ScrapingMetadataService extends BaseService {
  public async updateMetadata(
    creatorId: string,
    chatterId: string,
    data: IScrapinMetadata,
  ): Promise<IScrapinMetadata> {
    return this.patch(getScrapingMetadataApi(creatorId, chatterId), data);
  }
}

export const scrapingMetadataService = new ScrapingMetadataService();
