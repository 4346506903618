const DollarSquare = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6333 20.3234H12.7049C10.7916 20.3234 9.23994 18.7134 9.23994 16.73C9.23994 16.2517 9.63661 15.855 10.1149 15.855C10.5933 15.855 10.9899 16.2517 10.9899 16.73C10.9899 17.745 11.7599 18.5734 12.7049 18.5734H15.6333C16.3916 18.5734 17.0216 17.8967 17.0216 17.08C17.0216 16.065 16.6599 15.8667 16.0649 15.6567L11.3633 14C10.4533 13.685 9.22827 13.0084 9.22827 10.92C9.22827 9.12336 10.6399 7.6767 12.3666 7.6767H15.2949C17.2083 7.6767 18.7599 9.2867 18.7599 11.27C18.7599 11.7484 18.3633 12.145 17.8849 12.145C17.4066 12.145 17.0099 11.7484 17.0099 11.27C17.0099 10.255 16.2399 9.4267 15.2949 9.4267H12.3666C11.6083 9.4267 10.9783 10.1034 10.9783 10.92C10.9783 11.935 11.3399 12.1334 11.9349 12.3434L16.6366 14C17.5466 14.315 18.7716 14.9917 18.7716 17.08C18.7599 18.865 17.3599 20.3234 15.6333 20.3234Z"
        fill="currentColor"
      />
      <path
        d="M14 21.875C13.5217 21.875 13.125 21.4783 13.125 21V7C13.125 6.52167 13.5217 6.125 14 6.125C14.4783 6.125 14.875 6.52167 14.875 7V21C14.875 21.4783 14.4783 21.875 14 21.875Z"
        fill="currentColor"
      />
      <path
        d="M17.4999 26.5416H10.4999C4.16492 26.5416 1.45825 23.835 1.45825 17.5V10.5C1.45825 4.16498 4.16492 1.45831 10.4999 1.45831H17.4999C23.8349 1.45831 26.5416 4.16498 26.5416 10.5V17.5C26.5416 23.835 23.8349 26.5416 17.4999 26.5416ZM10.4999 3.20831C5.12158 3.20831 3.20825 5.12165 3.20825 10.5V17.5C3.20825 22.8783 5.12158 24.7916 10.4999 24.7916H17.4999C22.8783 24.7916 24.7916 22.8783 24.7916 17.5V10.5C24.7916 5.12165 22.8783 3.20831 17.4999 3.20831H10.4999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DollarSquare;
