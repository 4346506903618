import { CreatorsSessionStatus } from "constants/creators";
import { ICreators, SubscriptionStatus } from "./creators";

export interface IPaymentAccountInfo {
    email?: string;
    phone?: string;
    name?: string;
    country?: string;
    province?: string;
    isBusiness?: boolean;
    postalCode?: string;
}

export interface IPaymentBusinessPurchase {
    vatType?: string;
    city?: string;
    businessName?: string;
    businessId?: string;
    fullAddress?: string;
    postalCode?: string;
}

export interface ISubscriptionsHistoryItem {
    createdAt: string;
    total: string;
    description: string;
    id: string;
}

export interface ISubscriptionsHistory {
    nextToken: string;
    items: ISubscriptionsHistoryItem[]
}

export interface ISubscriptionsPaymentPlan {
    regular?: number;
    discount?: number;
}

export interface ISelectedPlanData {
    creatorId: string;
    plan: PlanAccount;
    regular?: number;
    discount?: number;
    disabled?: boolean;
}

export enum PlanAccount {
    M1 = "M1",
    M3 = "M3",
    M6 = "M6"
}

export interface ISubscriptions {
    agencyId: string;
    creator: ICreators;
    creatorId: string;
    creatorName: string;
    creatorUsername: string;
    endDate: string;
    plan: PlanAccount;
    sessionStatus: CreatorsSessionStatus;
    startDate: string;
    stats30D: string;
    status: SubscriptionStatus;
    subscriptionId: string;
    
    [PlanAccount.M1]: ISubscriptionsPaymentPlan;
    [PlanAccount.M3]: ISubscriptionsPaymentPlan;
    [PlanAccount.M6]: ISubscriptionsPaymentPlan;
}

export enum PaymentStatus {
    OPEN = "open",
    PAID = "paid",
    VOID = "void",
    UNCOLLECTIBLE = "uncollectible"
}

export interface IPaymentInvoice {
    hosted_invoice_url?: string;
    id?: string;
    status?: PaymentStatus;
    tax?: number;
    number?: string;
    total?: number;
    total_excluding_tax?: number;
}

export type IPaymentCustomer = IPaymentAccountInfo & IPaymentBusinessPurchase;

export const subscriptionStatusMap: Record<SubscriptionStatus, string> = {
    [SubscriptionStatus.PAID]: "Paid",
    [SubscriptionStatus.UNPAID]: "Unpaid",
    [SubscriptionStatus.TRIAL]: "Free Trial",
}