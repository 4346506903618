import React, { useEffect } from 'react';
import usePermissions from '../../../../../hooks/usePermissions';

import {
  OFPermissions,
  PlatformPermissions,
} from '../../../../../constants/roles';

const useUserPermissions = ({
  ref,
}: {
  ref: React.MutableRefObject<any | null>;
}) => {
  const { userPermissions, isSuperAdminPermission } = usePermissions();

  useEffect(() => {
    let unmounted = false;
    if (!window.electronAPI || isSuperAdminPermission) {
      return;
    }

    const eventName = 'ui:newpage';

    const cb = () => {
      if (unmounted) {
        return;
      }

      const permissions: string[] = userPermissions ?? [];

      const hideBlocks = () => {
        if (!permissions.includes(OFPermissions.CARDS_BANK)) {
          ref.current.insertCSS(
            `.m-main-container a[href^="/my/payments"], .m-main-container a[href^="/my/banking"] { display: none !important }`,
          );
        }

        if (!permissions.includes(OFPermissions.CHAT_HIDING_FAN_BLOCKING)) {
          ref.current.insertCSS(
            `ul.dropdown-menu li[role='presentation']:nth-of-type(10).m-danger, ul.dropdown-menu li[role='presentation']:nth-of-type(14).m-danger { display: none !important }`,
          );
        }

        if (!permissions.includes(OFPermissions.LOGOUT_BUTTON)) {
          ref.current.insertCSS(
            `.m-main-container button[at-attr="logout"] { display: none !important }`,
          );
        }

        if (!permissions.includes(OFPermissions.SETTINGS)) {
          ref.current.insertCSS(
            `.m-main-container a[href^="/my/settings"] { display: none !important }`,
          );
        }

        if (!permissions.includes(OFPermissions.STATEMENTS)) {
          ref.current.insertCSS(
            `.m-main-container a[href^="/my/statements"], .m-main-container a[href^="/my/statistics/statements"] { display: none !important }`,
          );
        }

        if (!permissions.includes(PlatformPermissions.STATISTICS)) {
          ref.current.insertCSS(
            `.m-main-container a[href^="/my/statistics"], .m-main-container a[href^="/my/settings/subscription"], .m-main-container a[href^="/my/statements/earnings"], .m-main-container a[href^="/my/stats/earnings"], .b-table.m-responsive.m-earnings { display: none !important }`,
          );
        }

        if (!permissions.includes(OFPermissions.VAULT)) {
          ref.current.insertCSS(
            `.b-placeholder-item-selected button[at-attr='delete_item'] { display: none !important }`,
          );
        }
      };

      hideBlocks();
    };

    window.electronAPI.receive(eventName, cb);

    return () => {
      unmounted = true;
      window.electronAPI.receiveOff(eventName);
    };
  }, [isSuperAdminPermission, userPermissions]);
};

export default useUserPermissions;
