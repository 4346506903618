import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../../../constants';

const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    title: {
      color: '#00AFF0',
      fontSize: '22px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '17px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
      },
    },
    suggestion: (x: number, y: number) => ({
      background: '#F3F4F6',
      padding: '4px',
      position: 'absolute',
      top: y,
      left: x,
      borderRadius: '8px',
      border: '1px solid #AEAEAE',
      color: '#555',
      boxSizing: 'border-box',
    }),
    button: {
      minWidth: 'auto',
      fontSize: '12px',
      padding: 0.5,
      fontWeight: 400,
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '12px',
      },
    },
    textarea: {
      border: 0,
      width: '100%',
      borderRadius: '10px',
      background: 'var(--white-color)',
      boxShadow: '0 4px 30px 0 rgba(0, 0, 0, 0.05)',
      padding: '22px',
      [breakpoints.down(ScreenSizes.screenXl)]: {
        padding: '15px',
      },
    },
    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      margin: '23px 0 10px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        marginTop: '15px',
        gap: '7px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        marginTop: '10px',
        gap: '5px',
      },
    },
    content: {
      '& textarea': {
        fontSize: '19px',
        color: '#848484',
        outline: 'none',
        border: 'none',
        [breakpoints.down(ScreenSizes.screenXXl)]: {
          fontSize: '14px',
        },
        [breakpoints.down(ScreenSizes.screenXl)]: {
          fontSize: '10px',
        },
      },
    },
    helperText: {
      marginBottom: '20px',
      textAlign: 'center',
      display: 'block',
      color: '#A3ADB1',
      fontSize: '12px',
      height: '18px',
      [breakpoints.down(ScreenSizes.screenXXl)]: {
        fontSize: '12px',
        height: '14px',
        marginBottom: '10px',
      },
      [breakpoints.down(ScreenSizes.screenXl)]: {
        fontSize: '10px',
        height: '14px',
        marginBottom: '10px',
      },
    },
  };
};

export default useStyles;
