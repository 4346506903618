import React, { useState } from 'react';

import styles from './AvailableUpdate.module.scss';
import { ReactComponent as CloseIcon } from 'assets/images/cross.svg';
import { ReactComponent as BrandLogo } from 'assets/images/temp/blue-logo.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/billing/successful.svg';

import { classNames } from 'utils/classNames';
import { IPaymentInvoice } from 'models/billing';
import { Button, ThemeButton } from 'components/Button/Button';

interface AvailableUpdateProps {
    data?: IPaymentInvoice;
    onCancel?: () => void;
    className?: string;
}

export const AvailableUpdate = ({ data = {}, className, onCancel }: AvailableUpdateProps) => {

    const onClickUpdateApp = () => {
        window.electronAPI.send("update-application");
    }

    const onClickCancelUpdateApp = () => {
        window.electronAPI.send("cancel-download-update-application");
        onCancel?.();
    }

    return (
        <div className={classNames(styles.container, {}, [className])}>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <BrandLogo />
                </div>
            </div>
            <div className={styles.body}>
                <p className={styles.title}>Available update!</p>
                <p className={styles.description}>Please update your application</p>
                <div className={styles.actions}>
                    
                    <Button theme={ThemeButton.DARK_OUTLINE} onClick={onClickCancelUpdateApp}>Cancel</Button>
                    <Button theme={ThemeButton.PRIMARY} onClick={onClickUpdateApp}>Update</Button>
                </div>

            </div>
        </div>
    );
};
