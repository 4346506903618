import { PAYMENT_IDS } from 'config/api';
import { ModalType } from 'constants/modal';
import { PaymentStatus } from 'models/billing';
import { MutableRefObject, useEffect, useRef } from 'react';
import { billingService } from 'services/BillingService';
import { useAppDispatch, useAppSelector } from 'store';
import { getUserAuthData } from 'store/reducers/auth';
import { loadCurrentBalance } from 'store/reducers/billing';
import { modalActions } from 'store/reducers/modal';

const PAYMENT_REQUEST_INTERVAL = 5000;

export const usePayment = (): void => {
    const timerRef = useRef() as MutableRefObject<ReturnType<typeof setInterval>>;
    const isAuth = Boolean(useAppSelector(getUserAuthData));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isAuth) return;

        timerRef.current = setInterval(async () => {
            try {
                const paymentIds = localStorage.getItem(PAYMENT_IDS);
                if (!paymentIds) return;
    
                const response = await billingService.getPaymentInvoice(paymentIds);
                const { status, number, tax, total, total_excluding_tax } = response.invoice;
    
                if(status === PaymentStatus.PAID) {
                    dispatch(modalActions.setModalType({ 
                        type: ModalType.PAYMENT_SUCCESSFUL, 
                        config: { data: { number, tax, total, total_excluding_tax } } 
                    }));
                    dispatch(loadCurrentBalance());
                    localStorage.removeItem(PAYMENT_IDS);
                };
    
                if (status === PaymentStatus.VOID || status === PaymentStatus.UNCOLLECTIBLE) {
                    localStorage.removeItem(PAYMENT_IDS);
                }
            } catch {
                localStorage.removeItem(PAYMENT_IDS);
                clearTimeout(timerRef.current);
            }

        }, PAYMENT_REQUEST_INTERVAL);

        return () => clearTimeout(timerRef.current);
    }, [isAuth]);
};
