import React, { useEffect } from 'react';

import styles from './Billing.module.scss';
import { classNames } from 'utils/classNames';
import { PaymentBalance } from './PaymentBalance/PaymentBalance';
import { SubscriptionTable } from './SubscriptionTable/SubscriptionTable';
import { loadSubscriptions } from 'store/reducers/billing';
import { useAppDispatch } from 'store';
import { modalActions } from '../../store/reducers/modal';
import { ModalType } from '../../constants/modal';
import { AI_COPILOT_NOTIFY } from '../../constants/billing';


interface BillingTableProps {
    className?: string;
}

export const Billing = ({ className }: BillingTableProps) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadSubscriptions());
    }, []);

    useEffect(() => {
        if (!localStorage.getItem(AI_COPILOT_NOTIFY)) {
            dispatch(modalActions.setModalType({
               type: ModalType.AI_COPILOT,
            }));
        }
    }, []);

    return (
        <div className={classNames(styles.container, {}, [className])}>
            <div className={styles.header}>
                <h1 className={styles.title}>Billing</h1>
            </div>
            <PaymentBalance />
            <SubscriptionTable />
        </div>
    );
};
