import React, { useEffect, useRef, useState } from 'react';

import {
  useWebView,
  useProxy,
  useCreatorAutoLogin,
  useUIHelpers,
  useUiEmojis,
  useUserPermissions,
  useCreatorLogout,
  useNotification,
  useVault,
} from './hooks';

const Webview = ({ src }: { src: string }) => {
  const ref = useRef<any>(null);

  const [isReady, setIsReady] = useState(false);

  useUIHelpers({ ref, isReady });
  useNotification({ ref, isReady });
  useUiEmojis({ ref, isReady });
  useWebView({ ref });
  useProxy({ isReady });
  useCreatorAutoLogin({ isReady, ref });
  useUserPermissions({ ref });
  useCreatorLogout({ ref });
  useVault({ ref });

  useEffect(() => {
    setIsReady(false);
  }, [src]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const ready = () => {
      setIsReady(true);

      // ref.current.openDevTools();
    };

    ref.current.addEventListener('dom-ready', ready);

    return () => {
      if (ref.current) {
        window.electronAPI.send('remove-proxy', null);

        ref.current.removeEventListener('dom-ready', ready);
      }
    };
  }, [ref.current]);

  return (
    <webview
      ref={ref}
      src={src}
      useragent="Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36"
      style={{ position: 'absolute', width: '100%', height: '100%' }}
      preload={`file://${window.versions.dirname()}/webview-preload.js`}
    />
  );
};

export default Webview;
