import { IMember } from "../../../models/members";

import styles from "../Members.module.scss";

const MemberCell = ({ item }: { item: IMember }) => {
  return (
    <div className={styles.member}><p className={styles.name}>{item.name}</p></div>
  );
};

export default MemberCell;
