import React from 'react';
import { Box } from '@mui/material';

import Tabs from './Tabs';

import useStyles from './useStyles';

const AccountDashboard = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.root}>
      <Tabs />
    </Box>
  );
};

export default AccountDashboard;
