import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import EmojiPicker, { Emoji } from 'emoji-picker-react';
import { EmojiClickData } from 'emoji-picker-react/dist/types/exposedTypes';

import Modal from '../../OFSidebar/components/Modal';

import { AddIcon } from '../../../icons';
import { IModalProps } from './interfases';
import { EmojiType } from '../interfaces';

import { useEmojiProviderContext } from '../../../providers';

import useStyles from './useStyles';

const ModalHotbar = ({ onSave, emojis }: IModalProps) => {
  const styles = useStyles();

  const { isHotbarModalOpen: isOpen, setIsHotbarModalOpen } =
    useEmojiProviderContext();

  const [isOpenPicker, setIsOpenPicker] = useState(false);

  const [activeEmojis, setActiveEmojis] = useState(emojis);

  useEffect(() => {
    if (!isOpen) {
      setIsOpenPicker(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setActiveEmojis(emojis);
  }, [emojis]);

  const handleEmojiClick = (emoji: EmojiClickData) => {
    const isExist = activeEmojis.find((item) => item.unified === emoji.unified);

    if (isExist) {
      return;
    }

    const newActiveEmojis = [...activeEmojis, emoji];
    setActiveEmojis(newActiveEmojis);
    setIsOpenPicker(false);
  };

  const handleRemoveEmoji = (emoji: EmojiType) => {
    setActiveEmojis((prevState) => {
      return prevState.filter((item) => item.unified !== emoji.unified);
    });
  };

  const handleClose = useCallback(() => {
    setIsHotbarModalOpen(false);
  }, []);

  const handleSave = () => {
    onSave(activeEmojis);
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Box sx={styles.root}>
        <Typography component={'h2'} sx={styles.title}>
          Emoji List
        </Typography>
        <Typography sx={styles.text}>
          Add and Remove Emojis to your Hotbar
        </Typography>
        <Box sx={styles.emojis}>
          {activeEmojis.map((item) => (
            <Box key={item.unified} sx={styles.button}>
              <Button
                type="button"
                onClick={() => handleRemoveEmoji(item)}
                sx={styles.remove}
              >
                &#10005;
              </Button>
              <Emoji unified={item.unified} />
            </Box>
          ))}
        </Box>
        <Button
          type="button"
          onClick={() => {
            setIsOpenPicker((prevState) => !prevState);
          }}
          sx={styles.add}
        >
          <AddIcon />
        </Button>
        <Box sx={styles.actions}>
          <Button type="button" onClick={handleClose} sx={styles.cancel}>
            Cancel
          </Button>
          <Button type="button" onClick={handleSave} sx={styles.save}>
            Save
          </Button>
        </Box>
        {isOpenPicker && (
          <Box sx={styles.picker}>
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ModalHotbar;
