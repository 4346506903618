import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { Box, Typography } from '@mui/material';

import Tag from './Tag';
import { TAGS } from './constants';

import useStyles from './styles';
import { useElectronContext } from '../../../../../../../providers/ElectronProvider';

import { fanNotesService } from '../../../../../../../../../services/FanNotesService';

import Loader from '../../../../Loader';
import TextareaBlock from './TextareaBlock';

interface INote {
  text: string;
  tag: string;
}

const FanNotes = () => {
  const styles = useStyles();

  const noteStringRef = useRef('');

  const { activeCreator, activeChatterId } = useElectronContext();

  const [isLoading, setIsLoading] = useState(false);

  const [activeTag, setActiveTag] = useState('');

  const loadNotes = useCallback(
    async (creatorId: string, chatterId: string) => {
      setIsLoading(true);
      try {
        const { notes } = await fanNotesService.getFanNotes(
          creatorId,
          chatterId,
        );

        setActiveTag('');
        noteStringRef.current = notes;
      } catch (e) {}
      setIsLoading(false);
    },
    [],
  );

  const updateNotes = useCallback(
    async (creatorId: string, chatterId: string, notes: string) => {
      try {
        await fanNotesService.updateFanNotes(creatorId, chatterId, { notes });
      } catch (e) {}
    },
    [],
  );

  const divideText = useCallback((noteString: string) => {
    const tags = TAGS.map((tag) => tag.label);
    const regex = /#(\w+)/g;
    const matches: string[] | null = noteString.match(regex);

    if (!matches) {
      return null;
    }

    const filteredMatches = matches.filter((item) => tags.includes(item));

    if (!filteredMatches) {
      return null;
    }

    const noteList: INote[] = [];
    let updatedStr = noteString;

    for (let i = 0; i <= filteredMatches.length - 1; i++) {
      const startIdx = 0;
      const endIdx = updatedStr.indexOf(filteredMatches[i]);
      const text = updatedStr.substring(startIdx, endIdx).trim();

      updatedStr = updatedStr.slice(endIdx + filteredMatches[i].length);

      noteList.push({ text, tag: filteredMatches[i] } as INote);
    }

    updatedStr = updatedStr.trim();

    if (updatedStr.length > 0) {
      noteList.push({ text: updatedStr, tag: '' } as INote);
    }

    return noteList;
  }, []);

  useEffect(() => {
    if (!activeCreator?.id || !activeChatterId) {
      return;
    }

    loadNotes(activeCreator.id, activeChatterId);
  }, [activeCreator?.id, activeChatterId, loadNotes]);

  const filteredNotes = () => {
    const noteString = noteStringRef.current;
    const notes = divideText(noteString);

    if (notes === null) {
      return noteString;
    }

    let filtered = [];
    if (activeTag) {
      filtered = notes.filter((note) => note.tag === activeTag);
    } else {
      filtered = notes;
    }

    return filtered.map(({ text, tag }) => `${text} ${tag}`).join('\n\n');
  };

  const handleSave = useCallback(
    (value: string) => {
      const noteString = noteStringRef.current;

      if (!activeCreator?.id || !activeChatterId || noteString === value) {
        return;
      }

      let newValueSrt = '';
      if (activeTag) {
        const notes = divideText(noteString);
        if (notes === null) {
          newValueSrt = value;
        } else {
          //TODO: fix the issue of typing while filter is active
          const newValue = notes
            .map(({ text, tag }) => {
              if (tag === activeTag || !tag) {
                return '';
              } else {
                return `${text}${tag ? ' ' + tag : ''}`;
              }
            })
            .join(' ');

          newValueSrt = `${newValue}\n\n${value.trim()}`;
        }
      } else {
        newValueSrt = value;
      }

      noteStringRef.current = newValueSrt;
      updateNotes(activeCreator?.id, activeChatterId, newValueSrt);
    },
    [activeCreator?.id, activeChatterId, activeTag, updateNotes],
  );

  return (
    <Box>
      <Box sx={styles.title}>Search by:</Box>

      <Box sx={styles.tags}>
        {TAGS.map((tag) => (
          <Tag
            label={tag.label}
            color={tag.color}
            active={tag.label === activeTag}
            key={tag.label}
            onClick={() => {
              setActiveTag((prevState) =>
                tag.label === prevState ? '' : tag.label,
              );
            }}
          />
        ))}
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Typography sx={styles.helperText}>
            {activeTag ? "While 'Search by' is active, you can't type" : null}
          </Typography>
          <Box sx={styles.textarea}>
            <Box sx={styles.content}>
              <TextareaBlock
                disabled={!!activeTag}
                notes={filteredNotes()}
                onUpdate={handleSave}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FanNotes;
